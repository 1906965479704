import React, { useCallback, useContext, useMemo, useState } from "react"
import Swal from "sweetalert2"
import { UserModel } from "../@types/api-user-model"
import api from "../services/api"

export type ContextValue = {
  desactivateClient: (id: string) => void
  ActivateClient: (id: string) => void
  clients: UserModel[]
  fetchClients: (page: number) => Promise<void>
  filterClients: (data: any) => Promise<void>
  loading: boolean
  total: number
  aproveClient: (id: string, approved_at: string, verified_at: string) => Promise<void>
  filtered: boolean
}

export const ClientsContext = React.createContext<ContextValue | undefined>(undefined)

export const ClientsProvider: React.FC = props => {
  const [clients, setClients] = useState<UserModel[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filtered, setFiltered] = useState(false)

  const fetchClients = useCallback(async (page: number) => {
    setLoading(true)
    try {
      const response = await api.get(`/user/index?filter=is_client&page=${page}`)
      setClients(response.data[0].data)
      setTotal(response.data[0].meta.total)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setFiltered(false)
    }
  }, [])

  const filterClients = useCallback(async (data: any) => {
    setLoading(true)
    try {
      const response = await api.get(
        `/user/index?filter=is_client&filter_field=${data.field}&filter_value=${data.value}`,
      )
      setClients(response.data[0].data)
      setTotal(response.data[0].meta.total)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setFiltered(true)
    }
  }, [])

  const aproveClient = useCallback(async (id: string, approved_at: string, verified_at: string) => {
    try {
      await api.post(`/user/settings/${id}`, { user_approved_at: approved_at, email_verified_at: verified_at })
      await Swal.fire("Sucesso ao aprovar!", "Cliente aprovado", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao aprovar!", "Cliente não aprovado", "error")
    }
  }, [])

  const desactivateClient = useCallback(async (id: string) => {
    try {
      await api.post(`/user/settings/${id}`, { active: false })
      await Swal.fire("Sucesso ao desativar!", "Cliente desativado", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao desativar!", "Não foi possível desativar", "error")
    }
  }, [])

  const ActivateClient = useCallback(async (id: string) => {
    try {
      await api.post(`/user/settings/${id}`, { active: true })
      await Swal.fire("Sucesso ao Ativar!", "Cliente ativado", "success")
      window.location.reload()
    } catch (error) {
      Swal.fire("Erro ao Ativar!", "Cliente não ativado", "error")
    }
  }, [])

  const value = useMemo(
    () => ({
      desactivateClient,
      ActivateClient,
      clients,
      fetchClients,
      filterClients,
      loading,
      total,
      aproveClient,
      filtered,
    }),
    [desactivateClient, ActivateClient, clients, fetchClients, loading, total, aproveClient, filterClients, filtered],
  )

  return <ClientsContext.Provider value={value} {...props} />
}

export const useClients = (): ContextValue => {
  const context = useContext(ClientsContext)

  if (context === undefined) {
    throw new Error("useClients must be used within an ClientsProvider")
  }

  return context
}

//
// Utils
//
