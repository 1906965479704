/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import { PrivateRoutes } from "./PrivateRoutes"
import { ErrorsPage } from "../modules/errors/ErrorsPage"
import { Logout, AuthPage } from "../modules/auth"
import { App } from "../App"
import { useAuthBelleza } from "../../belleza/hooks/useAuthBelleza"
import { VerifyEmailAddress } from "../modules/auth/components/VerifyEmailAddress"
// import { ForgotPassword } from '../modules/auth/components/ForgotPassword'
// import { DeactivateAccount } from '../modules/accounts/components/settings/cards/DeactivateAccount'
import { RedefinePassword } from "../modules/auth/components/RedefinePassword"

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { isAuthenticated, profile } = useAuthBelleza()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route path="verificar-endereco-email" element={<VerifyEmailAddress />} />
          <Route path="verificar-endereco-email/:id" element={<VerifyEmailAddress />} />
          <Route path="login/esqueci-senha/" element={<RedefinePassword />} />
          <Route path="login/esqueci-senha/:id" element={<RedefinePassword />} />
          {isAuthenticated ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to={`/gestao/agendamentos`} />} />
            </>
          ) : (
            <>
              <Route path="login/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
