import { lazy, Suspense, useEffect, useState } from "react"
import { Route, Routes, Navigate } from "react-router-dom"
import { MasterLayout } from "../../_metronic/layout/MasterLayout"
import TopBarProgress from "react-topbar-progress-indicator"
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper"
import { MenuTestPage } from "../pages/MenuTestPage"
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils"
import { Transactions } from "../../belleza/pages/Transactions"
import { Reports } from "../../belleza/pages/Reports"
import { Categories } from "../../belleza/pages/Categories"
import { Services } from "../../belleza/pages/Services"
import { Showcase } from "../../belleza/pages/Showcase"
import { PaymentOptions } from "../../belleza/pages/PaymentOptions"
import { Users } from "../../belleza/pages/Users"
import { AddCategory } from "../../belleza/pages/Categories/AddCategory"
import { EditCategory } from "../../belleza/pages/Categories/EditCategory"
import { EditService } from "../../belleza/pages/Services/EditService"
import { AddService } from "../../belleza/pages/Services/AddService"
import { UserServices } from "../../belleza/pages/UserServices"
import { AddUserServices } from "../../belleza/pages/UserServices/AddUserServices"
import { PageViewCustomers } from "../../belleza/pages/Customers/PageViewCustomers"
import { PageAppointmentsAvailable } from "../../belleza/pages/Partners/PageAppointmentsAvailable"
import { PageServicesAssociated } from "../../belleza/pages/Partners/PageServicesAssociated"
import { PageServicesAssociate } from "../../belleza/pages/Partners/PageServicesAssociate"
import { PageAppointmentsProgress } from "../../belleza/pages/Partners/PageAppointmentsProgress"
import { PageAppointments } from "../../belleza/pages/Appointment/PageAppointments"
import DetailsAppointment from "../../belleza/pages/Appointment/PageAppointments/details"
import { Clients } from "../../belleza/pages/Clients"
import { Providers } from "../../belleza/pages/Providers"
import { PageViewClients } from "../../belleza/pages/Clients/PageViewCustomers"
import { PageViewProviders } from "../../belleza/pages/Providers/PageViewProviders"
import { useAuthBelleza } from "../../belleza/hooks/useAuthBelleza"
import { LoadingCustomer } from "../../belleza/pages/Customers/LoadingCustomer"

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import("../pages/layout-builder/BuilderPageWrapper"))
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"))
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"))
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"))
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"))
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"))
  const UsersPage = lazy(() => import("../modules/apps/user-management/UsersPage"))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="login/*" element={<Navigate to={`/gestao/agendamentos`} />} />
        {/* Pages */}
        <Route path="/dashboard" element={<DashboardWrapper />} />

        {/* begin::Pages/Financial */}
        <Route path="/financeiro/transacoes" element={<Transactions />} />
        <Route path="/financeiro/relatorios" element={<Reports />} />
        {/* end::Pages/Financial */}

        {/* begin::Pages/management */}
        <Route path="/gestao/agendamentos" element={<PageAppointments />} />
        <Route path="/gestao/agendamentos/:id" element={<PageAppointments />} />

        {/* <Route
          path='/gestao/agendamentos/resumo-do-agendamentos'
          element={<PageAppointments />}
        /> */}

        {/* passar parâmetro */}

        <Route path="/gestao/clientes" element={<Clients />} />
        <Route path="/gestao/clientes/:id" element={<Clients />} />

        <Route path="/gestao/clientes/:id/visualizar-cliente" element={<PageViewClients />} />

        <Route path="/gestao/parceiros" element={<Providers />} />
        <Route path="/gestao/parceiros/:id" element={<Providers />} />

        <Route path="/gestao/parceiros/:id/visualizar-parceiro" element={<PageViewProviders />} />

        <Route path="/gestao/usuarios/loading" element={<LoadingCustomer />} />

        <Route path="/gestao/usuarios/:id/visualizar-usuario" element={<PageViewCustomers />} />

        <Route path="/gestao/categorias" element={<Categories />} />

        <Route path="/gestao/categorias/:id/editar-categoria" element={<EditCategory />} />

        <Route path="/gestao/categorias/adicionar-categoria" element={<AddCategory />} />

        <Route path="/gestao/servicos" element={<Services />} />
        <Route path="/gestao/servicos/:id/editar-servicos" element={<EditService />} />
        <Route path="/gestao/servicos/adicionar-servico" element={<AddService />} />

        <Route path="/gestao/servicos/editar-servico" element={<EditService />} />

        <Route path="/gestao/prestadores-e-servicos" element={<UserServices />} />

        <Route path="/gestao/servicos-ao-usuario/adicionar-servicos-ao-usuario" element={<AddUserServices />} />

        <Route path="/gestao/parceiros/associacao-parceiros-servicos" element={<PageServicesAssociate />} />

        <Route path="/gestao/detalhes-agendamento/:id" element={<DetailsAppointment />} />

        <Route path="/gestao/parceiros/lista-parceiros-servicos" element={<PageServicesAssociated />} />

        <Route path="/gestao/parceiros/agendamentos-disponiveis" element={<PageAppointmentsAvailable />} />

        <Route path="/gestao/parceiros/agendamentos-disponiveis/:id" element={<PageAppointmentsAvailable />} />

        <Route path="/gestao/parceiros/agendamentos-em-andamento" element={<PageAppointmentsProgress />} />

        <Route path="/gestao/parceiros/agendamentos-em-andamento/:id" element={<PageAppointmentsProgress />} />

        {/* end::Pages/management */}

        {/* begin::Pages/Config */}
        <Route path="/configuracao/vitrine" element={<Showcase />} />

        <Route path="/configuracao/meios-de-pagamento" element={<PaymentOptions />} />

        <Route path="/configuracao/usuarios" element={<Users />} />
        {/* end::Pages/Config */}

        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  )
}

const SuspensedView = ({ children }: { children: JSX.Element }) => {
  const baseColor = getCSSVariableValue("--bs-primary")
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
