import { Button, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useClients } from "../../hooks/useClients"
import { usePartners } from "../../hooks/usePartners"

export function HeaderSearch(props: any) {
  const { type } = props
  const { filterPartners, fetchProviders, isFiltered } = usePartners()
  const { filterClients, fetchClients, filtered } = useClients()
  const { register, handleSubmit } = useForm()
  const onSubmit = (data: any) => {
    type === "clients" ? filterClients(data) : filterPartners(data)
  }

  const cleanFilter = () => {
    type === "clients" ? fetchClients(1) : fetchProviders(1)
  }
  return (
    <Form className="p-5 d-flex align-items-center w-50" onSubmit={handleSubmit(onSubmit)}>
      <Form.Select aria-label="Default select example" required {...register("field")}>
        <option>Filtrar por</option>
        <option value="name">Nome</option>
        <option value="address_line1">Endereço</option>
        <option value="phone_number">Telefone</option>
        <option value="city">Cidade</option>
        <option value="document">Documento</option>
      </Form.Select>
      <Form.Control className="mx-4" placeholder="Pesquisar" required {...register("value")} />
      <Button variant="primary" type="submit">
        Filtrar
      </Button>
      {filtered && (
        <Button variant="secondary w-100 mx-2" onClick={() => cleanFilter()}>
          Limpar Filtro
        </Button>
      )}
      {isFiltered && (
        <Button variant="secondary w-100 mx-2" onClick={() => cleanFilter()}>
          Limpar Filtro
        </Button>
      )}
    </Form>
  )
}

/*
    <div className="card-header border-0 ">
    <div className="card-title">
    <div className="d-flex align-items-center position-relative my-1">
    <span className="svg-icon svg-icon-1 position-absolute ms-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <rect
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="currentColor"
                />
                <path
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="currentColor"
                />
                </svg>
                </span>
                <input
                data-kt-subscription-table-filter="search"
                className="form-control form-control-solid w-250px ps-14"
                placeholder="Pesquisar"
                />
                </div>
                </div>
                </div>
              */
