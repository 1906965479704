
interface PaginationItemProps {
    number: number;
    isCurrent?: boolean;
    onPageChange: (page: number) => void;
}

export function PaginationItem({ isCurrent, number, onPageChange }: PaginationItemProps) {
    if (isCurrent) {
        return (
            <li className="page-item active">
                <button className="page-link">
                    {number}
                </button>
            </li>
        );
    }

    return (
        <li className="page-item">
            <button
                className="page-link"
                onClick={() => onPageChange(number)}
            >
                {number}
            </button>
        </li>
    )
}
