import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { SpinnerLoadingMiddle } from "../../../../belleza/components/SpinnerLoadingMiddle";
import api from "../../../../belleza/services/api";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

export function VerifyEmailAddress() {
    const [qs] = useSearchParams();

    const id = qs.get("id");
    const hash = qs.get("hash");
    const expires = qs.get("expires");
    const signature = qs.get("signature");

    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        /* api.get(`/verify/link/${id}/${hash}`) */

        async function verify() {
            await api.get(`/verify/link/${id}/${hash}?expires=${expires}&signature=${signature}`)
                .then(() => {
                    setIsLoading(false);
                    setSuccess(true);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                })
        }
        verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        //Tela de confirmação de email
        <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
            <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                {
                    isLoading
                        ?
                        (
                            <SpinnerLoadingMiddle />
                        )
                        :
                        (
                            <>
                                {
                                    success
                                        ?
                                        (
                                            <div className='text-center mb-10' >
                                                {/* logo belleza */}
                                                <Link to='' className='mb-12' >
                                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-belleza-3-dark.svg')} className='h-60px' />
                                                </Link>
                                                {/* fim logo belleza */}
                                                <h1 className='text-dark mb-3 mt-10'>
                                                    E-mail verificado com sucesso!
                                                </h1>
                                                <div className='text-gray-400 fw-bold fs-4 mt-5'>
                                                    Agora você pode acessar sua conta.
                                                    <Link to='' className='text-primary fw-bolder'>
                                                        {localStorage.getItem('emailForSignIn')}
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            //algo deu errado

                                            <div className='text-center mb-10'>
                                                {/* logo belleza */}
                                                <Link to='' className='mb-12'>
                                                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-belleza-3-dark.svg')} className='h-60px' />
                                                </Link>
                                                {/* fim logo belleza */}
                                                <h1 className='text-dark mb-3 mt-10'>
                                                    Algo deu errado!
                                                </h1>
                                                <div className='text-gray-400 fw-bold fs-4 mt-5'>
                                                    O link de verificação de e-mail expirou ou você não está logado.
                                                    <Link to='' className='text-primary fw-bolder'>
                                                        {localStorage.getItem('emailForSignIn')}
                                                    </Link>
                                                </div>
                                            </div>

                                        )
                                }
                            </>
                        )
                }

                {/* <div className='text-center'>
                    <strong>ID:</strong> {id}
                </div>
                <div className='text-center'>
                    <strong>HASH:</strong> {hash}
                </div>
                <div className='text-center'>
                    <strong>EXPIRES:</strong>  {expires}
                </div>
                <div className='text-center'>
                    <strong>SIGNATURE:</strong> {signature}
                </div> */}
            </div >
        </div >
    );
}