import clsx from "clsx"
import { FC } from "react"

type Props = {
  className?: string
  scroll?: boolean
  height?: number
  children: JSX.Element
}

const KTCardBody: FC<Props> = ({ className, scroll, height, children }) => {
  return (
    <div
      className={clsx(
        "card-body",
        className && className,
        {
          "card-scroll": scroll,
        },
        height && `h-${height}px`,
      )}
    >
      {children}
    </div>
  )
}

export { KTCardBody }
