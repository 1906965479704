import { useEffect, useState } from "react"
import { TransactionsDTOs } from "../../../../dtos/TransactionsDTOs"
import { TableAppointmentsProgress } from "../../../Partners/PageAppointmentsProgress/TableAppointmentsProgress"
import { TablePaymentProgress } from "../TablePaymentProgress"

interface Props {
  transactions: TransactionsDTOs[]
}

export function PaymentTable({ transactions }: any) {
  const [payments, setPayments] = useState<any[]>([])
  const [isMounted, setIsMounted] = useState(false)

  const HandleDate = (date: any) => {
    return date.split("T")[0].split("-").reverse().join("/")
  }

  useEffect(() => {
    transactions.forEach((transaction: any) => {
      transaction.checkout && payments.push(transaction)
    })
    setIsMounted(true)
  }, [])

  return (
    <div className="card pt-4 mb-6 mb-xl-9 d-flex w-100">
      <div className="card-header border-0">
        <div className="card-title flex-column gap-2">
          <h2>Pagamentos</h2>
          <span>
            Os pagamentos são realizados automaticamente para a conta bancária cadastrada após 30 dias da realização do
            serviço.
          </span>
        </div>
      </div>
      <div className="card-body pt-0 pb-5">
        <div id="kt_table_customers_payment_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="table-responsive">
            {/*
            <table
              className="table align-middle table-row-dashed gy-5 dataTable no-footer"
              id="kt_table_customers_payment"
            >
              <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                <tr className="text-start text-muted text-uppercase gs-0">
                  <th
                    className="min-w-100px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Invoice No.: activate to sort column ascending"
                    style={{
                      width: "139.219px",
                    }}
                  >
                    Código
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Status: activate to sort column ascending"
                    style={{
                      width: "117.781px",
                    }}
                  >
                    Status
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Amount: activate to sort column ascending"
                    style={{
                      width: "113.625px",
                    }}
                  >
                    Valor
                  </th>
                  <th
                    className="min-w-100px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                    style={{
                      width: "231.938px",
                    }}
                  >
                    Data
                  </th>
                </tr>
              </thead>
              {transactions.length > 0 ? (
                <tbody className="fs-6 fw-semibold text-gray-600">
                  {transactions.map(transaction => (
                    <tr className="odd">
                      <td>
                        <a href="/" className="text-gray-600 text-hover-primary mb-1">
                          {transaction.id}
                        </a>
                      </td>
                      <td>
                        {transaction.transaction_status === "paid" ? (
                          <span className="badge badge-light-success">APROVADO</span>
                        ) : (
                          <span className="badge badge-light-danger">RECUSADO</span>
                        )}
                      </td>
                      <td>{`R$ ${transaction.amount.toFixed(2).replace(".", ",")}`}</td>
                      <td>{(transaction.created_at as any).split("T")[0].split("-").reverse().join("/")}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>Sem pagamentos</div>
              )}
            </table> */}
            <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
              <thead>
                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                  <th className="">Cod.</th>
                  <th className="">Cliente</th>
                  <th className="">Produto</th>
                  <th className="">Valor</th>
                  <th className="">Data</th>
                  <th className="">Hora</th>
                </tr>
              </thead>
              {payments.length > 0 ? (
                <tbody className="fw-bold text-gray-600">
                  {payments.map((appointment: any) => {
                    return <TablePaymentProgress data={appointment} key={appointment.id} />
                  })}
                </tbody>
              ) : (
                <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>Sem pagamentos</div>
              )}
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
