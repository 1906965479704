import { useState } from "react"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { PaginationsAPI } from "../../../components/Form/PaginationAPI"
import { HeaderSearchStatus } from "../../../components/HeaderSearchStatus"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"
import { useSchedules } from "../../../hooks/useSchedules"
import api from "../../../services/api"
import useMount from "../../../utils/useMount"
import { TableAppointmentsAvailable } from "./TableAppointmentsAvailable"
import Swal from "sweetalert2"

const errorMessages = [ // maikel colocar aqui cada erro retornado da api e no index seguinte a mensagem que deve ser mostrada
  'Appointment is already confirmed.',
  'O agendamento já está confirmado',
  'Provider must be approved and verified.',
  'O prestador deve ser aprovado e verificado',
  'This product is not approved to this provider.',
  'Este produto não é aprovado para este prestador.',
  'Provider must create bank account.',
  'O prestador deve criar uma conta bancária',
  'Provider has an appointment at the same time.',
  'O prestador tem um compromisso ao mesmo tempo'
]

export function PageAppointmentsAvailable() {
  const { profile } = useAuthBelleza()
  const { getUnconfirmedAppointments, providerAppointments, totalCount, page, setPage, loading } = useSchedules()
  const [checked, setChecked] = useState(false)
  const [selectedId, setSelectedId] = useState<string[]>([])

  // const [appointmentConfirm, setAppointmentConfirm] = useState(0);

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "/gestao/parceiros/agendamentos-disponiveis/:id",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  console.log("aqui", providerAppointments)
  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  async function handleConfirmAppointment(id: string) {
    console.log(id)
    await api
      .post(`/appointments/confirm/${id}`)
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
        showErrorToasty(error.response.data.message)
      })
      .finally(() => {
        /*  setIsLoading(false); */
      })
  }

  useMount(() => {
    getUnconfirmedAppointments()
  })

  const showErrorToasty = (errorMessage: string) => {
    const index = errorMessages.indexOf(errorMessage);
    if(index != -1) Swal.fire('Erro', errorMessages[index+1], 'error');
  }

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Agendamentos Disponíveis</PageTitle>
      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <KTCard>
          <div>
            <KTCardBody>
              <>
                <HeaderSearchStatus />
                <div className="card-body pt-0">
                  <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th></th>
                        <th className="">Cod.</th>
                        <th className="">Cliente</th>
                        <th className="">Produto</th>
                        <th className="">Endereço</th>
                        <th className="">Valor</th>
                        <th className="">Data</th>
                        <th className="text-end min-w-75px pe-6">Ações</th>
                      </tr>
                    </thead>
                    {providerAppointments.length > 0 ? (
                      <tbody className="fw-bold text-gray-600">
                        {providerAppointments.map(appointment => {
                          return (
                            <TableAppointmentsAvailable
                              data={appointment}
                              key={appointment.id}
                              onClickConfirm={() => handleConfirmAppointment(appointment.id.toString())}
                              onClickCheckBox={() => handleChecked(appointment.id.toString())}
                              checked={checked && selectedId[0] === appointment.id.toString() ? true : false}
                            />
                          )
                        })}
                      </tbody>
                    ) : (
                      <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                        Sem agendamentos
                      </div>
                    )}
                  </table>
                </div>

                <PaginationsAPI totalCountOfRegisters={totalCount} currentPage={page} onPageChange={setPage} />
              </>
            </KTCardBody>
          </div>
        </KTCard>
      )}
    </>
  )
}
