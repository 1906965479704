import { useCallback, useEffect, useState } from "react"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { SpinnerLoading } from "../../components/SpinnerLoading"
import { useAuthBelleza } from "../../hooks/useAuthBelleza"
import api from "../../services/api"
import { Button, Form, Modal } from "react-bootstrap"
import { ServicesDTOs } from "../../dtos/ServicesDTOs"
import { TableUserServices } from "./TableUserServices"
import { UserModel } from "../../@types/api-user-model"
import { useServices } from "../../hooks/useServices"
import { useParams } from "react-router-dom"

export function UserServices() {
  const { profile } = useAuthBelleza()
  const { userServices } = useServices()
  const [, setCustomersList] = useState<UserModel[]>([])
  const [statusModalCreateService, setStatusModalCreateService] = useState(false)
  const [checked, setChecked] = useState(false)
  const [, setSelectedId] = useState<string[]>([])
  const [services, setServices] = useState<ServicesDTOs[]>([])
  const [users, setUsers] = useState<UserModel[]>([])
  const [selectedUser, setSelectedUser] = useState(0)
  const [selectedService, setSelectedService] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const [isCreatedModalService, setIsCreatedModalService] = useState(false)
  const [isLoadingCreateService, setIsLoadingCreateService] = useState(false)

  const [filteredService, setFilteredService] = useState<ServicesDTOs[]>([])
  const { id } = useParams()

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "/gestao/servicos-e-prestadores/*",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function handleCloseCreateService() {
    setStatusModalCreateService(false)
  }

  /* AQUI COMEÇAR A IMPLEMENTAR FUNÇÕES*/
  function handleChecked(id: string) {
    setChecked(!checked)
    /* setSelectedUuid([...selectedUuid, uuid]); */
    setSelectedId([id])
  }

  async function handleCreateServiceCustomers() {
    setIsLoadingCreateService(true)
    console.log(selectedService)
    await api
      .post(`/user_products/add/${selectedUser}`, {
        product_id: selectedService,
      })
      .then(response => {
        setIsLoadingCreateService(false)
      })
      .catch(err => {})
      .finally(() => {
        setStatusModalCreateService(false)
        setIsCreatedModalService(false)
        window.location.reload()
      })
  }

  async function handleRemoveServiceCustomers(id: any) {
    console.log(id)
    await api
      .post(`/user_products/update/${id}`, {
        approved: 0,
      })
      .then(response => {
        console.log(response.data[0])
      })
      .catch(err => {})
      .finally(() => {
        window.location.reload()
      })
  }

  //PRECISO CORRIGIR A RERENDERIZACAO QUE NAO ESTA SENDO FEITA.

  async function fetchListUsers() {
    try {
      const response = await api.get("/user/index")
      setCustomersList(response.data[0].data)
      setUsers(response.data[0].data)
    } catch (error) {
      console.log("error: ", error)
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchListServices() {
    try {
      const response = await api.get("/products")
      const filter = response.data[0].filter(
        (a: any) => !profile?.user_products.some((b: any) => a.id === b.product_id),
      )

      setFilteredService(filter)

      /* console.log('products: ', response.data[0]); */
    } catch (error) {
      console.log("error: ", error)
    } finally {
      setIsLoading(false)
    }
  }

  //consumir API
  async function fetchApi() {
    await fetchListUsers()
    await fetchListServices()
  }

  useEffect(() => {
    setSelectedUser(Number(id))
    fetchApi()
  }, [])

  //function page go to view
  function handleViewCustomer(id: number) {
    localStorage.setItem("@App:idPartner", String(id))

    return (window.location.href = `/gestao/servicos/${id}/editar-servicos`)
    /* window.localStorage.removeItem('@App:idPartner'); */
  }
  //TESTE PRA VER SE E IGUAL
  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Serviço ao Usuário</PageTitle>
      <KTCard>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div>
            <div className="card-header border-0">
              <div className="card-title flex justify-content-between " style={{ width: "100%" }}>
                <h2>Serviços</h2>
                <Button onClick={() => setStatusModalCreateService(true)}>Adicionar</Button>
              </div>
            </div>

            {/* <HeaderSearchAdd onClick={handleShowCreateService} /> */}
            <KTCardBody>
              <>
                <div className="table-responsive">
                  <table className="table table-row-dashed table-row-gray-300 gy-7">
                    {/* START HEADER TABLE */}
                    <thead>
                      <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                        <th className="w-10px pe-2"></th>
                        <th>Nome do Serviço</th>
                        <th>Categoria</th>
                        <th>Valor</th>
                        <th>Status</th>
                        <th className="text-end">Ações</th>
                      </tr>
                    </thead>

                    {/* END HEADER TABLE */}
                    {/* begin::listCategories */}
                    {userServices.length > 0 ? (
                      <tbody className="text-gray-600 fw-bold">
                        {userServices.map(item => {
                          return (
                            <TableUserServices
                              key={item.id}
                              data={item}
                              onClickCheckBox={() => handleChecked(item.id.toString())}
                              onClickActionDisable={() => handleRemoveServiceCustomers(item.id)}
                              onClickActionView={() => handleViewCustomer(item.product_id)}
                            />
                          )
                        })}
                      </tbody>
                    ) : (
                      <div
                        style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40, width: "150px" }}
                      >
                        Sem serviços
                      </div>
                    )}

                    {/* end::listCategories */}
                    {/* END BODY TABLE */}
                  </table>
                </div>

                <div>
                  {/* begin::Modal Create UserServices */}
                  <div>
                    <Modal
                      show={statusModalCreateService}
                      aria-labelledby="contained-modal-title-vcenter"
                      centered
                      dialogClassName="modal-50w"
                    >
                      <Modal.Header closeButton onClick={handleCloseCreateService}>
                        <Modal.Title>Adicionar Serviço ao Cliente</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex flex-column mt-10 ms-10 me-10">
                          <>
                            {/* TENHO QUE FIXAR O ID DO USUARIO PARA SELECAO DO SERVIÇO */}

                            {/* begin::cliente selecionado */}
                            {/* <div className="mb-10">
                              <label className="form-label">Selecionar Cliente</label>
                              <select
                                className="form-select form-select-solid"
                                aria-label="Default select example"
                                value={selectedUser}
                                onChange={e => setSelectedUser(Number(e.target.value))}
                              >
                                <option>Selecione um Cliente</option>
                                {users.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div> */}
                            {/* end::cliente selecionado */}

                            {/* begin::servico selecionado */}
                            <div className="mb-10">
                              <label className="form-label">Selecionar Serviço</label>
                              <select
                                className="form-select form-select-solid"
                                aria-label="Default select example"
                                value={selectedService}
                                onChange={e => setSelectedService(Number(e.target.value))}
                              >
                                <option>Selecione um Serviço</option>
                                {filteredService.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {/* end::servico selecionado */}

                            {/* criado com sucesso */}
                            {isCreatedModalService ? (
                              <div className="d-flex justify-content-center bg-gray-200 rounded-1 mb-5">
                                <div className="d-flex align-items-center">
                                  <span className="text-success fw-bold m-2">Criado com Sucesso!</span>
                                </div>
                              </div>
                            ) : null}
                          </>
                        </div>
                        {isCreatedModalService ? (
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseCreateService}>
                              Fechar
                            </Button>
                          </Modal.Footer>
                        ) : (
                          <Modal.Footer>
                            {isLoadingCreateService ? (
                              <div>
                                <Button variant="secondary" onClick={handleCloseCreateService}>
                                  Fechar
                                </Button>

                                <button type="button" className="btn btn-primary ms-4" data-kt-indicator="on">
                                  <span className="indicator-label">Submit</span>
                                  <span className="indicator-progress">
                                    Salvando...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle ms-3"></span>
                                  </span>
                                </button>
                              </div>
                            ) : (
                              <div>
                                <Button variant="secondary" onClick={handleCloseCreateService}>
                                  Fechar
                                </Button>
                                <Button
                                  variant="primary text-white ms-3"
                                  type="submit"
                                  onClick={handleCreateServiceCustomers}
                                >
                                  Salvar
                                </Button>
                              </div>
                            )}
                          </Modal.Footer>
                        )}
                      </Modal.Body>
                    </Modal>
                  </div>
                  {/* end::Modal Create UserServices */}
                </div>
              </>
            </KTCardBody>
          </div>
        )}
      </KTCard>
    </>
  )
}
