import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { UsersListLoading } from "../../../app/modules/apps/user-management/users-list/components/loading/UsersListLoading"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { PickerPhoto } from "../../components/PickerPhoto"
import { SpinnerLoad } from "../../components/SpinnerLoad"
import { CategoriesDTOs } from "../../dtos/CategoriesDTOs"
import api from "../../services/api"
import { SpinnerLoadSmall } from "../../components/SpinnerLoadSmall"
import { useServices } from "../../hooks/useServices"
import useMount from "../../utils/useMount"
import Swal from "sweetalert2"

const EditService: React.FC = () => {
  const {
    editServiceById,
    getServiceById,
    dataService,
    setDataService,
    isLoading,
    updatePhotoById,
    imageLoad,
    removePhotoById,
  } = useServices()
  const params = useParams() // pegar o id da url
  const [isLoadingService] = useState(false) // serviço carregado
  const [categories, setCategories] = useState<CategoriesDTOs[]>([])
  // const [selectedCategory, setSelectedCategory] = useState(0)
  const [serviceFile, setserviceFile] = useState<File>()
  const [price, setPrice] = useState("")
  const [edit, setEdit] = useState(false)
  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `Serviço #${params?.id}`,
      path: `gestao/servicos/${params.id}/editar-servicos`,
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  //REMOVER FOTO
  const handleRemovePhoto = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      removePhotoById(params.id || "")
    },
    [params, removePhotoById],
  )

  //ATUALIZAR FOTO
  const handleUpdatePhoto = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      await handleRemovePhoto(e)
      const data = new FormData()
      data.append("file", serviceFile!)
      updatePhotoById(params?.id || "", data)
    },
    [params?.id, serviceFile, updatePhotoById, handleRemovePhoto],
  )

  //EDITAR SERVIÇOS

  const handleEditService = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      const data = new FormData()
      data.append("category_id", dataService.category_id)
      data.append("name", dataService.name)
      data.append("desc", dataService.description)
      data.append("price", dataService.price)
      console.log(data)

      if (serviceFile && edit) {
        handleUpdatePhoto(e)
      }
      editServiceById(
        params.id || "",
        `category_id=${dataService.category_id}&name=${dataService.name}&desc=${dataService.description}&price=${dataService.price}&disabled=0`,
      )
    },
    [dataService, edit, editServiceById, handleUpdatePhoto, params.id, serviceFile],
  )

  const changeInput = useCallback(
    (value: string | Number, key: string) => {
      setDataService(prev => {
        return { ...prev, [key]: value }
      })
    },
    [setDataService],
  )

  const newPrice = (e: any) => {
    console.log("e", e)
    var v = e.replace(/\D/g, "")
    v = (v / 100).toFixed(2) + ""
    v = v.replace(".", ",")
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
    return v
  }

  const formatterNumber = useCallback((number: any) => {
    console.log(number)
    var formatString = number
    formatString = formatString.replace(",", "")
    formatString = parseFloat(formatString)
    return formatString
  }, [])

  useMount(() => {
    getServiceById(params?.id || "")
    async function loadCategory() {
      const response = await api.get(`/categories`)
      setCategories(response.data[0])
    }
    loadCategory()
  })

  useEffect(() => {
    setPrice(`${dataService.price}`)
  }, [dataService])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Serviços</PageTitle>
      {isLoading ? (
        <UsersListLoading />
      ) : (
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Adicionar Serviço</h3>
            </div>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <form noValidate className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Foto Serviço</label>
                  {imageLoad ? (
                    <SpinnerLoadSmall />
                  ) : (
                    <div className="col-lg-8">
                      <div className="mb-5">
                        <PickerPhoto
                          onFileSelected={file => {
                            setserviceFile(file)
                            setEdit(true)
                          }}
                          image={dataService.file}
                        />
                      </div>
                      {/* <div className="col-lg-8 ">
                        {serviceFile === undefined && filePreview === null ? (
                          ""
                        ) : serviceFile === undefined && filePreview !== null ? (
                          dataService.file && (
                            <button type="button" className="btn btn-danger ms-2" onClick={handleRemovePhoto}>
                              Remover Foto
                            </button>
                          )
                        ) : (
                          <button type="button" className="btn btn-primary ms-2" onClick={handleUpdatePhoto}>
                            Atualizar Foto
                          </button>
                        )}
                      </div> */}
                    </div>
                  )}
                </div>

                {/* linha divisória */}

                <div>
                  <div className="separator my-10"></div>
                </div>

                {isLoadingService ? (
                  //centralizar isLoading
                  <SpinnerLoad />
                ) : (
                  <>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6 required">Categoria do Serviço</label>
                      <div className="col-lg-8 fv-row">
                        <select
                          className="form-select"
                          aria-label="Select example"
                          onChange={e => changeInput(Number(e.target.value), "category_id")}
                          value={dataService.category_id} // valor selecionado
                        >
                          <option>Selecione a categoria do serviço</option>
                          {categories.map(category => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">Nome do Serviço</label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Nome do Serviço"
                          name="Nome do Serviço"
                          value={dataService.name}
                          onChange={e => changeInput(e.target.value, "name")}
                        />
                      </div>
                    </div>

                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">Preço do Serviço</label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Preço do Serviço"
                          name="Preço do Serviço"
                          value={price}
                          onChange={e => {
                            setDataService(prev => {
                                return { ...prev, price: formatterNumber(e.target.value) || 0 }
                            })
                            setPrice(e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    {/* end::Input Price */}

                    {/* begin::Input Desc */}
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">Descrição</label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Descrição"
                          name="desc"
                          value={dataService.description}
                          onChange={e => {
                            changeInput(e.target.value, "description")
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* begin::Button Save */}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <div className="d-flex justify-content-end">
                    <Link to={"/gestao/servicos"} type="button" className="btn btn-light-primary me-3">
                      Voltar
                    </Link>
                  </div>

                  <button type="submit" className="btn btn-primary" onClick={handleEditService}>
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export { EditService }
