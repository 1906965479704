
export function HeaderSearchStatus() {
    return (
        <div
            className='card-header align-items-center py-5 gap-2 gap-md-5'
            data-select2-id='select2-data-115-0vf7'
        >
            <div className='card-title'>
                {/* <div className='d-flex align-items-center position-relative my-1'>
                    <span className='svg-icon svg-icon-1 position-absolute ms-4'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                        >
                            <rect
                                opacity='0.5'
                                x='17.0365'
                                y='15.1223'
                                width='8.15546'
                                height='2'
                                rx='1'
                                transform='rotate(45 17.0365 15.1223)'
                                fill='currentColor'
                            />
                            <path
                                d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                    <input
                        data-kt-ecommerce-order-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Buscar'
                    />
                </div> */}
            </div>
            <div
                className='card-toolbar flex-row-fluid justify-content-end gap-5'
                data-select2-id='select2-data-114-aplj'
            >
                {/* <div className='input-group w-250px'>
                    <input
                        className='form-control form-control-solid rounded rounded-end-0'
                        placeholder='Informe um período'
                        id='kt_ecommerce_sales_flatpickr'
                    />
                    <button
                        className='btn btn-icon btn-light'
                        id='kt_ecommerce_sales_flatpickr_clear'
                    >
                        <span className='svg-icon svg-icon-2'>
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                            >
                                <rect
                                    opacity='0.5'
                                    x='7.05025'
                                    y='15.5356'
                                    width='12'
                                    height='2'
                                    rx='1'
                                    transform='rotate(-45 7.05025 15.5356)'
                                    fill='currentColor'
                                />
                                <rect
                                    x='8.46447'
                                    y='7.05029'
                                    width='12'
                                    height='2'
                                    rx='1'
                                    transform='rotate(45 8.46447 7.05029)'
                                    fill='currentColor'
                                />
                            </svg>
                        </span>
                    </button>
                </div> */}
                {/*  <div className='w-100 mw-150px' data-select2-id='select2-data-113-gnb0'>
                    <select
                        className='form-select form-select-solid select2-hidden-accessible'
                        data-control='select2'
                        data-hide-search='true'
                        data-placeholder='Status'
                        data-kt-ecommerce-order-filter='status'
                        data-select2-id='select2-data-1-bwj5'
                        tabIndex={-1}
                        aria-hidden='true'
                    >
                        <option data-select2-id='select2-data-3-xuvx' />
                        <option value='all' data-select2-id='select2-data-119-nwod'>
                            Status
                        </option>
                        <option value='Cancelled' data-select2-id='select2-data-120-iqnh'>
                            Cancelado
                        </option>
                        <option value='Completed' data-select2-id='select2-data-121-ikn4'>
                            Finalizado
                        </option>
                        <option value='Denied' data-select2-id='select2-data-122-3hsl'>
                            Negado
                        </option>
                        <option value='Expired' data-select2-id='select2-data-123-gr3a'>
                            Expirado
                        </option>
                        <option value='Failed' data-select2-id='select2-data-124-3ud5'>
                            Falha
                        </option>
                        <option value='Pending' data-select2-id='select2-data-125-vcpv'>
                            Pendente
                        </option>
                        <option value='Processing' data-select2-id='select2-data-126-u11m'>
                            Processando
                        </option>
                        <option value='Refunded' data-select2-id='select2-data-127-78ug'>
                            Reembolsado
                        </option>
                        <option value='Delivered' data-select2-id='select2-data-128-bfyq'>
                            Entregue
                        </option>
                        <option value='Delivering' data-select2-id='select2-data-129-hr1b'>
                            Em andamento
                        </option>
                    </select>

                </div> */}
            </div>
        </div>
    );
}