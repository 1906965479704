import { Link } from "react-router-dom"

interface Props {
  toView?: string
  OnClickToEdit?: () => void
  OnClickDelete?: () => void //Falta o dado de implementação da API
  OnClickAction?: () => void //Falta o dado de implementação da API
  onClickActivate?: () => void
  onClickDesactivate?: () => void
  isBlue?: boolean
}

export function ActionButtonLink({
  OnClickToEdit,
  OnClickDelete,
  OnClickAction,
  toView,
  isBlue,
  onClickActivate,
  onClickDesactivate,
}: Props) {
  return (
    <>
      <Link
        to=""
        className={isBlue ? "btn btn-primary ps-7" : "btn btn-light btn-active-light-primary btn-sm"}
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        onClick={OnClickAction}
      >
        Ações
        <span className="svg-icon svg-icon-5 m-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
              fill="currentColor"
              data-darkreader-inline-fill=""
            />
          </svg>
        </span>
      </Link>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
        data-kt-menu="true"
        style={{}}
      >
        {onClickActivate && (
          <div className="menu-item px-3">
            <button onClick={() => onClickActivate()} className="btn btn-white menu-link px-3 fs-7 w-100">
              Ativar
            </button>
          </div>
        )}
        {onClickDesactivate && (
          <div className="menu-item px-3">
            <button onClick={() => onClickDesactivate()} className="btn btn-white menu-link px-3 fs-7 w-100">
              Desativar
            </button>
          </div>
        )}
        {OnClickToEdit && (
          <div className="menu-item px-3">
            <button className="btn btn-white menu-link px-3 fs-7 w-100" onClick={OnClickToEdit}>
              Editar
            </button>
          </div>
        )}
        {OnClickDelete && (
          <div className="menu-item px-3">
            <button
              data-kt-subscriptions-table-filter="delete_row"
              className="btn btn-white menu-link px-3 fs-7 w-100"
              onClick={OnClickDelete} //Falta o dado de implementação da API
            >
              Deletar
            </button>
          </div>
        )}
      </div>
    </>
  )
}
