/* eslint-disable react/jsx-no-target-blank */
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub"
import { AsideMenuItem } from "./AsideMenuItem"
import { useAuthBelleza } from "../../../../../belleza/hooks/useAuthBelleza"

export function AsideMenuMain() {
  const { profile, user } = useAuthBelleza()

  return (
    <>
      {user?.user?.is_admin === 1 ? (
        <>
          {/* <AsideMenuItem
            to="/dashboard"
            icon="/media/icons/duotune/general/gen008.svg"
            title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
            fontIcon="bi-app-indicator"
          /> */}

          {/* <AsideMenuItem
          to='/builder'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Layout Builder'
          fontIcon='bi-layers'
        /> */}

          {/* <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                  <span className='menu-section text-muted text-uppercase fs-6 ls-1'>
                    FINANCEIRO
                  </span>
                </div>
              </div>

              <AsideMenuItem
                to='financeiro/transacoes'
                icon='/media/icons/duotune/abstract/abs014.svg'
                title='Transações'
                fontIcon='bi-layers'
              />

              <AsideMenuItem
                to='/financeiro/relatorios'
                icon='/media/icons/duotune/abstract/abs014.svg'
                title='Relatórios'
                fontIcon='bi-layers'
              /> */}

          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-6 ls-1">GESTÃO</span>
            </div>
          </div>

          <AsideMenuItem
            to="/gestao/agendamentos"
            icon="/media/icons/duotune/abstract/abs014.svg"
            title="Agendamentos"
            fontIcon="bi-layers"
          />

          <AsideMenuItem
            to="/gestao/clientes"
            icon="/media/icons/duotune/abstract/abs014.svg"
            title="Clientes"
            fontIcon="bi-layers"
          />

          <AsideMenuItem
            to="/gestao/parceiros"
            icon="/media/icons/duotune/abstract/abs014.svg"
            title="Parceiros"
            fontIcon="bi-layers"
          />

          <AsideMenuItem
            to="/gestao/categorias"
            icon="/media/icons/duotune/abstract/abs014.svg"
            title="Categorias"
            fontIcon="bi-layers"
          />

          <AsideMenuItem
            to="/gestao/servicos"
            title="Serviços"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/abstract/abs014.svg"
          />

          {/* <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-6 ls-1">CONFIGURAÇÕES</span>
            </div>
          </div> */}

          {/* <AsideMenuItem
            to="/configuracao/vitrine"
            icon="/media/icons/duotune/abstract/abs042.svg"
            title="Vitrine"
            fontIcon="bi-layers"
          /> */}

          {/* <AsideMenuItem
          to='/configuracao/meios-de-pagamento'
          icon='/media/icons/duotune/general/gen009.svg'
          title='Meios de pagamento'
          fontIcon='bi-layers'
        /> */}

          {/* <AsideMenuItem
          to='/configuracao/usuarios'
          icon='/media/icons/duotune/general/gen019.svg'
          title='Usuários'
          fontIcon='bi-layers'
        />
   */}

          {/* ativar/desativar::pages */}
          {/* <AsideMenuItemWithSub
          to='/crafted/pages'
          title='Pages'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
            <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <AsideMenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
  
          <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
            <AsideMenuItem
              to='/crafted/pages/wizards/horizontal'
              title='Horizontal'
              hasBullet={true}
            />
            <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </AsideMenuItemWithSub>
        </AsideMenuItemWithSub> */}

          {/* ativar/desativar::account */}
          {/* <AsideMenuItemWithSub
          to='/crafted/accounts'
          title='Accounts'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        >
          <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </AsideMenuItemWithSub> */}

          {/* ativar/desativar::errors */}
          {/* <AsideMenuItemWithSub
          to='/error'
          title='Errors'
          fontIcon='bi-sticky'
          icon='/media/icons/duotune/general/gen040.svg'
        >
          <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </AsideMenuItemWithSub> */}

          {/*
        <AsideMenuItemWithSub
          to='/crafted/widgets'
          title='Widgets'
          icon='/media/icons/duotune/general/gen025.svg'
          fontIcon='bi-layers'
        >
          <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </AsideMenuItemWithSub> 
        */}

          {/* ativar/desativar::Apps */}
          {/* <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
          </div>
        </div> */}

          {/* ativar/desativar::chat */}
          {/* <AsideMenuItemWithSub
          to='/apps/chat'
          title='Chat'
          fontIcon='bi-chat-left'
          icon='/media/icons/duotune/communication/com012.svg'
        >
          <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </AsideMenuItemWithSub> */}

          {/* ativar/desativar::calendar */}
          {/*  <AsideMenuItem
          to='/apps/user-management/users'
          icon='/media/icons/duotune/general/gen051.svg'
          title='User management'
          fontIcon='bi-layers'
        /> */}

          <div className="menu-item">
            <div className="menu-content">
              <div className="separator mx-1 my-4"></div>
            </div>
          </div>

          <div className="menu-item">
            {/* <a
            target='_blank'
            className='menu-link'
            href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
          >
            <span className='menu-icon'>
              <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
            </span>
            <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
          </a> */}
          </div>
        </>
      ) : user?.user?.is_admin === 0 && user?.user?.is_provider === 1 ? (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-6 ls-1">PARCEIRO</span>
            </div>
          </div>

          <AsideMenuItemWithSub
            to="/gestao/parceiros"
            title="Parceiros"
            fontIcon="bi-archive"
            icon="/media/icons/duotune/abstract/abs014.svg"
            className="here show"
          >
            <AsideMenuItem
              to={`/gestao/usuarios/${user.user.id}/visualizar-usuario`}
              title="Meu Perfil"
              hasBullet={true}
            />
            {/*
            <AsideMenuItem
              to="/gestao/parceiros/associacao-parceiros-servicos"
              title="Novo Serviço"
              hasBullet={true}
      />*/}
            <AsideMenuItem to="/gestao/parceiros/lista-parceiros-servicos" title="Meus Serviços" hasBullet={true} />
            <AsideMenuItem
              to="/gestao/parceiros/agendamentos-disponiveis"
              title="Agendamentos Disponíveis"
              hasBullet={true}
            />
            <AsideMenuItem
              to="/gestao/parceiros/agendamentos-em-andamento/"
              title="Meus agendamentos"
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
        </>
      ) : profile?.is_admin === 0 && profile?.is_provider === 0 ? (
        ""
      ) : (
        ""
      )}
    </>
  )
}
