// import { KTSVG } from "../../../_metronic/helpers";
import { SpinnerLoad } from "../SpinnerLoad";

export function SpinnerLoading() {
    return (
        //loading no meio da tela
        <div className="d-flex flex-row h-800px">
            <div className="d-flex flex-column flex-row-fluid">
                <div className="d-flex flex-row flex-column-fluid">
                    <div className="d-flex flex-row-fluid flex-center">
                        <SpinnerLoad />
                    </div>
                </div>
            </div>
        </div>
    );
}