import { FC, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import * as S from "./styles"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
import { SidebarCustomers } from "../components/SidebarCustomers"
import { ActionButtonLink } from "../../../components/ActionButtonLink"
import SchedulesTable from "../components/Schedules"
import SecurityInformations from "../components/SecurityInformations"
import useMount from "../../../utils/useMount"
import { useTransactions } from "../../../hooks/useTransactions"
import { useSchedules } from "../../../hooks/useSchedules"
import { useUser } from "../../../hooks/useUser"
import api from "../../../services/api"
import { useClients } from "../../../hooks/useClients"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

const PageViewClients: FC = () => {
  const params = useParams()
  const [active, setActive] = useState(1)
  const [toggleState] = useState(1)
  const [isLoading] = useState(false)
  const [status, setStatus] = useState(0)
  const { currentUser, fetchUser } = useUser()
  const { getClientSchedules } = useSchedules()
  const { fetchUserTranactions } = useTransactions()

  const { desactivateClient, ActivateClient } = useClients();

  let navigate = useNavigate()

  const pageTitleBreadCrumbs: Array<PageLink> = useMemo(
    () => [
      {
        title: `Cliente #${currentUser?.id}`,
        path: "/gestao/clientes/:id/visualizar-cliente",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
      },
    ],
    [currentUser?.id],
  )

  //carregar dados do cliente
  useMount(() => {
    fetchUser(params.id || "")
    fetchUserTranactions(params.id || "")
    getClientSchedules(`${params.id}`)
  })

  // const deleteUser = async () => {
  //   const isDeleted = await deleteClient(params.id || '0')
  //   if(isDeleted) {
  //     navigate('/gestao/clientes/1');
  //   }
  // }

  const deleteUserr = async (id: string) => {
    try {
      await api.delete(`/user/delete/${id}`)
      await Swal.fire("Sucesso ao Deletar!", "Cliente deletado", "success")
      navigate('/gestao/clientes/1');
    } catch (error) {
      Swal.fire("Erro ao Deletar!", "Cliente não deletado", "error")
    }
  }

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Clientes</PageTitle>

      {isLoading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <form
              id="kt_ecommerce_add_category_form"
              className="form d-flex flex-column flex-lg-row"
              data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html"
            >
              <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10"></div>
            </form>
          </div>
        </div>
      )}

      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div style={{ width: "100%" }} className="d-flex flex-column flex-lg-row">
          <SidebarCustomers
            codId={String(currentUser?.id)}
            name={currentUser?.name || ""}
            phone_number={currentUser?.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") || ""}
            document={currentUser?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") || ""}
            email={currentUser?.email || ""}
            last_access={currentUser?.last_access || ""}
            file={currentUser?.file || ""}
            is_admin={currentUser?.is_admin}
            is_provider={currentUser?.is_provider}
            status={status}
            value={status} //status do cliente
            onChange={e => setStatus(Number(e.target.value))}
          />

          <div className="flex-lg-row-fluid ms-lg-15">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="nav nav-tabs nav-line-tabs mb-5 fs-6 flex-between"
            >
              <S.LineTabs>
                <S.ButtonTab onClick={() => setActive(1)} active={active === 1}>
                  Geral
                </S.ButtonTab>
                <S.ButtonTab onClick={() => setActive(2)} active={active === 2}>
                  Segurança
                </S.ButtonTab>
              </S.LineTabs>

              { params.id && <ActionButtonLink // implementar aqui maikel! não tem informações dos endpoints na documentação
                onClickDesactivate={() => desactivateClient(params.id || '0')}
                onClickActivate={() => ActivateClient(params.id || '0')}
                OnClickDelete={() => deleteUserr(params.id || '0')}
                isBlue
              />}
            </div>
            <div className="tab-content" id="myTabContent">
              {/* <div
                className={`tab-pane fade ${toggleState === 1 ? "show active" : ""}`}
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <AdminPartnersUserServices userId={String(params.id)} />
              </div> */}
              {active === 1 && (
                <div style={{ marginTop: "40px" }}>
                  <SchedulesTable />
                </div>
              )}

              {active === 2 && <SecurityInformations />}

              <div
                className={`tab-pane fade ${toggleState === 2 ? "show active" : ""}`}
                id="kt_tab_pane_2"
                role="tabpanel"
              >
                {/* {
                                    transactions.map((transaction) => {
                                        return (
                                            <PaymentCustomers
                                                key={transaction.id}
                                                data={transaction}
                                            />
                                        )
                                    })
                                } */}
              </div>
              {/*  <div
                                className={`tab-pane fade ${toggleState === 3 ? 'show active' : ''}`} id='kt_tab_pane_3'
                                role='tabpanel'
                            >
                            </div>
                            <div
                                className={`tab-pane fade ${toggleState === 4 ? 'show active' : ''}`} id='kt_tab_pane_3'
                                role='tabpanel'
                            >
                                Documentos
                            </div>
                            <div
                                className={`tab-pane fade ${toggleState === 5 ? 'show active' : ''}`} id='kt_tab_pane_3'
                                role='tabpanel'
                            >
                                Produtos do Usuário
                            </div> */}
            </div>

            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel"></div>
              <div className="tab-pane fade" id="kt_user_view_overview_security" role="tabpanel">
                <h1>hello</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PageViewClients }
