import { useEffect } from "react"

import { Overlay } from "./styles"
import { useUser } from '../../../../hooks/useUser';

interface Props {
  showModal: boolean
  setShowModal: (value: boolean) => void
}

const EditEmail = ({ showModal, setShowModal }: Props) => {
  const {setCurrentUser, updateUser, currentUser} = useUser()
  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showModal])

  return (
    <>
      {showModal && <Overlay />}

      <div
        style={{ display: showModal ? "block" : "none", zIndex: 1001 }}
        className="modal fade show"
        id="kt_modal_update_details"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Atualizar endereço de e-mail</h2>
              <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                <span onClick={() => setShowModal(false)} className="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect
                      opacity="0.5"
                      x="6"
                      y="17.3137"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    ></rect>
                    <rect
                      x="7.41422"
                      y="6"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    ></rect>
                  </svg>
                </span>
              </div>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <form id="kt_modal_update_email_form" className="form" action="#">
                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
                  <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                      <rect
                        x="11"
                        y="14"
                        width="7"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11 14)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="11"
                        y="17"
                        width="2"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11 17)"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>

                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="fw-bold">
                      <div className="fs-6 text-gray-700">
                        Lembre que em caso de alteração de e-mail a Parceira precisará confirmar novamente sua conta
                        através do link que será enviado para o novo endereço.
                      </div>
                    </div>
                  </div>
                </div>

                <div className="fv-row mb-7">
                  <label className="fs-6 fw-bold form-label mb-2">
                    <span className="required">Novo e-mail</span>
                  </label>

                  <input
                    className="form-control form-control-solid"
                    placeholder="JhoeDue@email.com"
                    name="profile_email"
                    value={currentUser?.email}
                    onChange={e => {
                      setCurrentUser((prev: any) => {
                        return { ...prev, email: e.target.value }
                      })
                    }}
                  />
                </div>

                <div className="text-center pt-15">
                  <button
                    onClick={() => setShowModal(false)}
                    type="reset"
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Descartar
                  </button>
                  <button
                    onClick={async () => {
                      await updateUser(`${currentUser?.id}`)
                      window.location.reload()
                    }}
                    type="button"
                    className="btn btn-primary"
                    data-kt-users-modal-action="submit"
                  >
                    <span className="indicator-label">Alterar</span>
                    <span className="indicator-progress">
                      Aguarde...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditEmail
