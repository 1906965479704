import moment from "moment"
import { InputHTMLAttributes } from "react"
import { Link } from "react-router-dom"
import { UserModel } from "../../../../@types/api-user-model"
import { CheckBox } from "../../../../components/Form/CheckBox"
import { ActionButtonCustomers } from "../ActionButtonCustomers"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: UserModel
  onClickCheckBox?: () => void
  onClickActionView?: () => void
  onClickActionDisable?: () => void
  onClickActionActive?: () => void
  onClickApproved?: () => void
}

//API - Falta o dado de implementação da API

export function TableCustomers({
  data,
  onClickCheckBox,
  onClickActionView,
  onClickActionDisable,
  onClickActionActive,
  onClickApproved,
  ...rest
}: Props) {
  return (
    <>
      <tr className="w-100">
        {/* begin::checkbox */}

        {/* end::checkbox */}

        {/* begin::image and name*/}
        <td className="w-350px">
          <Link to={`/gestao/parceiros/${data.id}/visualizar-parceiro`}>
            <div className="d-flex align-items-center">
              <div className="d-flex">
                <Link to={`/gestao/parceiros/${data.id}/visualizar-parceiro`} className="symbol symbol-50px">
                  <span
                    className="symbol-label"
                    style={{
                      backgroundImage: data.file
                        ? `url(https://apibelleza.velty.com.br/storage/${data.file})`
                        : `url(https://iili.io/ZOF5Yu.png)`,
                    }}
                  />
                </Link>
              </div>

              {/* name e email */}
              <div className="d-flex flex-column ms-4">
                <Link
                  to={`/gestao/parceiros/${data.id}/visualizar-parceiro`}
                  className="text-gray-800 text-hover-primary mb-1 fs-5"
                >
                  {data.name}
                </Link>
                <span className="text-muted fw-bold text-muted d-block fs-7">{data.email}</span>
              </div>
            </div>
          </Link>
        </td>
        <td className="w-150px">
          <div className="mt-4"></div>
        </td>

        {/* begin::Active */}
        <td className="w-150px">
          <div className="mt-4">
            {data.last_access ? moment(data.last_access).format("DD/MM/YYYY") : "Sem registro"}
          </div>
        </td>
        {/* end::Active */}

        {/* begin::Active */}
        <td className="w-150px">
          <Link to={`/gestao/parceiros/${data.id}/visualizar-parceiro`}>
            <div className="d-flex mt-4 w-100 justify-content-start">
              {data.active ? (
                data.user_approved_at ? (
                  <div className="badge badge-light-success fs-7">Aprovado</div>
                ) : (
                  <div className="badge badge-light-danger fs-7">Não Aprovado</div>
                )
              ) : (
                <div className="badge badge-light-warning fs-7">Desativado</div>
              )}
            </div>
          </Link>
        </td>
        {/* end::Active */}

        {/*begin::data cadastro  */}
        <td className="w-150px ">
          <div className="d-flex mt-4 justify-content-start">
            {/* formato data pt-BR */}
            <span className="text-gray-500 fw-bold text-muted d-block fs-6">
              {/* formato data 	25 Jul 2022, 6:05 pm */}
              {/* observação: o .split nao pode ser null */}
              {data.created_at ? data.created_at.split("T")[0].split("-").reverse().join("/") : "SEM DATA"}
            </span>
          </div>
        </td>
        {/*end::data cadastro  */}

        <td className="w-150px">
          <div className="d-flex justify-content-end">
            <ActionButtonCustomers
              onClickView={onClickActionView}
              onClickActive={onClickActionActive}
              onClickDisable={onClickActionDisable}
              onClickApproved={onClickApproved}
            />
          </div>
        </td>
        {/* end::actions */}
      </tr>
    </>
  )
}
