import React, { FormEvent, useCallback, useEffect, useState } from "react"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { useAuthBelleza } from "../../hooks/useAuthBelleza"
import { CategoriesDTOs } from "../../dtos/CategoriesDTOs"
import api from "../../services/api"
import { Link, useNavigate } from "react-router-dom"
import { SpinnerLoading } from "../../components/SpinnerLoading"
import { useServices } from "../../hooks/useServices"
import Swal from "sweetalert2"

const AddService: React.FC = () => {
  const { profile } = useAuthBelleza()
  const navigate = useNavigate()
  const { createService, isLoading } = useServices()
  const [filePreview, setFilePreview] = useState("")
  const [name, setName] = useState("")
  const [price, setPrice] = useState(0)
  const [, setFormatedPrice] = useState("")
  const [desc, setDesc] = useState("")
  const [file, setFile] = useState<File>()
  const [selectedCategory, setSelectedCategory] = useState(0)
  const [categories, setCategories] = useState<CategoriesDTOs[]>([])

  /* Table */

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "gestao/servicos/adicionar-servico",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  //CRIAR SERVIÇOS
  const handleCreateService = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!file || !name || !price || !desc || !selectedCategory) {
        return Swal.fire("Preencher todos os campos!", "Erro ao criar serviço", "error")
      }

      const data = new FormData()

      data.append("name", name)
      data.append("desc", desc)
      data.append("price", String(price / 100))
      data.append("category_id", String(selectedCategory))
      data.append("file", file!)
      data.append("disabled", "0")

      await createService(data)
      navigate("/gestao/servicos")
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, desc, file, price, selectedCategory],
  )

  const newPrice = (e: any) => {
    var v = e.replace(/\D/g, "")
    v = (v / 100).toFixed(2) + ""
    v = v.replace(".", ",")
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
    v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
    e = v
    return e
  }

  const formatterNumber = useCallback((number: any) => {
    var formatString = number
    formatString = formatString.replace(",", "")
    formatString = parseFloat(formatString)
    return formatString
  }, [])

  //carregar selected categories dados
  useEffect(() => {
    async function loadCategory() {
      const response = await api.get(`/categories`)
      setCategories(response.data[0])
    }
    loadCategory()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Serviços</PageTitle>

      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Adicionar Serviço</h3>
            </div>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <form noValidate className="form">
              <div className="card-body border-top p-9">
                {/* begin::Input Avatar Photo */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Foto Serviço</label>

                  <div className="col-lg-8">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage: `url(${filePreview})`,
                      }}
                    >
                      <div
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          backgroundImage: filePreview ? `url(${filePreview})` : "url(https://iili.io/ZNb6Wg.png)",
                        }}
                      >
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Alterar avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7">
                            <input
                              type="file"
                              name="avatar"
                              accept=".png,.jpg,.jpeg"
                              onChange={e => {
                                if (e.target.files) {
                                  if (e.target.files[0].size > 999999) {
                                    Swal.fire("Imagem muito grande!", "Tamanho máximo: 1 mb", "error")
                                  } else {
                                    const reader = new FileReader()
                                    reader.readAsDataURL(e.target.files[0])
                                    reader.onload = () => {
                                      setFilePreview(reader.result as string)
                                    }
                                  }
                                }
                                if (e.target.files) {
                                  setFile(e.target.files[0])
                                }
                              }}
                            />
                          </i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Categoria do Serviço</label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-select"
                      aria-label="Select example"
                      onChange={e => setSelectedCategory(Number(e.target.value))}
                    >
                      <option>Selecione a categoria do serviço</option>
                      {categories.map(category => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Nome do serviço</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Nome da serviço"
                      name="Nome da serviço"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Preço do serviço</label>

                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Preço do Serviço"
                      name="Preço do Serviço"
                      value={newPrice(`${price}`)}
                      onChange={e => {
                        setPrice(formatterNumber(e.target.value))
                        setFormatedPrice(e.target.value)
                      }}
                    />
                  </div>
                </div>

                {/* begin::Input Desc */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Descrição</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Descrição"
                      name="desc"
                      value={desc}
                      onChange={e => {
                        setDesc(e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <div className="d-flex justify-content-end">
                    <Link to={"/gestao/servicos"} type="button" className="btn btn-light-primary me-3">
                      Voltar
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={handleCreateService}>
                    Criar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export { AddService }
