import { useState } from "react"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { HeaderSearchAdd } from "../../components/HeaderSearchAdd"
import { SpinnerLoading } from "../../components/SpinnerLoading"
import { TableCategories } from "./TableCategories"
import { Environment } from "../../environment"
import { Paginations } from "../../components/Form/Pagination"
import { useNavigate } from "react-router-dom"
import { useCategories } from "../../hooks/useCategories"
import useMount from "../../utils/useMount"

export function Categories() {
  const { fetchCategories, categories, loading, deleteCategoryById } = useCategories()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [checked, setChecked] = useState(false)
  const [selectedId, setSelectedId] = useState<string[]>([])
  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: ``,
      path: "",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  // function handleCloseModalEdit() {
  //     setStatusModalEdit(false);
  // }

  // function handleShowModalEdit() {
  //     setStatusModalEdit(true);
  // }

  // //index da pagination atual
  // function currentPagination(index: number) {
  //     setCurrentPage(index);
  // }

  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  useMount(() => {
    fetchCategories()
  })

  //delete categories
  function handleViewCustomer(id: string) {
    navigate(`/gestao/categorias/${id}/editar-categoria`)
  }

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Categorias</PageTitle>
      <KTCard>
        {loading ? (
          <SpinnerLoading />
        ) : (
          <div>
            <div>
              <HeaderSearchAdd href="/gestao/categorias/adicionar-categoria" />
            </div>

            <KTCardBody>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  {/* START HEADER TABLE */}
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th>Nome</th>
                      <th></th>
                      <th>Descrição</th>
                      <th>N° Serviços</th>
                      <th>Criado em</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {categories.length > 0 ? (
                    <tbody className="text-gray-600 fw-bold">
                      {categories
                        .slice((page - 1) * Environment.LINHA_DE_LINHAS, page * Environment.LINHA_DE_LINHAS)
                        .map(item => {
                          return (
                            <TableCategories
                              key={item.id}
                              data={item}
                              OnClickToEdit={() => handleViewCustomer(item.id.toString())}
                              toView={""}
                              OnClickDelete={() => deleteCategoryById(item.id)}
                              onClickCheckBox={() => handleChecked(item.id.toString())}
                              checked={checked && selectedId[0] === item.id.toString() ? true : false}
                            />
                          )
                        })}
                    </tbody>
                  ) : (
                    <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                      Sem categorias
                    </div>
                  )}
                </table>
              </div>
            </KTCardBody>

            <Paginations totalCountOfRegisters={categories.length} currentPage={page} onPageChange={setPage} />
          </div>
        )}
      </KTCard>
    </>
  )
}
