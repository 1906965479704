import { useCallback, useEffect, useState } from "react"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { TableCustomers } from "./components/TableCustomers"
import { HeaderSearch } from "../../components/HeaderSearch"
import { useNavigate } from "react-router-dom"
import { SpinnerLoadingMiddle } from "../../components/SpinnerLoadingMiddle"
import { PaginationsAPI } from "../../components/Form/PaginationAPI"
import useMount from "../../utils/useMount"
import { useClients } from "../../hooks/useClients"
import { Button, Dropdown, Form } from "react-bootstrap"
import { useForm } from "react-hook-form"

export function Clients() {
  let navigate = useNavigate()
  const { fetchClients, clients, loading, aproveClient, desactivateClient, ActivateClient, total } = useClients()
  const [checked, setChecked] = useState(false)
  const [selectedId, setSelectedId] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const day = new Date().getDate()
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: ``,
      path: "/gestao/clientes/:id",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  /* AQUI COMEÇAR A IMPLEMENTAR FUNÇÕES*/
  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  const handleApproved = useCallback(
    (id: string) => {
      aproveClient(id, `${year}-${month}-${day}`, `${year}-${month}-${day}`)
    },
    [aproveClient, day, month, year],
  )

  function handleViewCustomer(id: string) {
    navigate(`/gestao/usuarios/${id}/visualizar-usuario`)
  }

  useMount(() => {
    fetchClients(page)
  })

  useEffect(() => {
    fetchClients(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    function nextPage() {
      navigate(`/gestao/clientes/${page}`)
    }
    nextPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Clientes</PageTitle>
      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <KTCard>
          <div>
            <HeaderSearch type="clients" />

            <KTCardBody>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th>Clientes</th>
                      <th>Tipo</th>
                      <th>Status</th>
                      <th>Aprovado</th>
                      <th>Data de Cadastro</th>
                      <th className="text-end pe-6">Ações</th>
                    </tr>
                  </thead>

                  <tbody className="text-gray-600 fw-bold">
                    {
                      /* customersList
                                                    .filter((item) => item.is_admin === 0 && item.is_provider === 0 && item.is_company === 0)
                                                    .map((item) => {
                                                        return (
                                                            <TableCustomers
                                                                key={item.id}
                                                                data={item}
                                                                onClickCheckBox={() => handleChecked(item.id.toString())}
                                                                onClickActionDisable={() => handleDeleteCategories(item.id)}
                                                                onClickActionView={handleViewCustomer}
                                                            />
                                                        )
                                                    }) */
                      clients.length > 0 ? (
                        clients.map(item => {
                          return (
                            <TableCustomers
                              key={item.id}
                              data={item}
                              onClickActionDisable={() => desactivateClient(item.id.toString())}
                              onClickActionActive={() => ActivateClient(item.id.toString())}
                              onClickActionView={() => handleViewCustomer(item.id.toString())}
                              onClickCheckBox={() => handleChecked(item.id.toString())}
                              checked={checked && selectedId[0] === item.id.toString() ? true : false}
                              onClickApproved={() => handleApproved(item.id.toString())}
                            />
                          )
                        })
                      ) : (
                        <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                          Sem clientes
                        </div>
                      )
                    }
                  </tbody>
                </table>
              </div>
            </KTCardBody>
            <PaginationsAPI totalCountOfRegisters={total} currentPage={page} onPageChange={setPage} />
          </div>
        </KTCard>
      )}
    </>
  )
}
