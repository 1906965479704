import { FC, FormEvent, useCallback, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { SpinnerLoading } from "../../components/SpinnerLoading"
import { useCategories } from "../../hooks/useCategories"
import Swal from "sweetalert2"

const AddCategory: FC = () => {
  const navigate = useNavigate()
  const { createCategory, loading } = useCategories()
  const [file, setFile] = useState<File>()
  const [filePreview, setFilePreview] = useState("")
  const [name, setName] = useState("")
  const [desc, setDesc] = useState("")

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `Criar categoria`,
      path: "gestao/categorias/adicionar-categoria",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]
  const handleCreateCategory = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()

      if (!name || !desc) {
        Swal.fire("Preencher Campos!", "Por favor preencher todos os campos", "error")
        return
      }
      const data = new FormData()

      data.append("name", name)
      data.append("desc", desc)
      data.append("disabled", "0")

      if (file) {
        data.append("file", file!)
      }
      await createCategory(data)
      navigate("/gestao/categorias")
    },
    [name, desc, file, createCategory, navigate],
  )

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Categorias</PageTitle>

      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="card mb-5 mb-xl-10">
          {/* begin:Profile Details */}
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Adicionar Categoria</h3>
            </div>
          </div>

          {/* begin:: Body */}
          <div id="kt_account_profile_details" className="collapse show">
            <form noValidate className="form">
              <div className="card-body border-top p-9">
                {/* begin::Input Avatar Photo */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Foto Categoria</label>

                  <div className="col-lg-8">
                    <div
                      className="image-input image-input-outline"
                      data-kt-image-input="true"
                      style={{
                        backgroundImage:
                          //base64, //preview da imagem
                          `url(${filePreview})`,
                      }}
                    >
                      <div
                        className="image-input-wrapper w-125px h-125px"
                        style={{
                          //se tiver imagem, mostra a imagem, senão mostra o avatars/300-21.jpg
                          backgroundImage: filePreview ? `url(${filePreview})` : "url(https://iili.io/ZNb6Wg.png)",
                        }}
                      >
                        <label
                          className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                          data-kt-image-input-action="change"
                          data-bs-toggle="tooltip"
                          title="Alterar avatar"
                        >
                          <i className="bi bi-pencil-fill fs-7">
                            <input
                              type="file"
                              name="avatar"
                              accept=".png,.jpg,.jpeg"
                              onChange={e => {
                                //base64
                                if (e.target.files) {
                                  if (e.target.files[0].size > 999999) {
                                    Swal.fire("Imagem muito grande!", "Tamanho máximo: 1 mb", "error")
                                  } else {
                                    const reader = new FileReader()
                                    reader.readAsDataURL(e.target.files[0])
                                    reader.onload = () => {
                                      setFilePreview(reader.result as string)
                                    }
                                  }
                                }

                                //file
                                if (e.target.files) {
                                  setFile(e.target.files[0])
                                }
                              }}
                            />
                          </i>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Input Avatar Photo */}

                {/* begin::Input Name */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Nome da Categoria</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Nome da Categoria"
                      name="Nome da Categoria"
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                </div>
                {/* end::Input Name */}

                {/* begin::Input Email */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Descrição</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Descrição"
                      name="desc"
                      value={desc}
                      onChange={e => {
                        setDesc(e.target.value)
                      }}
                    />
                  </div>
                </div>

                {/* end::Input Email */}

                {/* begin::Button Save */}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <div className="d-flex justify-content-end">
                    <Link to={"/gestao/categorias"} type="button" className="btn btn-light-primary me-3">
                      Voltar
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={handleCreateCategory}>
                    Criar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export { AddCategory }
