import { useCallback, useEffect, useState } from "react"
import Swal from "sweetalert2"
import { PickerPhoto } from "../../../../components/PickerPhoto"
import { useUser } from "../../../../hooks/useUser"
import { Overlay } from "./styles"

interface Props {
  showModal: boolean
  setShowModal: (value: boolean) => void
}

const EditCustomer = ({ showModal, setShowModal }: Props) => {
  const { updateUserPhoto, removeUserPhoto, currentUser, setCurrentUser, updateUser } = useUser()
  const [edit, setEdit] = useState(false)
  const [currentFile, setCurrentFile] = useState<File | any>()

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showModal])

  const inputChange = useCallback(
    (key: string, value: string | File) => {
      setCurrentUser((prev: any) => {
        return { ...prev, [key]: value }
      })
    },
    [setCurrentUser],
  )
  if (currentUser && currentUser?.name.length > 30) {
    Swal.fire({
      icon: "warning",
      title: "O nome do usuário é muito grande!",
      text: "O nome precisa ser atualizado! Alguns funções podem ser comprometidas se a atualização não for realizada.",
    })
  }
  const submitInformations = useCallback(async () => {
    if (!currentUser) return
    updateUser(`${currentUser?.id}`)
    const data = new FormData()
    if (currentFile && edit) {
      await removeUserPhoto(`${currentUser?.id}`)
      data.append("file", currentFile)
      updateUserPhoto(`${currentUser?.id}` || "", data)
    }
  }, [currentFile, currentUser, edit, removeUserPhoto, updateUser, updateUserPhoto])

  return (
    <>
      <span
        data-bs-toggle="tooltip"
        data-bs-trigger="hover"
        title=""
        data-bs-original-title="Editar informações da parceira"
      >
        <button
          onClick={() => setShowModal(true)}
          className="btn btn-sm btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_update_details"
        >
          Editar
        </button>
      </span>
      {showModal && <Overlay />}

      <div
        style={{ display: showModal ? "block" : "none", zIndex: 1001 }}
        className="modal fade show"
        id="kt_modal_update_details"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <form className="form" action="#" id="kt_modal_update_user_form">
              <div className="modal-header" id="kt_modal_update_user_header">
                <h2 className="fw-bolder">Atualizar Parceira</h2>

                <div
                  onClick={() => setShowModal(false)}
                  className="btn btn-icon btn-sm btn-active-icon-primary"
                  data-kt-users-modal-action="close"
                >
                  <span className="svg-icon svg-icon-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect
                        opacity="0.5"
                        x="6"
                        y="17.3137"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-45 6 17.3137)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="7.41422"
                        y="6"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(45 7.41422 6)"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                </div>
              </div>

              <div className="modal-body py-10 px-lg-17">
                <div
                  className="d-flex flex-column scroll-y me-n7 pe-7"
                  id="kt_modal_update_user_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_update_user_header"
                  data-kt-scroll-wrappers="#kt_modal_update_user_scroll"
                  data-kt-scroll-offset="300px"
                  style={{ maxHeight: "414px" }}
                >
                  <a
                    className="fw-boldest fs-3 rotate collapsible mb-7 text-black"
                    data-bs-toggle="collapse"
                    href="#kt_modal_update_user_user_info"
                    role="button"
                    aria-expanded="false"
                    aria-controls="kt_modal_update_user_user_info"
                  >
                    Dados pessoais
                  </a>

                  <div id="kt_modal_update_user_user_info" className="collapse show">
                    <div className="mb-7">
                      <label className="fs-6 fw-bold mb-2">
                        <span>Atualizar Foto</span>
                        <i
                          className="fas fa-exclamation-circle ms-1 fs-7"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="Arquivos permitidos: png, jpg, jpeg."
                          aria-label="Arquivos permitidos: png, jpg, jpeg."
                        ></i>
                      </label>

                      <div style={{ width: "fit-content", position: "relative" }} className="mt-1">
                        <PickerPhoto
                          onFileSelected={file => {
                            setCurrentFile(file)
                            // inputChange("file", file)
                            setEdit(true)
                          }}
                          image={currentUser?.file || ""}
                        />
                      </div>
                    </div>

                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-bold mb-2">Nome</label>

                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder=""
                        name="name"
                        value={currentUser?.name}
                        onChange={e => inputChange("name", e.target.value)}
                        maxLength={30}
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-bold mb-2">CPF</label>

                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder=""
                        name="name"
                        value={currentUser?.document}
                        onChange={e => inputChange("document", e.target.value)}
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-bold mb-2">
                        <span>Email</span>
                        <i
                          className="fas fa-exclamation-circle ms-1 fs-7"
                          data-bs-toggle="tooltip"
                          title=""
                          data-bs-original-title="O email precisa ser validado"
                          aria-label="O email precisa ser validado"
                        ></i>
                      </label>

                      <input
                        disabled
                        type="email"
                        className="form-control form-control-solid"
                        placeholder=""
                        name="email"
                        value={currentUser?.email}
                        onChange={e => inputChange("email", e.target.value)}
                      />
                    </div>

                    <div className="fv-row mb-7">
                      <label className="fs-6 fw-bold mb-2">Descrição</label>

                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder=""
                        name="description"
                        value={currentUser?.bio || ""}
                        onChange={e => inputChange("bio", e.target.value)}
                      />
                    </div>
                  </div>

                  <a
                    className="fw-boldest fs-3 rotate collapsible mb-7 text-black"
                    data-bs-toggle="collapse"
                    href="#kt_modal_update_user_address"
                    role="button"
                    aria-expanded="false"
                    aria-controls="kt_modal_update_user_address"
                  >
                    Endereço
                  </a>

                  <div id="kt_modal_update_user_address" className="collapse show">
                    <div className="d-flex flex-column mb-7 fv-row">
                      <label className="fs-6 fw-bold mb-2">Logradouro</label>

                      <input
                        className="form-control form-control-solid"
                        placeholder=""
                        name="address1"
                        value={currentUser?.address_line1}
                        onChange={e => inputChange("address_line1", e.target.value)}
                      />
                    </div>

                    <div className="d-flex flex-column mb-7 fv-row">
                      <label className="fs-6 fw-bold mb-2">Complemento</label>

                      <input
                        className="form-control form-control-solid"
                        placeholder=""
                        name="address2"
                        value={currentUser?.address_line2 || ""}
                        onChange={e => inputChange("address_line2", e.target.value)}
                      />
                    </div>

                    <div className="d-flex flex-column mb-7 fv-row">
                      <label className="fs-6 fw-bold mb-2">Bairro</label>

                      <input className="form-control form-control-solid" placeholder="" value="Lagoa Nova" />
                    </div>

                    <div className="row g-9 mb-7">
                      <div className="col-md-6 fv-row">
                        <label className="fs-6 fw-bold mb-2">Cidade</label>

                        <input
                          className="form-control form-control-solid"
                          placeholder=""
                          value={currentUser?.city}
                          onChange={e => inputChange("city", e.target.value)}
                        />
                      </div>

                      <div className="col-md-6 fv-row">
                        <label className="fs-6 fw-bold mb-2">CEP</label>

                        <input
                          className="form-control form-control-solid"
                          placeholder=""
                          name="postcode"
                          value={currentUser?.zipcode}
                          onChange={e => inputChange("zipcode", e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer flex-center">
                <button
                  onClick={() => setShowModal(false)}
                  type="button"
                  className="btn btn-light me-3"
                  data-kt-users-modal-action="cancel"
                >
                  Descartar
                </button>

                <button
                  onClick={() => submitInformations()}
                  type="button"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                >
                  <span className="indicator-label">Atualizar</span>
                  <span className="indicator-progress">
                    Aguarde...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditCustomer
