import React, { useCallback, useContext, useMemo, useState } from "react"
import Swal from "sweetalert2"
import { UserModel } from "../@types/api-user-model"
import api from "../services/api"

export type ContextValue = {
  DesactivatePartner: (id: string) => void
  ActivatePartner: (id: string) => void
  providers: UserModel[]
  fetchProviders: (page: number) => Promise<void>
  loading: boolean
  total: number
  aproveProvider: (id: string, approved_at: string, verified_at: string) => Promise<void>
  fetchPartnerServices: (id: string) => Promise<void>
  partnerServices: UserProducts[]
  fetchPartnerStatus: (id: string) => Promise<void>
  statusAppointments: Status | undefined
  filterPartners: (data: any) => Promise<void>
  isFiltered: boolean
}

export const PartnersContext = React.createContext<ContextValue | undefined>(undefined)

export const PartnersProvider: React.FC = props => {
  const [providers, setProviders] = useState<UserModel[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [partnerServices, setPartnerServices] = useState<UserProducts[]>([])
  const [statusAppointments, setStatusAppointments] = useState<Status>()
  const [isFiltered, setIsFiltered] = useState(false)

  const fetchProviders = useCallback(async (page: number) => {
    setLoading(true)
    try {
      const response = await api.get(`/user/index?page=${page}&filter=is_provider`)

      setProviders(response.data[0].data)
      setTotal(response.data[0].meta.total)
    } catch (error) {
    } finally {
      setLoading(false)
      setIsFiltered(false)
    }
  }, [])

  const fetchPartnerServices = useCallback(async (id: string) => {
    try {
      const response = await api.get(`user_products/user/${id}`)

      setPartnerServices(response.data)
    } catch (error) {}
  }, [])

  const fetchPartnerStatus = useCallback(async (id: string) => {
    try {
      const response = await api.get(`/appointments/stats/${id}`)
      setStatusAppointments(response.data[0])
    } catch (error) {}
  }, [])

  const aproveProvider = useCallback(async (id: string, approved_at: string, verified_at: string) => {
    try {
      await api.post(`/user/settings/${id}`, { user_approved_at: approved_at, email_verified_at: verified_at })
      await Swal.fire("Sucesso ao aprovar!", "Parceiro aprovado", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao aprovar!", "Parceiro não aprovado", "error")
    }
  }, [])

  const DesactivatePartner = useCallback(async (id: string) => {
    try {
      await api.post(`/user/settings/${id}`, { active: false })
      await Swal.fire("Sucesso ao desativar!", "Parceiro desativado", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao desativar!", "Não foi possível desativar", "error")
    }
  }, [])

  const ActivatePartner = useCallback(async (id: string) => {
    try {
      await api.post(`/user/settings/${id}`, { active: true })
      await Swal.fire("Sucesso ao Ativar!", "Parceiro ativado", "success")
      window.location.reload()
    } catch (error) {
      Swal.fire("Erro ao Ativar!", "Parceiro não ativado", "error")
    }
  }, [])

  const filterPartners = useCallback(async (data: any) => {
    setLoading(true)
    try {
      const response = await api.get(`/user/index?&filter_field=${data.field}&filter_value=${data.value}`)
      setProviders(response.data[0].data)
      setTotal(response.data[0].meta.total)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
      setIsFiltered(true)
    }
  }, [])

  const value = useMemo(
    () => ({
      DesactivatePartner,
      ActivatePartner,
      providers,
      fetchProviders,
      loading,
      total,
      aproveProvider,
      fetchPartnerServices,
      partnerServices,
      fetchPartnerStatus,
      statusAppointments,
      filterPartners,
      isFiltered,
    }),
    [
      DesactivatePartner,
      ActivatePartner,
      providers,
      fetchProviders,
      loading,
      total,
      aproveProvider,
      fetchPartnerServices,
      partnerServices,
      fetchPartnerStatus,
      statusAppointments,
      filterPartners,
      isFiltered,
    ],
  )

  return <PartnersContext.Provider value={value} {...props} />
}

export const usePartners = (): ContextValue => {
  const context = useContext(PartnersContext)

  if (context === undefined) {
    throw new Error("usePartners must be used within an PartnersProvider")
  }

  return context
}

//
// Utils
//

interface Status {
  as_provider: {
    complete: number
    pending: number
    total: number
  }
}

interface UserProducts {
  product: {
    id: number
    category_id: number
    name: string
    file: null
    price: number
    desc: string
    created_at: string
    updated_at: string
    category: {
      id: number
      name: string
      file: null
      desc: string
      created_at: string
      updated_at: string
    }
  }
}
