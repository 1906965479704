import ReactDOM from "react-dom"
// Axios
import axios from "axios"
import { Chart, registerables } from "chart.js"
import { QueryClient, QueryClientProvider } from "react-query"
import GlobalStyles from "./styles/global"
// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n"
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/style.scss"
import "./_metronic/assets/sass/style.react.scss"
import { AppRoutes } from "./app/routing/AppRoutes"
import { AuthProvider, setupAxios } from "./app/modules/auth"
import { AuthProviderBelleza } from "./belleza/hooks/useAuthBelleza"
import { SchedulesProvider } from "./belleza/hooks/useSchedules"
import { ServicesProvider } from "./belleza/hooks/useServices"
import { CategoriesProvider } from "./belleza/hooks/useCategories"
import { PartnersProvider } from "./belleza/hooks/usePartners"
import { ClientsProvider } from "./belleza/hooks/useClients"
import { TransactionsProvider } from "./belleza/hooks/useTransactions"
import { UserProvider } from "./belleza/hooks/useUser"
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AuthProviderBelleza>
      <MetronicI18nProvider>
        <SchedulesProvider>
          <AuthProvider>
            <UserProvider>
            <CategoriesProvider>
              <ServicesProvider>
                <PartnersProvider>
                  <ClientsProvider>
                    <TransactionsProvider>
                      <AppRoutes />
                    </TransactionsProvider>
                  </ClientsProvider>
                </PartnersProvider>
              </ServicesProvider>
            </CategoriesProvider>
            </UserProvider>
          </AuthProvider>
        </SchedulesProvider>
      </MetronicI18nProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </AuthProviderBelleza>
    <GlobalStyles />
  </QueryClientProvider>,
  document.getElementById("root"),
)
