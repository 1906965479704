import { SelectHTMLAttributes, useState } from "react"
import { Link } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import EditCustomer from "../EditCustomer"
import { usePartners } from "../../../../hooks/usePartners"
import useMount from "../../../../utils/useMount"
import { useUser } from "../../../../hooks/useUser"
import { BsFillStarFill, BsStar } from "react-icons/bs"

interface SidebarCustomersProps extends SelectHTMLAttributes<HTMLSelectElement> {
  file: string
  name: string
  email: string
  document: string
  codId: string
  phone_number: string
  last_access: string
  status?: number
  is_admin?: number
  is_provider?: number
  rating?: number | null | undefined
}

export function SidebarCustomers({
  file,
  name,
  email,
  codId,
  status,
  last_access,
  phone_number,
  document,
  is_admin,
  is_provider,
  rating,
  ...rest
}: SidebarCustomersProps) {
  const [showEdit, setShowEdit] = useState(false)
  const { currentUser } = useUser()
  const { fetchPartnerServices, partnerServices, fetchPartnerStatus, statusAppointments } = usePartners()

  useMount(() => {
    fetchPartnerServices(codId)
    fetchPartnerStatus(codId)
  })

  const handleRating = (rating: number | null | undefined) => {
    switch (rating) {
      case 1:
        return (
          <div className="flex justify-content-between gap-2">
            <BsFillStarFill color="#ffd000" />
            <BsStar color="#ffd000" />
            <BsStar color="#ffd000" />
            <BsStar color="#ffd000" />
            <BsStar color="#ffd000" />
          </div>
        )
      case 2:
        return (
          <div className="flex justify-content-between gap-2">
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsStar color="#ffd000" />
            <BsStar color="#ffd000" />
            <BsStar color="#ffd000" />
          </div>
        )
      case 3:
        return (
          <div className="flex justify-content-between gap-2">
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsStar color="#ffd000" />
            <BsStar color="#ffd000" />
          </div>
        )
      case 4:
        return (
          <div className="flex justify-content-between gap-2">
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsStar color="#ffd000" />
          </div>
        )
      case 5:
        return (
          <div className="flex justify-content-between gap-2">
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
            <BsFillStarFill color="#ffd000" />
          </div>
        )
      case null:
        return (
          <div className="badge badge-lg badge-light-primary d-inline">
            <span>Sem avaliações</span>
          </div>
        )
      default:
        break
    }
  }

  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <div className="symbol symbol-100px symbol-circle mb-7">
              <img
                style={{ objectFit: "cover" }}
                className="symbol-label"
                src={
                  file ? `https://apibelleza.velty.com.br/storage/${file}` : toAbsoluteUrl("/media/avatars/blank.png")
                }
                alt="user"
              />
            </div>
            <Link to="#" className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">
              {name}
            </Link>
            <div className="mb-9">
              <div className="mb-4">{handleRating(rating)}</div>
              <div className="badge badge-lg badge-light-primary d-inline">
                {!partnerServices?.length ? (
                  partnerServices.map(item => item?.product?.name)
                ) : (
                  <span>Sem serviços</span>
                )}
              </div>
            </div>
            <div className="fw-bolder mb-3">
              Serviços prestados
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="popover"
                data-bs-trigger="hover"
                data-bs-html="true"
                data-bs-content="Número de serviços prestados desde o seu cadastro inicial"
                data-bs-original-title=""
                title=""
              />
            </div>
            <div className="d-flex flex-wrap flex-center">
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3 flex flex-col items-center">
                <div className="fs-4 fw-bolder text-gray-700">
                  <span className="w-75px">{statusAppointments?.as_provider?.complete}</span>
                  <span className="svg-icon svg-icon-3 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect
                        opacity="0.5"
                        x="13"
                        y="6"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(90 13 6)"
                        fill="currentColor"
                      />
                      <path
                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="fw-bold text-muted">Total</div>
              </div>
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3 flex flex-col items-center">
                <div className="fs-4 fw-bolder text-gray-700">
                  <span className="w-50px">{statusAppointments?.as_provider?.complete}</span>
                  <span className="svg-icon svg-icon-3 svg-icon-danger">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect
                        opacity="0.5"
                        x="11"
                        y="18"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11 18)"
                        fill="currentColor"
                      />
                      <path
                        d="M11.4343 15.4343L7.25 11.25C6.83579 10.8358 6.16421 10.8358 5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75L11.2929 18.2929C11.6834 18.6834 12.3166 18.6834 12.7071 18.2929L18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25C17.8358 10.8358 17.1642 10.8358 16.75 11.25L12.5657 15.4343C12.2533 15.7467 11.7467 15.7467 11.4343 15.4343Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="fw-bold text-muted">Concluídos</div>
              </div>
              <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3 flex flex-col items-center">
                <div className="fs-4 fw-bolder text-gray-700">
                  <span className="w-50px">{statusAppointments?.as_provider?.pending}</span>
                  <span className="svg-icon svg-icon-3 svg-icon-success">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect
                        opacity="0.5"
                        x="13"
                        y="6"
                        width="13"
                        height="2"
                        rx="1"
                        transform="rotate(90 13 6)"
                        fill="currentColor"
                      />
                      <path
                        d="M12.5657 8.56569L16.75 12.75C17.1642 13.1642 17.8358 13.1642 18.25 12.75C18.6642 12.3358 18.6642 11.6642 18.25 11.25L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711L5.75 11.25C5.33579 11.6642 5.33579 12.3358 5.75 12.75C6.16421 13.1642 6.83579 13.1642 7.25 12.75L11.4343 8.56569C11.7467 8.25327 12.2533 8.25327 12.5657 8.56569Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="fw-bold text-muted">Pendentes</div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div
              className="fw-bolder rotate collapsible"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="kt_user_view_details"
            >
              Detalhes
              <span className="ms-2 rotate-180">
                <span className="svg-icon svg-icon-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </span>
            </div>
            <EditCustomer showModal={showEdit} setShowModal={setShowEdit} />
          </div>
          <div className="separator" />
          <div id="kt_user_view_details" className="collapse show">
            <div className="pb-5 fs-6">
              <div className="d-flex justify-content-between">
                <div className="pb-5 fs-6">
                  <div className="fw-bolder mt-5">Código</div>
                  <div className="text-gray-600">ID-{codId}</div>
                </div>

                <div className="pb-5 fs-6">
                  <div className="fw-bolder mt-6"></div>
                  {is_admin === 1 ? (
                    <div className="badge badge-primary fs-7 me-5">Admin</div>
                  ) : is_provider === 1 ? (
                    <div className="badge badge-warning fs-7 me-5">Parceiro</div>
                  ) : (
                    <div className="badge badge-danger fs-7 me-5">Cliente</div>
                  )}
                </div>
              </div>
              <div className="fw-bolder mt-5">Email</div>
              <div className="text-gray-600">
                <Link to="#" className="text-gray-600 text-hover-primary">
                  {email}
                </Link>
              </div>
              <div className="fw-bolder mt-5">Telefone</div>
              <div className="text-gray-600">
                <Link to="#" className="text-gray-600 text-hover-primary">
                  {phone_number}
                </Link>
              </div>
              <div className="fw-bolder mt-5">CPF</div>
              <div className="text-gray-600">
                <Link to="#" className="text-gray-600 text-hover-primary">
                  {document} 
                </Link>
              </div>
              <div className="fw-bolder mt-5">Endereço</div>
              <div className="text-gray-600">
                {currentUser?.address_line1}
                <br />
                {currentUser?.address_line2}
                <br />
                {currentUser?.city}/ {currentUser?.state}
              </div>
              {/* <div className="fw-bolder mt-5">Último acesso</div>
              <div className="text-gray-600">{last_access ? last_access : "?"}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
