import React, { useCallback, useContext, useMemo, useState } from "react"
import Swal from "sweetalert2"
import { CategoriesDTOs } from "../dtos/CategoriesDTOs"
import api from "../services/api"

export type ContextValue = {
  fetchCategories: () => Promise<void>
  categories: CategoriesDTOs[]
  page: number
  loading: boolean
  deleteCategoryById: (categoryId: number) => Promise<void>
  editCategoryById: (
    categoryId: string,
    name: string,
    desc: string,
    file: any,
    /*
    data:
      | {
          id: string
          name: string
          desc: string
          disabled: string
        }
      | FormData,
  */
  ) => Promise<void>
  editPictureById: (categoryId: string, data: FormData) => Promise<void>
  deletePictureById: (categoryId: string) => Promise<void>
  fetchCategoryById: (categoryId: string) => Promise<void>
  currentCategory: CategoryResponse | undefined
  setCurrentCategory: React.Dispatch<React.SetStateAction<CategoryResponse | undefined>>
  createCategory: (data: FormData) => Promise<void>
  disableCategory: (categoryId: string, params: string) => Promise<void>
}

export const CategoriesContext = React.createContext<ContextValue | undefined>(undefined)

export const CategoriesProvider: React.FC = props => {
  const [categories, setCategories] = useState<CategoriesDTOs[]>([])
  const [page] = useState(1)
  const [loading, setLoading] = useState(false)
  const [currentCategory, setCurrentCategory] = useState<CategoryResponse>()

  const createCategory = useCallback(async (data: FormData) => {
    setLoading(true)
    try {
      await api.post("/categories", data)
      await Swal.fire("Sucesso ao criar!", "Categoria criada", "success")
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const fetchCategories = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get("/categories")
      setCategories(response.data[0].filter((item: any) => item.disabled !== "1"))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const fetchCategoryById = useCallback(async (categoryId: string) => {
    setLoading(true)
    try {
      const response = await api.get(`/categories/${categoryId}`)
      setCurrentCategory(response.data[0])
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const editCategoryById = useCallback(async (categoryId: string, name, desc, file) => {
    setLoading(true)
    const data = {
      name: name,
      desc: desc,
      disabled: 0,
      file: file,
    }
    console.log(data)
    try {
      await api.put(`/categories/${categoryId}`, data)
      await Swal.fire("Sucesso ao editar!", "Categoria editada", "success")
      window.location.reload()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      await Swal.fire("Erro ao editar!", "Categoria não editada", "error")
    }
  }, [])

  const disableCategory = useCallback(async (categoryId: string, params: string) => {
    try {
      await api.put(`/categories/${categoryId}?${params}`)
      await Swal.fire("Sucesso ao desativar!", "Categoria desativada", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao desativar!", "Categoria não desativada", "error")
    }
  }, [])

  const deleteCategoryById = useCallback(
    async (categoryId: number) => {
      setLoading(true)
      try {
        await api.delete(`/categories/${categoryId}`)
        await Swal.fire("Sucesso ao remover!", "Categoria removida", "success")
        fetchCategories()
        setLoading(false)
      } catch (error) {
        setLoading(false)
        await Swal.fire("Erro ao remover!", "Categoria não removida", "error")
      }
    },
    [fetchCategories],
  )

  const editPictureById = useCallback(async (categoryId: string, data: FormData) => {
    setLoading(true)
    try {
      await api.post(`/categories/image/${categoryId}`, data)
      await Swal.fire("Sucesso ao editar!", "Foto editada", "success")
      setLoading(false)
      window.location.reload()
    } catch (error) {
      setLoading(false)
      await Swal.fire("Erro ao editar!", "Foto não editada", "error")
    }
  }, [])

  const deletePictureById = useCallback(async (categoryId: string) => {
    setLoading(true)
    try {
      await api.delete(`/categories/image/${categoryId}`)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }, [])

  const value = useMemo(
    () => ({
      categories,
      fetchCategories,
      page,
      loading,
      deleteCategoryById,
      editCategoryById,
      editPictureById,
      fetchCategoryById,
      currentCategory,
      setCurrentCategory,
      deletePictureById,
      createCategory,
      disableCategory,
    }),
    [
      categories,
      fetchCategories,
      page,
      loading,
      deleteCategoryById,
      editCategoryById,
      editPictureById,
      fetchCategoryById,
      currentCategory,
      setCurrentCategory,
      deletePictureById,
      createCategory,
      disableCategory,
    ],
  )

  return <CategoriesContext.Provider value={value} {...props} />
}

export const useCategories = (): ContextValue => {
  const context = useContext(CategoriesContext)

  if (context === undefined) {
    throw new Error("useCategories must be used within an CategoriesProvider")
  }

  return context
}

//
// Utils
//

export default interface CategoryResponse {
  id: number
  name: string
  desc: string
  file: string | null
  created_at: string
  updated_at: string
  disabled?: number
}
