/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import * as S from "./styles"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { UserModel } from "../../../@types/api-user-model"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
import { SidebarCustomers } from "../components/SidebarCustomers"
import { ActionButtonLink } from "../../../components/ActionButtonLink"
import SchedulesTable from "../components/Schedules"
import SecurityInformations from "../components/SecurityInformations"
import { useServices } from "../../../hooks/useServices"
import useMount from "../../../utils/useMount"
import { usePartners } from "../../../hooks/usePartners"
import { useTransactions } from "../../../hooks/useTransactions"
import { UserServices } from "../../UserServices"
import { useUser } from "../../../hooks/useUser"
import { PaymentMethods } from "../../UserServices/PaymentMethods"
import { PaymentTable } from "../components/TablePayments"
import api from "../../../services/api"

const PageViewCustomers: FC = () => {
  const params = useParams()
  const [active, setActive] = useState(1)
  const { getUserServices } = useServices()
  const [toggleState] = useState(1)
  const { currentUser, fetchUser } = useUser()
  const { loading } = usePartners()
  const { fetchUserTranactions } = useTransactions()
  const [customer] = useState<UserModel[]>([])

  const [status, setStatus] = useState(0)

  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    api.get(`/appointments/provider/${params.id}`).then(res => setAppointments(res.data[0].data))
  }, [])

  useMount(() => {
    fetchUser(params.id || "")
    fetchUserTranactions(params.id || "")
  })

  // const toggleTab = (index: number) => {
  //   setToggleState(index)
  // }

  const pageTitleBreadCrumbs: Array<PageLink> = useMemo(
    () => [
      {
        title: `Parceiro #${params.id}`,
        path: "/gestao/clientes/:id/visualizar-cliente",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
      },
    ],
    [customer],
  )

  //carregar dados do cliente

  useMount(() => {
    getUserServices(`${params.id}` || "")
  })

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Parceiro</PageTitle>

      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <form
              id="kt_ecommerce_add_category_form"
              className="form d-flex flex-column flex-lg-row"
              data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html"
            >
              <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10"></div>
            </form>
          </div>
        </div>
      )}

      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div style={{ width: "100%" }} className="d-flex flex-column flex-lg-row">
          <SidebarCustomers
            codId={String(params.id)}
            name={currentUser?.name || ""}
            phone_number={currentUser?.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") || ""}
            document={currentUser?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") || ""}
            email={currentUser?.email || ""}
            last_access={currentUser?.last_access || ""}
            file={currentUser?.file || ""}
            is_admin={currentUser?.is_admin}
            is_provider={currentUser?.is_provider}
            status={status}
            value={status} //status do cliente
            rating={currentUser?.rating}
            onChange={e => setStatus(Number(e.target.value))}
          />

          <div className="flex-lg-row-fluid ms-lg-15">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="nav nav-tabs nav-line-tabs mb-5 fs-6 flex-between"
            >
              <S.LineTabs>
                <S.ButtonTab onClick={() => setActive(1)} active={active === 1}>
                  Geral
                </S.ButtonTab>
                <S.ButtonTab onClick={() => setActive(2)} active={active === 2}>
                  Segurança
                </S.ButtonTab>
                <S.ButtonTab onClick={() => setActive(3)} active={active === 3}>
                  Serviços
                </S.ButtonTab>
                <S.ButtonTab onClick={() => setActive(4)} active={active === 4}>
                  Pagamentos
                </S.ButtonTab>
                <S.ButtonTab onClick={() => setActive(5)} active={active === 5}>
                  Dados bancários
                </S.ButtonTab>
              </S.LineTabs>

              {/* <ActionButtonLink
                onClickDesactivate={() => {}}
                onClickActivate={() => {}}
                OnClickDelete={() => {}}
                isBlue
              /> */}
            </div>
            <div className="tab-content" id="myTabContent">
              {/* <div
                className={`tab-pane fade ${toggleState === 1 ? "show active" : ""}`}
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <AdminPartnersUserServices userId={String(params.id)} />
              </div> */}
              {active === 1 && (
                <div style={{ marginTop: "40px" }}>
                  <SchedulesTable />
                </div>
              )}

              {active === 2 && <SecurityInformations />}

              {active === 3 && <UserServices />}

              {active === 4 && <PaymentTable transactions={appointments} />}

              {active === 5 && <PaymentMethods />}

              <div
                className={`tab-pane fade ${toggleState === 2 ? "show active" : ""}`}
                id="kt_tab_pane_2"
                role="tabpanel"
              ></div>
            </div>

            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel"></div>

              <div className="tab-pane fade" id="kt_user_view_overview_security" role="tabpanel">
                <h1>hello</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PageViewCustomers }
