import { FC, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import * as S from "./styles"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
import { SidebarCustomers } from "../components/SidebarCustomers"
import { ActionButtonLink } from "../../../components/ActionButtonLink"
import SecurityInformations from "../components/SecurityInformations"
import { ScheduleCustomers } from "../components/ScheduleCustomers"
import DocumentsTable from "../components/Documents"
import { usePartners } from "../../../hooks/usePartners"
import useMount from "../../../utils/useMount"
import { useTransactions } from "../../../hooks/useTransactions"
import UserServices from "../UserServices"
import { useServices } from "../../../hooks/useServices"
import { useUser } from "../../../hooks/useUser"
import { PaymentCustomers } from "../components/PaymentCustomers"
import api from "../../../services/api"
import { PaymentMethods } from "../../UserServices/PaymentMethods"
import { PaymentTable } from "../../Customers/components/TablePayments"

const PageViewProviders: FC = () => {
  const params = useParams()
  const [active, setActive] = useState(0)
  const { fetchUser, currentUser } = useUser()
  const { loading } = usePartners()
  const { getUserServices } = useServices()
  const { fetchUserTranactions, userTransactions } = useTransactions()
  const [status, setStatus] = useState(0)
  const [appointments, setAppointments] = useState([])

  useEffect(() => {
    api.get(`/appointments/provider/${params.id}`).then(res => setAppointments(res.data[0].data))
  }, [])

  const pageTitleBreadCrumbs: Array<PageLink> = useMemo(
    () => [
      {
        title: `Parceiro #${currentUser?.id}`,
        path: "/gestao/clientes/:id/visualizar-cliente",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
      },
    ],
    [currentUser],
  )

  useMount(() => {
    fetchUser(params.id || "")
    fetchUserTranactions(params.id || "")
    getUserServices(`${params.id}`)
  })

  const tabList = ["Geral", "Segurança", "Agendamentos", "Documentos", "Pagamentos", "Conta bancária"]

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Parceiros</PageTitle>

      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <div className="post d-flex flex-column-fluid" id="kt_post">
          <div id="kt_content_container" className="container-xxl">
            <form
              id="kt_ecommerce_add_category_form"
              className="form d-flex flex-column flex-lg-row"
              data-kt-redirect="../../demo1/dist/apps/ecommerce/catalog/categories.html"
            >
              <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                {/* begin::Photo */}
                {/* <AsideColumnCustomers
                                        filePreview={file} //imagem do cliente
                                    /> */}

                {/* end::Photo */}

                {/* begin::Selected */}
              </div>
              {/* begin::Inputs */}
            </form>
          </div>
        </div>
      )}

      <div className="post d-flex flex-column-fluid" id="kt_post">
        <div style={{ width: "100%" }} className="d-flex flex-column flex-lg-row">
          <SidebarCustomers
            codId={String(params.id)}
            name={currentUser?.name || ""}
            phone_number={currentUser?.phone_number.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") || ""}
            document={currentUser?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") || ""}
            email={currentUser?.email || ""}
            last_access={currentUser?.last_access || ""}
            file={currentUser?.file || ""}
            is_admin={currentUser?.is_admin}
            is_provider={currentUser?.is_provider}
            status={status}
            value={status} //status do cliente
            onChange={e => setStatus(Number(e.target.value))}
            rating={currentUser?.rating}
          />

          <div className="flex-lg-row-fluid ms-lg-15">
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="nav nav-tabs nav-line-tabs mb-5 fs-6 flex-between"
            >
              <S.LineTabs>
                {tabList.map((item, index) => (
                  <S.ButtonTab onClick={() => setActive(index)} active={active === index}>
                    {item}
                  </S.ButtonTab>
                ))}
              </S.LineTabs>
            </div>
            <div className="tab-content" id="myTabContent">
              {/* <div
                className={`tab-pane fade ${toggleState === 1 ? "show active" : ""}`}
                id="kt_tab_pane_1"
                role="tabpanel"
              >
                <AdminPartnersUserServices userId={String(params.id)} />
              </div> */}
              {active === 0 && (
                <>
                  <ScheduleCustomers />
                  <UserServices />
                </>
              )}

              {active === 1 && <SecurityInformations />}

              {active === 2 && <PaymentCustomers transactions={appointments} />}

              {active === 3 && <DocumentsTable />}

              {active === 4 && <PaymentTable transactions={appointments} />}

              {active === 5 && <PaymentMethods />}
            </div>

            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade active show" id="kt_tab_pane_1" role="tabpanel"></div>

              <div className="tab-pane fade" id="kt_user_view_overview_security" role="tabpanel">
                <h1>hello</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PageViewProviders }
