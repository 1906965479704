import { InputHTMLAttributes } from "react"
// import { ActionButton } from "../../../components/ActionButton";
import { CheckBox } from "../../../components/Form/CheckBox"
import { ServicesDTOs } from "../../../dtos/ServicesDTOs"
import formatterNumber from "../../../utils/priceFormatter"
import { ButtonAppointmentsConfirm } from "./ButtonAppointmentsConfirm"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: ServicesDTOs
  onClickCheckBox?: () => void
  onClickConfirm?: () => void
}

export function TableServicesPartners({ data, onClickCheckBox, onClickConfirm, ...rest }: Props) {
  return (
    <>
      <tr className="w-100">
        {/* begin::checkbox */}
        <td>
          <div className="d-flex mt-4">
            <CheckBox
              id={String(data.id)}
              className="form-check-input"
              data-kt-check="false"
              data-kt-check-target="#kt_table_users .form-check-input"
              value={String(data.id)}
              onChange={onClickCheckBox}
              {...rest}
            />
          </div>
        </td>
        {/* end::checkbox */}

        {/* begin::image */}
        <td>
          <div className="d-flex">
            <p className="symbol symbol-50px">
              <span
                className="symbol-label"
                style={{
                  backgroundImage: data.file
                    ? `url(https://apibelleza.velty.com.br/storage/${data.file})`
                    : `url(https://iili.io/ZOF5Yu.png)`,
                }}
              />
            </p>
          </div>
        </td>
        {/* end::image */}

        {/* begin::name */}
        <td className="w-150px">
          <div className="d-flex mt-4 w-100">
            <p className="fs-6 text-gray-700">{data.name}</p>
          </div>
        </td>
        {/* end::name */}

        {/* begin::status falta na API*/}
        {/* <td className="w-150px">
                    <div className="d-flex mt-4 w-100 justify-content-start">
                        <div className='badge badge-light-success'>Ativo - "API"</div>
                    </div>
                </td> */}
        {/* end::status falta na API*/}

        {/* begin::categoria */}
        <td className="w-150px">
          <div className="d-flex mt-4 w-100">
            <p className="fs-6 text-gray-500">{data.category?.name}</p>
          </div>
        </td>
        {/* begin::description */}

        <td className="w-400px">
          <div className="d-flex mt-4">{data.desc}</div>
        </td>
        {/* end::description */}

        {/* begin::n services */}
        <td>
          <div className="d-flex mt-4">{formatterNumber(data.price)}</div>
        </td>
        {/* end::n services */}

        {/* begin::actions */}
        <td>
          <div className="d-flex justify-content-center">
            <ButtonAppointmentsConfirm onClickConfirm={onClickConfirm} />
          </div>
        </td>
        {/* end::actions */}
      </tr>
    </>
  )
}
