import { useEffect } from "react"
import { useUser } from "../../../../hooks/useUser"

import { Overlay } from "./styles"

interface Props {
  showModal: boolean
  setShowModal: (value: boolean) => void
}

const PasswordModal = ({ showModal, setShowModal }: Props) => {
  const { sendLinkRecover } = useUser()
  const token = ""

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [showModal])

  return (
    <>
      {showModal && <Overlay onClick={() => setShowModal(false)} />}

      <div
        style={{ display: showModal ? "block" : "none", zIndex: 1001 }}
        className="modal fade show"
        id="kt_modal_update_details"
        tabIndex={-1}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="fw-bolder">Atualizar Senha</h2>

              <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close">
                <span onClick={() => setShowModal(false)} className="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect
                      opacity="0.5"
                      x="6"
                      y="17.3137"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    ></rect>
                    <rect
                      x="7.41422"
                      y="6"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    ></rect>
                  </svg>
                </span>
              </div>
            </div>
            {token ? (
              <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <form id="kt_modal_update_password_form" className="form" action="#">
                  <div className="mb-10 fv-row" data-kt-password-meter="true">
                    <div className="mb-1">
                      <label className="form-label fw-bold fs-6 mb-2">Nova senha</label>

                      <div className="position-relative mb-3">
                        <input
                          className="form-control form-control-lg form-control-solid"
                          type="password"
                          placeholder=""
                          name="new_password"
                          autoComplete="off"
                        />
                        <span
                          className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                          data-kt-password-meter-control="visibility"
                        >
                          <i className="bi bi-eye-slash fs-2"></i>
                          <i className="bi bi-eye fs-2 d-none"></i>
                        </span>
                      </div>

                      <div className="d-flex align-items-center mb-3" data-kt-password-meter-control="highlight">
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                        <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                      </div>
                    </div>

                    <div className="text-muted">Utilize pelo menos 8 caracteres</div>
                  </div>

                  <div className="fv-row mb-10">
                    <label className="form-label fw-bold fs-6 mb-2">Confirme a nova senha</label>
                    <input
                      className="form-control form-control-lg form-control-solid"
                      type="password"
                      placeholder=""
                      name="confirm_password"
                      autoComplete="off"
                    />
                  </div>

                  <div className="text-center pt-15">
                    <button
                      onClick={() => setShowModal(false)}
                      type="reset"
                      className="btn btn-light me-3"
                      data-kt-users-modal-action="cancel"
                    >
                      Descartar
                    </button>
                    <button type="submit" className="btn btn-primary" data-kt-users-modal-action="submit">
                      <span className="indicator-label">Alterar</span>
                      <span className="indicator-progress">
                        Aguarde...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div style={{ paddingBottom: "70px !important" }} className="p-8 flex flex-col">
                <span>Um link será enviado para o seu email</span>
                <button
                  onClick={() => {
                    sendLinkRecover()
                  }}
                  type="button"
                  className="btn btn-primary mt-8"
                  data-kt-users-modal-action="submit"
                >
                  <span className="indicator-label">Enviar link de validação</span>
                  <span className="indicator-progress">
                    Aguarde...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordModal
