import { useEffect, useState } from "react"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Paginations } from "../../../components/Form/Pagination"
import { SpinnerLoading } from "../../../components/SpinnerLoading"
import { UserServicesDTO } from "../../../dtos/UserServicesDTOs"
import { Environment } from "../../../environment"
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"
import api from "../../../services/api"
import { TableListServicesPartners } from "./TableListServicesPartners"

export function PageServicesAssociated() {
  const { profile } = useAuthBelleza()
  // const navigate = useNavigate();

  const userId = localStorage.getItem("@App:userId")

  // const [idUser, setIdUser] = useState(0);

  const [userServicesList, setUserServicesList] = useState<UserServicesDTO[]>([])

  const [checked, setChecked] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string[]>([])

  const [page, setPage] = useState(1)

  /* Table */
  const [isLoading, setIsLoading] = useState(true)

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "/gestao/servicos-ao-usuario/lista-servicos-ao-usuario",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  async function handleApproved(id: string) {
    if (selectedId[0] !== id.toString()) return alert("Clique no serviço que deseja aprovar")

    await api
      .post(`/user_products/update/${selectedId[0]}`, {
        approved: true,
      })
      .then(response => {
        alert("Serviço aprovado com sucesso!")
      })
      .catch(error => {
        console.log(error)
        alert("Erro ao aprovar serviço!")
      })
      .finally(() => {
        setSelectedId([])
        setChecked(false)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    async function fetchListServices() {
      await api
        .get(`/user_products/user/${userId}`)
        .then(response => {
          setUserServicesList(response.data[0])
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    fetchListServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    console.log("selectedId: ", selectedId[0])
    console.log("userProfile: ", profile?.id)
    console.log("userId: ", userId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Serviços Associados</PageTitle>

      <KTCard>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div>
            <KTCardBody>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  {/* START HEADER TABLE */}
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th />
                      <th>Nome</th>
                      <th>Categoria</th>
                      <th>Preço</th>
                      <th>Status</th>
                      {/* <th >Descrição</th> */}
                      {/* <th className='text-center'>Ações</th> */}
                    </tr>
                  </thead>
                  {/* END HEADER TABLE */}

                  {/* begin::listCategories */}
                  {userServicesList.length > 0 ? (
                    <tbody className="text-gray-600 fw-bold">
                      {userServicesList
                        .slice((page - 1) * Environment.LINHA_DE_LINHAS, page * Environment.LINHA_DE_LINHAS)
                        .map(item => {
                          return (
                            <TableListServicesPartners
                              key={item.id}
                              data={item}
                              onClickConfirm={() => handleApproved(item.id.toString())}
                              onClickCheckBox={() => handleChecked(item.id.toString())}
                              checked={checked && selectedId[0] === item.id.toString() ? true : false}
                            />
                          )
                        })}
                    </tbody>
                  ) : (
                    <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                      Sem serviços
                    </div>
                  )}
                  {/* end::listCategories */}

                  {/* END BODY TABLE */}
                </table>
              </div>
            </KTCardBody>
            {/* begin::pagination */}
            <Paginations totalCountOfRegisters={userServicesList.length} currentPage={page} onPageChange={setPage} />
          </div>
        )}
      </KTCard>
    </>
  )
}
