import styled from "styled-components"

export const Container = styled.div`

svg {
  filter: opacity(0.4);
}
`

export const LineTabs = styled.div`
  display: flex;
`

export const ButtonTab: any = styled.div`
  color: ${(props: any) => (props.active ? "#009ef7" : "#a1a5b7")};
  font-size: 16px;
  font-weight: 500;
  margin-right: 30px;
  border-bottom: ${(props: any) => (props.active ? "2px solid #009ef7" : "")};
  padding-bottom: 12px;
  cursor: pointer;
`

export const ContainerBoxes = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`

export const WhiteBox = styled.div`
  width: 100%;
  background: white;
  border-radius: 14px;
  padding: 20px;
  height: 300px;
  h3 {
    font-size: 18px;
    font-weight: 600;
  }
`
export const ContentOverflow = styled.div`
  overflow-x: auto;
`

export const ContentList = styled.div`
  min-width: 220px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eff2f5;

  .side {
    display: flex;
    align-items: center;
    padding-right: 10px;
    h5 {
      color: #a1a5b7;
      font-size: 14px;
      margin-left: 10px;
      margin-bottom: 0px;
    }

    img {
      width: 25px;
      height: 25px;
      border-radius: 100%;
    }
  }
  h5 {
    color: #a1a5b7;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;

    &.data-content {
      color: #7e8299;
    }
  }
`

export const Adress = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-top: 30px;

  @media(max-width: 1023px) {
    grid-template-columns: 1fr;
  }

  .box {
    width: 100%;
    height: 200px;
    background: white;
    padding: 24px;
    border-radius: 14px;
    position: relative;

    h3 {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 16px;
    }

    h4 {
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
      max-width: 220px;
    }

    .img-fixed {
      width: 175px;
      position: absolute;
      top: 0px;
      right: 0px;

      img {
        width: 100%;
        opacity: 0.15;
      }
    }
  }
`

export const ScheduleDetails = styled.div`
  background: white;
  padding: 20px;
  margin-top: 30px;

  .price-total {
    td {
      color: black;
    }
  }

  td {
    color: #7e8299;
  }

  .product {
    display: flex;
    align-items: center;
    h4 {
      font-size: 14px;
      color: #7e8299;
    }

    h5 {
      color: #a1a5b7;
      font-size: 12px;
    }

    .product-image {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
  }
`

export const OrderHistory = styled.div`
  background: white;
  padding: 20px;
  margin-top: 30px;

  td {
    color: #7e8299;
  }


`