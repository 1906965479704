import React, { useEffect, useMemo, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { UserModel } from "../../../@types/api-user-model"
import { Paginations } from "../../../components/Form/Pagination"
import { ServicesDTOs } from "../../../dtos/ServicesDTOs"
import { Environment } from "../../../environment"
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"
import { useServices } from "../../../hooks/useServices"
import api from "../../../services/api"
import formatterNumber from "../../../utils/priceFormatter"

// import { Container } from './styles';

const UserServices: React.FC = () => {
  const { profile } = useAuthBelleza()
  const { userServices, acceptService, getUserServices, deleteUserService } = useServices()
  const [page, setPage] = useState(1)
  const [statusModalCreateService, setStatusModalCreateService] = useState(false)
  const [selectedService, setSelectedService] = useState(0)
  const [filteredService, setFilteredService] = useState<ServicesDTOs[]>([])
  const [isCreatedModalService, setIsCreatedModalService] = useState(false)
  const [isLoadingCreateService, setIsLoadingCreateService] = useState(false)
  const [selectedUser, setSelectedUser] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const [users, setUsers] = useState<UserModel[]>([])
  const [, setCustomersList] = useState<UserModel[]>([])

  const currentList = useMemo(() => {
    return userServices.slice((page - 1) * Environment.LINHA_DE_LINHAS, page * Environment.LINHA_DE_LINHAS)
  }, [page, userServices])

  function handleCloseCreateService() {
    setStatusModalCreateService(false)
  }

  async function handleCreateServiceCustomers() {
    setIsLoadingCreateService(true)
    console.log(selectedService)
    await api
      .post(`/user_products/add/${selectedUser}`, {
        product_id: selectedService,
      })
      .then(response => {
        setIsLoadingCreateService(false)
      })
      .catch(err => console.error(err))
      .finally(() => {
        setStatusModalCreateService(false)
        setIsCreatedModalService(false)
        window.location.reload()
      })
  }

  async function fetchListServices() {
    if (profile?.user_products !== undefined || null) {
      try {
        const response = await api.get("/products")
        const filter = response.data[0].filter((a: any) => !currentList.some((b: any) => a.id === b.product_id))
        setFilteredService(filter)
      } catch (error) {
        console.log("error: ", error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  async function fetchListUsers() {
    try {
      const response = await api.get(`/user/${id}`)
      setCustomersList(response.data[0])
      setUsers(response.data[0])
    } catch (error) {
      console.log("error: ", error)
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchApi() {
    await fetchListUsers()
    await fetchListServices()
  }

  useEffect(() => {
    setSelectedUser(Number(id))
    fetchApi()
  }, [profile])

  return (
    <div className="card pt-4 mb-6 mb-xl-9">
      <div className="card-header border-0">
        <div className="card-title flex justify-content-between " style={{ width: "100%" }}>
          <h2>Serviços</h2>
          <Button onClick={() => setStatusModalCreateService(true)}>Adicionar</Button>
        </div>
      </div>

      <div className="card-body pt-0 pb-5">
        <div id="kt_table_customers_payment_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="table-responsive">
            <table
              className="table align-middle table-row-dashed gy-5 dataTable no-footer"
              id="kt_table_customers_payment"
            >
              <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                <tr className="text-start text-muted text-uppercase gs-0">
                  <th
                    className="min-w-100px sorting"
                    tabIndex={1}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Invoice No.: activate to sort column ascending"
                  >
                    Nome
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Status: activate to sort column ascending"
                  >
                    Categoria
                  </th>
                  <th
                    className="sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Amount: activate to sort column ascending"
                  >
                    Valor
                  </th>
                  <th
                    className="min-w-100px sorting"
                    tabIndex={0}
                    aria-controls="kt_table_customers_payment"
                    rowSpan={1}
                    colSpan={1}
                    aria-label="Date: activate to sort column ascending"
                  >
                    Status
                  </th>
                  <th className="text-end min-w-100px pe-4 sorting_disabled" rowSpan={1} colSpan={1} aria-label="Ações">
                    Ações
                  </th>
                </tr>
              </thead>
              {currentList.length ? (
                <tbody className="fs-6 fw-semibold text-gray-600">
                  {currentList.map(item => (
                    <tr className="odd">
                      <td>
                        <Link style={{ display: "flex" }} to="#" className="text-gray-600 text-hover-primary mb-1">
                          <img
                            style={{ width: "40px", marginRight: "20px" }}
                            src={
                              item.product.file
                                ? `https://apibelleza.velty.com.br/storage/${item.product.file}`
                                : `https://iili.io/ZOF5Yu.png`
                            }
                            alt="product"
                          />
                          {item.product.name}
                        </Link>
                      </td>

                      <td>{item.product.category.name}</td>

                      <td> {formatterNumber(item.product.price)}</td>

                      <td>
                        {item.approved === 0 ? (
                          <span className="badge badge-light-warning">Pendente</span>
                        ) : (
                          <span className="badge badge-light-success">Aceito</span>
                        )}
                      </td>

                      <td className="pe-0 text-end">
                        <Link
                          to="#"
                          className="btn btn-sm btn-light btn-active-light-primary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          Ações
                          <span className="svg-icon svg-icon-5 m-0">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </Link>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3">
                            {item.approved === 0 && (
                              <Link
                                onClick={async () => {
                                  await acceptService(`${item.id}`)
                                  getUserServices(`${item.user.id}`)
                                }}
                                to="#"
                                className="menu-link px-3"
                              >
                                Aprovar
                              </Link>
                            )}
                            <Link
                              onClick={async () => {
                                await deleteUserService(`${item.id}`)
                                getUserServices(`${item.user.id}`)
                              }}
                              to="#"
                              className="menu-link px-3"
                            >
                              Deletar
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div style={{ marginTop: "20px" }}>Sem serviços</div>
              )}
            </table>
          </div>
        </div>
        <Paginations totalCountOfRegisters={userServices.length} currentPage={page} onPageChange={setPage} />
      </div>
      {/* begin::Modal Create UserServices */}
      <div>
        <Modal
          show={statusModalCreateService}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-50w"
        >
          <Modal.Header closeButton onClick={handleCloseCreateService}>
            <Modal.Title>Adicionar Serviço ao Cliente</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column mt-10 ms-10 me-10">
              <>
                {/* TENHO QUE FIXAR O ID DO USUARIO PARA SELECAO DO SERVIÇO */}

                {/* begin::cliente selecionado */}
                {/* <div className="mb-10">
                              <label className="form-label">Selecionar Cliente</label>
                              <select
                                className="form-select form-select-solid"
                                aria-label="Default select example"
                                value={selectedUser}
                                onChange={e => setSelectedUser(Number(e.target.value))}
                              >
                                <option>Selecione um Cliente</option>
                                {users.map(item => (
                                  <option key={item.id} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                              </select>
                            </div> */}
                {/* end::cliente selecionado */}

                {/* begin::servico selecionado */}
                <div className="mb-10">
                  <label className="form-label">Selecionar Serviço</label>
                  <select
                    className="form-select form-select-solid"
                    aria-label="Default select example"
                    value={selectedService}
                    onChange={e => setSelectedService(Number(e.target.value))}
                  >
                    <option>Selecione um Serviço</option>
                    {filteredService.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                {/* end::servico selecionado */}

                {/* criado com sucesso */}
                {isCreatedModalService ? (
                  <div className="d-flex justify-content-center bg-gray-200 rounded-1 mb-5">
                    <div className="d-flex align-items-center">
                      <span className="text-success fw-bold m-2">Criado com Sucesso!</span>
                    </div>
                  </div>
                ) : null}
              </>
            </div>
            {isCreatedModalService ? (
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseCreateService}>
                  Fechar
                </Button>
              </Modal.Footer>
            ) : (
              <Modal.Footer>
                {isLoadingCreateService ? (
                  <div>
                    <Button variant="secondary" onClick={handleCloseCreateService}>
                      Fechar
                    </Button>

                    <button type="button" className="btn btn-primary ms-4" data-kt-indicator="on">
                      <span className="indicator-label">Submit</span>
                      <span className="indicator-progress">
                        Salvando... <span className="spinner-border spinner-border-sm align-middle ms-3"></span>
                      </span>
                    </button>
                  </div>
                ) : (
                  <div>
                    <Button variant="secondary" onClick={handleCloseCreateService}>
                      Fechar
                    </Button>
                    <Button variant="primary text-white ms-3" type="submit" onClick={handleCreateServiceCustomers}>
                      Salvar
                    </Button>
                  </div>
                )}
              </Modal.Footer>
            )}
          </Modal.Body>
        </Modal>
      </div>
      {/* end::Modal Create UserServices */}
    </div>
  )
}

export default UserServices
