
interface Props {
    href?: string;
    onClick?: () => void;
}

export function HeaderSearchAdd({ href, onClick }: Props) {
    return (
        <div className='card-header border-0 pt-6'>
            <div className='card-title'>
                {/* <div className='d-flex align-items-center position-relative my-1'>
                    <span className='svg-icon svg-icon-1 position-absolute ms-6'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='24'
                            viewBox='0 0 24 24'
                            fill='none'
                        >
                            <rect
                                opacity='0.5'
                                x='17.0365'
                                y='15.1223'
                                width='8.15546'
                                height='2'
                                rx='1'
                                transform='rotate(45 17.0365 15.1223)'
                                fill='currentColor'
                            />
                            <path
                                d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                                fill='currentColor'
                            />
                        </svg>
                    </span>
                    <input
                        data-kt-subscription-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Pesquisar'
                    />
                </div> */}
            </div>
            <div className='card-toolbar'>
                <div
                    className='d-flex justify-content-end'
                    data-kt-subscription-table-toolbar='base'
                    onClick={onClick}
                >
                    <a href={href} className='btn btn-primary'>
                        Adicionar
                    </a>
                </div>
                {/* <div
                    className='d-flex justify-content-end align-items-center d-none'
                    data-kt-subscription-table-toolbar='selected'
                >
                    <div className='fw-bolder me-5'>
                        <span
                            className='me-2'
                            data-kt-subscription-table-select='selected_count'
                        />
                        Selected
                    </div>
                    <button
                        type='button'
                        className='btn btn-danger'
                        data-kt-subscription-table-select='delete_selected'
                    >
                        Delete Selected
                    </button>
                </div> */}
            </div>
        </div>
    );
}