import { Link, useParams } from "react-router-dom"
import { Paginations } from "../../../../components/Form/Pagination"
import { useSchedules } from "../../../../hooks/useSchedules"
import formatterNumber from "../../../../utils/priceFormatter"
import useMount from "../../../../utils/useMount"

const SchedulesTable = () => {
  const params = useParams()
  const { getPartnerSchedules, providerAppointments, totalCount, setPage, page } = useSchedules()

  useMount(() => {
    getPartnerSchedules(`${params.id}`)
  })

  return (
    <div className="card pt-4 mb-6 mb-xl-9">
      <div className="card-header border-0">
        <div className="card-title">
          <h2>Agendamentos</h2>
        </div>
      </div>

      <div className="card-body pt-0 pb-5">
        <div id="kt_table_customers_payment_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
          <div className="table-responsive">
            {providerAppointments.length > 0 ? (
              <table
                className="table align-middle table-row-dashed gy-5 dataTable no-footer"
                id="kt_table_customers_payment"
              >
                <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                  <tr className="text-start text-muted text-uppercase gs-0">
                    <th
                      className="min-w-100px sorting"
                      tabIndex={1}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Invoice No.: activate to sort column ascending"
                    >
                      Código
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Status: activate to sort column ascending"
                    >
                      Status
                    </th>
                    <th
                      className="sorting"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Amount: activate to sort column ascending"
                    >
                      Valor
                    </th>
                    <th
                      className="min-w-100px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                    >
                      Data
                    </th>
                    <th
                      className="text-end min-w-100px pe-4 sorting_disabled"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Ações"
                    >
                      Ações
                    </th>
                  </tr>
                </thead>

                <tbody className="fs-6 fw-semibold text-gray-600">
                  {providerAppointments.map(item => (
                    <tr className="odd">
                      <td>
                        <Link to="#" className="text-gray-600 text-hover-primary mb-1">
                          {item.id}
                        </Link>
                      </td>

                      <td>
                        <span className="badge badge-light-success">Sucesso</span>
                      </td>

                      <td>{<span className="fw-bolder">{formatterNumber(item.product.price)}</span>}</td>

                      <td data-order="2020-12-14T20:43:00-03:00">
                        {" "}
                        {new Date(item.appointment_date).toLocaleDateString("pt-BR", { timeZone: "UTC" })}
                      </td>

                      <td className="pe-0 text-end">
                        <Link
                          to="#"
                          className="btn btn-sm btn-light btn-active-light-primary"
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                        >
                          Ações
                          <span className="svg-icon svg-icon-5 m-0">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </Link>
                        <div
                          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                          data-kt-menu="true"
                        >
                          <div className="menu-item px-3">
                            <Link to={`/gestao/detalhes-agendamento/${item.id}`} className="menu-link px-3">
                              Visualizar
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>Sem serviços</div>
            )}
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
              <Paginations totalCountOfRegisters={totalCount} currentPage={page} onPageChange={setPage} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SchedulesTable
