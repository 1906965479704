import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import Swal from "sweetalert2"
import { useUser } from "../../../hooks/useUser"
import api from "../../../services/api"

const PaymentMethods = () => {
  const { currentUser, uploadDocument, deleteDocument } = useUser()
  const [show, setShow] = useState(false)
  const [selectDoc, setSelectDoc] = useState<any>(null)
  const [bankList, setBankList] = useState<any>(null)

  const [bankName, setBankName] = useState<string>("")

  const handleClose = () => setShow(false)
  const handleShow = (document: any) => {
    setSelectDoc(document)
    setShow(true)
  }
  const [form, setForm] = useState({
    type: "",
    file: "",
  })
  const [edit, setEdit] = useState(false)

  const [bank, setBank] = useState<string>(currentUser?.bank || "")
  const [branchNumber, setBranchNumber] = useState<number | string>(currentUser?.branch_number || 0)
  const [branchDigit, setBranchDigit] = useState<number | string>(currentUser?.branch_check_digit || 0)
  const [accountNumber, setAccountNumber] = useState<number | string>(currentUser?.account_number || 0)
  const [accountDigit, setAccountDigit] = useState<number | string>(currentUser?.account_check_digit || 0)
  const [accType, setAccType] = useState<string>(currentUser?.type || "")

  useEffect(() => {
    axios.get("https://brasilapi.com.br/api/banks/v1").then(res => {
      setBankList(res.data)
    })
  }, [])

  const handleBank = async (e: any) => {
    e.preventDefault()
    const account = {
      bank: bank,
      branch_number: Number(branchNumber),
      branch_check_digit: Number(branchDigit),
      account_number: Number(accountNumber),
      account_check_digit: Number(accountDigit),
      type: accType,
    }

    await api
      .post(`user/bankaccount/${currentUser?.id}`, account)
      .then(res => res.status === 200 && window.location.reload())
      .catch(err => {
        console.error(err.response)
        err.response.status === 412 &&
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar a conta!",
            text: "Atualize o cadastro, nome ou documento incorreto",
          })
        err.response.status === 429 &&
          Swal.fire({
            icon: "error",
            title: "Falha ao Atualizar",
            text: "Tente novamente em alguns minutos.",
          })
      })
  }
  const findBank = (id: any) => {
    if (id === "000") return "Sem Banco"
    const output = bankList.find((item: any) => item.code === Number(id))

    return output.name
  }
  console.log(bankList)
  return (
    <div className="card pt-4 mb-6 mb-xl-9">
      <div style={{ alignItems: "center" }} className="card-header border-0">
        <div className="card-title">
          <h2>Conta bancária</h2>
        </div>
        <button onClick={() => setEdit(!edit)} style={{ width: "24px", background: "none" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#009ef7"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
      </div>

      <div className="card-body pt-0 pb-5">
        {edit ? (
          <>
            <div className="fv-row mb-7">
              <label className="fs-6 fw-bold mb-2"></label>
            </div>
            <div className="flex mb-7 flex-col">
              <label className="fs-6 fw-bold mb-2">Conta bancaria:</label>
            </div>
            <form noValidate className="form">
              <div className="card-body border-top p-9">
                {/* begin::Input Banco */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Número do banco</label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-control form-control-lg form-control-solid"
                      defaultValue={bank && Number(bank)}
                      onChange={e => {
                        setBank(e.target.value)
                      }}
                    >
                      <option>Selecione o Banco</option>
                      {bankList.map((item: any) => (
                        <option value={`${item.code}`}>
                          {item.code} - {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* end::Input Banco */}

                {/* begin::Input Agencia */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Agência</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Agência"
                      name="Agência"
                      maxLength={4}
                      minLength={4}
                      defaultValue={branchNumber && branchNumber}
                      onChange={e => {
                        setBranchNumber(Number(e.target.value))
                      }}
                    />
                  </div>
                </div>
                {/* end::Input Agencia */}

                {/* begin::Input digito */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Dígito da agência</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Dígito da agência"
                      name="Dígito da agência"
                      defaultValue={branchDigit && branchDigit}
                      maxLength={1}
                      onChange={e => {
                        setBranchDigit(Number(e.target.value))
                      }}
                    />
                  </div>
                </div>
                {/* end::Input digito */}

                {/* begin::Input conta */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Conta</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Conta"
                      name="Conta"
                      minLength={4}
                      defaultValue={accountNumber && accountNumber}
                      onChange={e => {
                        setAccountNumber(Number(e.target.value))
                      }}
                    />
                  </div>
                </div>
                {/* end::Input conta */}

                {/* begin::Input digito conta */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Dígito da conta</label>
                  <div className="col-lg-8 fv-row">
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Dígito da conta"
                      name="Dígito da conta"
                      defaultValue={accountDigit && accountDigit}
                      maxLength={1}
                      onChange={e => {
                        setAccountDigit(Number(e.target.value))
                      }}
                    />
                  </div>
                </div>
                {/* end::Input digito conta */}

                {/* begin::Input tipo conta */}
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">Tipo da conta</label>
                  <div className="col-lg-8 fv-row">
                    <select
                      className="form-control form-control-lg form-control-solid"
                      name="type"
                      onChange={e => setAccType(e.target.value)}
                    >
                      <option selected value="">
                        Selecione o tipo de conta
                      </option>
                      <option value="checking">Conta Corrente</option>
                      <option value="savings">Conta Poupança</option>
                    </select>
                  </div>
                </div>
                {/* end::Input tipo conta */}

                {/* begin::Button Save */}
                <div className="card-footer d-flex justify-content-end py-6 px-9 gap-5">
                  <button className="btn btn-secundary" onClick={e => setEdit(!edit)}>
                    Cancelar
                  </button>
                  <button className="btn btn-primary" onClick={e => handleBank(e)}>
                    Salvar
                  </button>
                </div>
              </div>
            </form>
          </>
        ) : (
          <div id="kt_table_customers_payment_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed gy-5 dataTable no-footer"
                id="kt_table_customers_payment"
              >
                <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                  <tr className="text-start text-muted text-uppercase gs-0">
                    <th
                      className="min-w-100px sorting"
                      tabIndex={1}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Invoice No.: activate to sort column ascending"
                    >
                      Banco
                    </th>

                    <th
                      className="min-w-100px sorting flex justify-content-center"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                    >
                      Agência
                    </th>

                    <th
                      className="min-w-100px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                    >
                      Conta
                    </th>
                  </tr>
                </thead>

                <tbody className="fs-6 fw-semibold text-gray-600">
                  <tr className="odd">
                    {currentUser?.bank === null || undefined ? (
                      <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40, width: "100%" }}>
                        Sem conta cadastrada
                      </div>
                    ) : (
                      <>
                        {bankList && (
                          <>
                            <td style={{ textTransform: "uppercase" }}>{findBank(currentUser?.bank)}</td>
                            <td className="flex justify-content-center">{`${currentUser?.branch_number} - ${currentUser?.branch_check_digit}`}</td>
                            <td>{`${currentUser?.account_number} - ${currentUser?.account_check_digit}`}</td>
                          </>
                        )}
                      </>
                    )}
                  </tr>
                  {/* <div className="mt-5" style={{ color: "#009Ef7" }}>
                    <Link to="#" className="text-gray-600 text-hover-primary mb-1 p-0">
                      ARQUIVOS
                    </Link>
                  </div>
                  {currentUser?.documents.map(document => (
                    <div className="flex justify-between w-full">
                      <span style={{ textTransform: "uppercase" }}>{document.type}</span>
                      <img
                        style={{ width: "300px" }}
                        src={`https://apibelleza.velty.com.br/storage/${document.file}`}
                        alt="document"
                      />
                      <button
                        onClick={() => deleteDocument(`${document.id}`)}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#009Ef7"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {show && (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{selectDoc.type}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex justify-content-center">
              <img
                style={{ width: "300px" }}
                src={`https://apibelleza.velty.com.br/storage/${selectDoc.file}`}
                alt="document"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export { PaymentMethods }
