import { InputHTMLAttributes } from "react";

interface Props extends InputHTMLAttributes<HTMLDivElement> { }

export function CheckBox({ ...rest }: Props) {
    return (
        <div className='form-check form-check-sm form-check-custom form-check-solid'>
            <input type='checkbox' {...rest} />
        </div>
    );
}