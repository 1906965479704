import { useEffect, useState } from "react"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { useAuthBelleza } from "../../hooks/useAuthBelleza"
import api from "../../services/api"
import { Environment } from "../../environment"
import { CategoriesDTOs } from "../../dtos/CategoriesDTOs"

export function Transactions() {
  const { profile } = useAuthBelleza()
  const [, setPaginationCount] = useState(0) //total de paginas
  // const [currentPage, setCurrentPage] = useState(1); // Página atual
  const [, setTotalCount] = useState(0) //total de itens
  const [, setCategory] = useState<CategoriesDTOs[]>([])

  /* Table */
  const [, setIsLoading] = useState(false)

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "financeiro/transacoes/",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  //index da pagination atual
  // function currentPagination(index: number) {
  //     setCurrentPage(index);
  // }

  //carregar lista de categorias
  useEffect(() => {
    async function fetchCategory() {
      setIsLoading(true)
      await api
        .get("/categories")
        .then(response => {
          //lista da categoria
          setCategory(response.data[0])
          //total de itens
          setTotalCount(response.data[0].length)
          //total de páginas Math.ceil arredonda para cima
          setPaginationCount(Math.ceil(response.data[0].length / Environment.LINHA_DE_LINHAS))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    fetchCategory()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Transacoes</PageTitle>
      {/*<KTCard>
                 {
                    isLoading ? <SpinnerLoading /> :
                        <div>
                            <HeaderSearchAdd href='adicionar-servico' />
                            <KTCardBody >

                                <div className='table-responsive'>
                                    <table className="table table-row-dashed table-row-gray-300 gy-7">

                                        <thead>
                                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                                <th >Nome</th>
                                                <th >Desc</th>
                                            </tr>
                                        </thead>

                                        <tbody className='text-gray-600 fw-bold'>
                                            {
                                                
                                                category.slice((currentPage - 1) * Environment.LINHA_DE_LINHAS, currentPage * Environment.LINHA_DE_LINHAS)
                                                    .map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>{item.name}</td>
                                                                <td>{item.desc}</td>
                                                            </tr>
                                                        )
                                                    })
                                            }
                                        </tbody>
                                    </table>
                                </div>


                                <div>
                                    {
                                        (totalCount > 0 && totalCount > Environment.LINHA_DE_LINHAS) && (
                                            <ul className="pagination bg-gray-100">
                                                {
                                                    Array.from({ length: paginationCount }, (_, index) => {
                                                        return (
                                                            <li key={index} className={
                                                                `page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                                            >
                                                                <a className={
                                                                    `page-link ${currentPage === index + 1 ? 'active text-white' : ''}`}
                                                                    onClick={
                                                                        () => currentPagination(index + 1)
                                                                    }
                                                                >
                                                                    {index + 1}
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        )
                                    }
                                </div>
                            </KTCardBody>
                        </div>
                }
            </KTCard> */}
    </>
  )
}
