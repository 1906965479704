import { useEffect, useState } from "react"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { TableCustomers } from "./components/TableCustomers"
import { HeaderSearch } from "../../components/HeaderSearch"
import { useNavigate } from "react-router-dom"
import { SpinnerLoadingMiddle } from "../../components/SpinnerLoadingMiddle"
import { PaginationsAPI } from "../../components/Form/PaginationAPI"
import { usePartners } from "../../hooks/usePartners"
import useMount from "../../utils/useMount"

export function Providers() {
  const {
    DesactivatePartner,
    ActivatePartner,
    fetchProviders,
    providers,
    loading,
    total,
    aproveProvider,
    filterPartners,
  } = usePartners()
  let navigate = useNavigate()
  const [checked, setChecked] = useState(false)
  const [selectedId, setSelectedId] = useState<string[]>([])

  const [page, setPage] = useState(1)

  const day = new Date().getDate()
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: ``,
      path: "/gestao/parceiros/:id",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  async function handleApproved(id: string) {
    aproveProvider(id, `${year}-${month}-${day}`, `${year}-${month}-${day}`)
  }

  //function page go to view
  function handleViewCustomer(id: string) {
    navigate(`/gestao/usuarios/${id}/visualizar-usuario`)
  }

  //consumir API
  useMount(() => {
    fetchProviders(page)
  })

  useEffect(() => {
    fetchProviders(page)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  useEffect(() => {
    function nextPage() {
      navigate(`/gestao/parceiros/${page}`)
    }
    nextPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Parceiros</PageTitle>
      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <KTCard>
          <div>
            <HeaderSearch />
            <KTCardBody>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th>Parceiro</th>
                      <th></th>
                      <th>ÚLTIMO ACESSO</th>
                      <th>Aprovado</th>
                      <th>Data de Cadastro</th>
                      <th className="text-end pe-6">Ações</th>
                    </tr>
                  </thead>

                  <tbody className="text-gray-600 fw-bold">
                    {providers.length > 0 ? (
                      providers.map(item => {
                        return (
                          <TableCustomers
                            key={item.id}
                            data={item}
                            onClickActionDisable={() => DesactivatePartner(item.id.toString())}
                            onClickActionActive={() => ActivatePartner(item.id.toString())}
                            onClickActionView={() => handleViewCustomer(item.id.toString())}
                            onClickCheckBox={() => handleChecked(item.id.toString())}
                            checked={checked && selectedId[0] === item.id.toString() ? true : false}
                            onClickApproved={() => handleApproved(item.id.toString())}
                          />
                        )
                      })
                    ) : (
                      <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                        Sem parceiros
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </KTCardBody>
            <PaginationsAPI totalCountOfRegisters={total} currentPage={page} onPageChange={setPage} />
          </div>
        </KTCard>
      )}
    </>
  )
}
