import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import Swal from "sweetalert2"
import { ProfileDTO } from "../dtos/profileDTO"
import api from "../services/api"

type SignInCredentials = {
  email: string
  password: string
}

type AuthContextData = {
  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  isAuthenticated: boolean
  user: { user: ProfileDTO } | null
  profile: ProfileDTO | null
  loading: boolean
}

type AuthProviderProps = {
  children: ReactNode
}

const AuthContext = createContext({} as AuthContextData)

function AuthProviderBelleza({ children }: AuthProviderProps) {
  const [user, setUser] = useState<{ user: ProfileDTO } | null>(null)
  const [profile, setProfile] = useState<ProfileDTO | null>({} as ProfileDTO)
  const isAuthenticated = !!user
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function loadStorageData() {
      const storagedUser = localStorage.getItem("@App:user")
      const storagedToken = localStorage.getItem("@App:token")

      if (storagedToken && storagedUser) {
        setUser(JSON.parse(storagedUser))
        api.defaults.headers.Authorization = `Bearer ${storagedToken}`

        const loadProfile = async () => {
          const response = await api.get("/profile")
          setProfile(response.data)
        }
        loadProfile()
      }
    }
    loadStorageData()
  }, [])

  async function signIn({ email, password }: SignInCredentials) {
    setLoading(true)
    try {
      const response = await api.post("/login", {
        email,
        password,
      })
      
      const { user } = response.data
      setUser(user)
      setProfile(user)

      localStorage.setItem("@App:user", JSON.stringify(response.data))
      localStorage.setItem("@App:token", response.data.access_token)
      localStorage.setItem("@App:userId", response.data.user.id)

      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao fazer login!", "Verifique os dados e tente novamente", "error")
    } finally {
      setLoading(false)
    }
  }

  async function signOut() {
    setLoading(true)
    try {
      localStorage.removeItem("@App:user")
      localStorage.removeItem("@App:userId")
      localStorage.removeItem("@App:token")
      setUser(null)
      setProfile(null)
      setLoading(false)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  /* useEffect(() => {
        async function handleClearLocalStorage() {
            localStorage.clear();
            console.log('limpou');
        }
        handleClearLocalStorage();
    }, []); */

  return (
    <AuthContext.Provider
      value={{
        signIn,
        isAuthenticated,
        user,
        signOut,
        profile,
        loading,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProviderBelleza }

export function useAuthBelleza() {
  const context = useContext(AuthContext)
  return context
}
