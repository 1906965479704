import { InputHTMLAttributes, useState } from "react"
import { Link } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"
import { AppointmentsPartnersDTOS } from "../../../dtos/AppointmentsPartnersDTOs"
// import { ButtonAppointmentsConfirm } from "./ButtonAppointmentsConfirm"
import { ActionButtonLink } from "../../../components/ActionButtonLink"
import { CheckBox } from "../../../components/Form/CheckBox"
import formatterNumber from "../../../utils/priceFormatter"
import api from "../../../services/api"
import { Button, Modal } from "react-bootstrap"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: AppointmentsPartnersDTOS
  onClickCheckBox?: () => void
  onClickConfirm?: () => void
}

export function TableAppointment({ data, onClickCheckBox, onClickConfirm, ...rest }: Props) {
  const [show, setShow] = useState(false)
  const [appointment, setAppointment] = useState<AppointmentsPartnersDTOS | null>(null)

  const deleteAppointment = () => {
    api
      .delete(`appointments/delete/${appointment?.id}`)
      .then(res => res.status === 200 && window.location.reload())
      .catch(err => console.error(err))
  }
  return (
    <>
      <tr>
        <td className="w-30px">
          <Link to="#" className="text-gray-800 text-hover-primary fw-bolder">
            {data.id}
          </Link>
        </td>

        <td className="w-250px">
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <Link to="#">
                <div className="symbol-label fs-3 bg-light-danger text-danger">
                  <span className="svg-icon svg-icon-2x svg-icon-danger">
                    <img src={toAbsoluteUrl("/media/avatars/blank.png")} className={"w-100 rounded"} alt="Pic" />
                  </span>
                </div>
              </Link>
            </div>

            <div className="ms-5">
              <Link
                to={`/gestao/detalhes-agendamento/${data.id}`}
                className="text-gray-800 text-hover-primary fs-5 fw-bolder"
              >
                {data.client_user.name}
              </Link>
            </div>
          </div>
        </td>

        <td className="w-250px">
          {data.provider_user?.name ? (
            <div className="d-flex align-items-center">
              <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                <Link to={`/gestao/detalhes-agendamento/${data.id}`}>
                  <div className="symbol-label fs-3 bg-light-danger text-danger">
                    <span className="svg-icon svg-icon-2x svg-icon-danger">
                      <img src={toAbsoluteUrl("/media/avatars/blank.png")} className={"w-100 rounded"} alt="Pic" />
                    </span>
                  </div>
                </Link>
              </div>

              <div className="ms-5">
                <Link to="#" className="text-gray-800 text-hover-primary fs-5 fw-bolder">
                  {data.provider_user?.name}
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <span className="badge badge-light-danger">Sem prestador</span>
            </div>
          )}
        </td>

        <td className="text-start w-200px">
          {data.confirmed === 0 ? (
            <span className="badge badge-light-warning">Pendente</span>
          ) : (
            <span className="badge badge-light-success">Confirmado</span>
          )}
        </td>

        <td className="text-start ps-5 w-200px">
          <span className="fw-bolder">{formatterNumber(data.price)}</span>
        </td>

        <td className="text-start w-200px">
          <span className="fw-bolder">
            {new Date(data.appointment_date).toLocaleDateString("pt-BR", { timeZone: "UTC" })}
          </span>
        </td>

        {/* <td className="text-end w-100px">
        <ButtonAppointmentsConfirm onClickConfirm={onClickConfirm} />
      </td> */}
        <td className="w-150px ">
          <ActionButtonLink
            toView={`/gestao/detalhes-agendamento/${data.id}`}
            OnClickDelete={() => {
              setAppointment(data)
              setShow(true)
            }}
          />
        </td>
      </tr>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Deletar agendamento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-content-center">
            <span>Tem certeza que deseja apagar permanentemente o agendamento {appointment?.id}?</span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setShow(false)
              setAppointment(null)
            }}
          >
            Não
          </Button>
          <Button variant="danger" onClick={() => deleteAppointment()}>
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
