import React, { useCallback, useContext, useMemo, useState } from "react"
import { ServicesDTOs } from "../dtos/ServicesDTOs"
import api from "../services/api"
import Swal from "sweetalert2"

export type ContextValue = {
  servicesList: ServicesDTOs[]
  getServices: () => Promise<void>
  isLoading: boolean
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  deleteServiceById: (id: number) => Promise<void>
  editServiceById: (id: string, params: string) => Promise<void>
  getServiceById: (id: string) => Promise<void>
  dataService: ServiceBody
  setDataService: React.Dispatch<React.SetStateAction<ServiceBody>>
  updatePhotoById: (id: string, data: FormData) => Promise<void>
  imageLoad: boolean
  removePhotoById: (id: string) => Promise<void>
  createService: (data: FormData) => Promise<void>
  userServices: UserServices[]
  getUserServices: (userId: string) => Promise<void>
  acceptService: (serviceId: string) => Promise<void>
  deleteUserService: (serviceId: string) => Promise<void>
}

export const ServicesContext = React.createContext<ContextValue | undefined>(undefined)

export const ServicesProvider: React.FC = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [imageLoad, setImageLoad] = useState(false)
  const [page, setPage] = useState(1)
  const [servicesList, setServicesList] = useState<ServicesDTOs[]>([])
  const [userServices, setUserServices] = useState<UserServices[]>([])
  const [dataService, setDataService] = useState<ServiceBody>({
    name: "",
    description: "",
    price: "",
    file: "",
    category_id: "",
  })

  const createService = useCallback(async (data: FormData) => {
    setIsLoading(true)
    try {
      await api.post(`/products`, data)
      setIsLoading(false)
      await Swal.fire("Sucesso ao criar!", "Serviço criado", "success")
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  const getServices = useCallback(async () => {
    try {
      setIsLoading(true)
      const response = await api.get("/products")
      setServicesList(response.data[0]?.filter((item: ServicesDTOs) => item.disabled !== 1))
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
      console.log("error: ", error)
    }
  }, [])

  const getUserServices = useCallback(async (userId: string) => {
    try {
      setIsLoading(true)
      const response = await api.get(`/user_products/user/${userId}`)
      setUserServices(response.data[0])
      setIsLoading(false)
    } catch (error) {
      setIsLoading(true)
      console.log("error: ", error)
    }
  }, [])

  const getServiceById = useCallback(async (id: string) => {
    setIsLoading(true)
    try {
      const response = await api.get(`/products/${id}`)
      setDataService(prev => {
        return {
          ...prev,
          category_id: response.data[0].category.id,
          name: response.data[0].name,
          description: response.data[0].desc,
          file: response.data[0].file,
          price: response.data[0].price,
        }
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [])

  const acceptService = useCallback(async (serviceId: string) => {
    try {
      await api.post(`/user_products/update/${serviceId}`, { approved: true })
      await Swal.fire("Sucesso ao aceitar!", "Serviço aceito", "success")
    } catch (error) {
      await Swal.fire("Erro ao aceitar!", "Serviço não foi aceito", "error")
    }
  }, [])

  const deleteUserService = useCallback(async (serviceId: string) => {
    try {
      await api.delete(`/user_products/delete/${serviceId}`)
      await Swal.fire("Sucesso ao remover!", "Serviço removido", "success")
    } catch (error) {
      await Swal.fire("Erro ao remover!", "Serviço não removido", "error")
    }
  }, [])

  const editServiceById = useCallback(async (id: string, params: string) => {
    setIsLoading(true)
    console.log()
    try {
      await api.put(`/products/${id}?${params}`)
      setIsLoading(false)
      await Swal.fire("Sucesso ao editar!", "Serviço editado", "success")
      window.location.reload()
    } catch (error) {
      setIsLoading(false)
      await Swal.fire("Erro ao editar!", "Serviço não editado", "error")
    }
  }, [])

  const updatePhotoById = useCallback(async (id: string, data: FormData) => {
    setImageLoad(true)
    try {
      await api.post(`/products/image/${id}`, data)
      setImageLoad(false)
      await Swal.fire("Sucesso ao editar!", "Foto editada", "success")
      window.location.reload()
    } catch (error) {
      console.log(error)
      await Swal.fire("Erro ao editar!", "Foto não editada", "error")
      setImageLoad(false)
    }
  }, [])

  const removePhotoById = useCallback(async (id: string) => {
    setImageLoad(true)
    try {
      await api.delete(`/products/image/${id}`)
      setImageLoad(false)
    } catch (error) {
      setImageLoad(false)
    }
  }, [])

  const deleteServiceById = useCallback(async (id: number) => {
    setIsLoading(true)
    try {
      await api.delete(`/products/${id}`)
      setIsLoading(false)
      await Swal.fire("Sucesso ao remover!", "Serviço removido", "success")
      window.location.reload()
    } catch (error) {
      setIsLoading(false)
      await Swal.fire("Erro ao remover!", "Serviço não removido", "error")
    }
  }, [])

  const value = useMemo(
    () => ({
      servicesList,
      getServices,
      isLoading,
      page,
      setPage,
      deleteServiceById,
      editServiceById,
      getServiceById,
      dataService,
      setDataService,
      updatePhotoById,
      imageLoad,
      removePhotoById,
      createService,
      userServices,
      getUserServices,
      acceptService,
      deleteUserService,
    }),
    [
      getServices,
      isLoading,
      page,
      servicesList,
      deleteServiceById,
      editServiceById,
      getServiceById,
      dataService,
      setDataService,
      updatePhotoById,
      imageLoad,
      removePhotoById,
      createService,
      userServices,
      getUserServices,
      acceptService,
      deleteUserService,
    ],
  )

  return <ServicesContext.Provider value={value} {...props} />
}

export const useServices = (): ContextValue => {
  const context = useContext(ServicesContext)

  if (context === undefined) {
    throw new Error("useServices must be used within an ServicesProvider")
  }

  return context
}

//
// Utils
//

export interface ServiceBody {
  name: string
  description: string
  price: string
  file: string
  category_id: string
}

export interface UserServices {
  id: number
  user_id: number
  product_id: number
  approved: number
  product: {
    id: number
    category_id: number
    name: string
    file: string
    price: number
    desc: string
    created_at: string
    updated_at: string
    disabled: number
    category: {
      id: number
      name: string
      file: string
      desc: string
      created_at: string
      updated_at: string
      disabled: number
    }
  }
  user: {
    id: number
    name: string
    email: string
    is_admin: number
    is_provider: number
    is_company: number
    document: string
    phone_number: string
    nickname: string
    birthdate: string
    bio: string
    file: string
    address_line1: string
    address_line2: string
    zipcode: string
    city: string
    state: string
    country: string
    pagarme_customer_id: string
    email_verified_at: string
    user_approved_at: string
    active: number
    last_access: string
    last_access_ip: string
    created_at: string
    updated_at: string
    documents: never[]
  }
}
