import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import { AsideDefault } from "./core/components/aside/AsideDefault"
import { Footer } from "./core/components/Footer"
import { HeaderWrapper } from "./core/components/header/HeaderWrapper"
import { Toolbar } from "./core/components/toolbar/Toolbar"
import { RightToolbar } from "../partials/layout/RightToolbar"
import { ScrollTop } from "./core/components/ScrollTop"
import { Content } from "./core/components/Content"
import { PageDataProvider } from "./core"
import { useLocation } from "react-router-dom"
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from "../partials"
import { MenuComponent } from "../assets/ts/components"

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <>
        <div className="page d-flex flex-row flex-column-fluid">
          <AsideDefault />
          <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
            <HeaderWrapper />

            <div id="kt_content" className="content d-flex flex-column flex-column-fluid">
              <Toolbar />
              <div className="post d-flex flex-column-fluid" id="kt_post">
                <Content>
                  <Outlet />
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger />
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <Main />
        <InviteUsers />
        <UpgradePlan />
        {/* end:: Modals */}
        <ScrollTop />
      </>
    </PageDataProvider>
  )
}

export { MasterLayout }
