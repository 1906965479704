import moment from "moment"
import { useCallback, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useSchedules } from "../../../../hooks/useSchedules"
import useMount from "../../../../utils/useMount"
import { useUser } from "../../../../hooks/useUser"

export function ScheduleCustomers() {
  const {currentUser} = useUser()
  const { getSchedulesByDate, currentAppointments } = useSchedules()
  const [currentDay, setCurrentDay] = useState("01")
  const [filterMonth, setFilterMonth] = useState<Number>()

  const getAllDaysInMonth = (month: number, year: number) =>
    Array.from(
      { length: new Date(year, month, 0).getDate() }, // get next month, zeroth's (previous) day
      (_, i) => new Date(year, month - 1, i + 1), // get current month (0 based index)
    )

  const allDatesInOctober = useMemo(
    () =>
      getAllDaysInMonth(
        parseInt(filterMonth ? moment(`${filterMonth}`).format("M") : moment().format("M")),
        parseInt(moment().format("YYYY")),
      ),
    [filterMonth],
  )



  const switchMonth = useCallback((month: string) => {
    switch (month) {
      case "Mon":
        return "Seg"
      case "Tue":
        return "Ter"
      case "Wed":
        return "Qua"
      case "Thu":
        return "Qui"
      case "Fri":
        return "Sex"
      case "Sat":
        return "Sab"
      case "Sun":
        return "Dom"
    }
  }, [])

  const currentYear = moment().format("YYYY")

  const currentMonth = moment().format("MM")

  const weekDays = useMemo(
    () => allDatesInOctober.map(x => x.toLocaleDateString([], { weekday: "short" })),
    [allDatesInOctober],
  )

  const daysMonth = useMemo(
    () => allDatesInOctober.map(x => x.toLocaleDateString([], { day: "2-digit" })),
    [allDatesInOctober],
  )

  useMount(() => {
    setCurrentDay(moment().format("DD"))
    getSchedulesByDate(`${currentUser?.id}`, moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD"))
  })

  const months = useMemo(
    () => [
      {
        name: "Mês atual",
      },
      {
        name: "Janeiro",
        value: "1",
      },
      {
        name: "Fevereiro",
        value: "2",
      },
      {
        name: "Março",
        value: "3",
      },
      {
        name: "Abril",
        value: "4",
      },
      {
        name: "Maio",
        value: "5",
      },
      {
        name: "Junho",
        value: "6",
      },
      {
        name: "Julho",
        value: "7",
      },
      {
        name: "Agosto",
        value: "8",
      },
      {
        name: "Setembro",
        value: "9",
      },
      {
        name: "Outubro",
        value: "10",
      },
      {
        name: "Novembro",
        value: "11",
      },
      {
        name: "Dezembro",
        value: "12",
      },
    ],
    [],
  )

  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header mt-6 flex justify-between">
        <div className="card-title flex-column">
          <h2 className="mb-1">Agenda da Parceira</h2>
          <div className="fs-6 fw-bold text-muted">{currentAppointments.length} agendamentos pendentes</div>
        </div>
        <div>
          <select
            onChange={e => {
              if (e.target.value === "Mês atual") {
                setFilterMonth(parseInt(moment().format("M")))
              } else {
                setFilterMonth(parseInt(e.target.value))
              }
            }}
            style={{ fontSize: "16px" }}
          >
            {months.map(item => (
              <option value={item.value} style={{ fontSize: "16px" }}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card-body p-9 pt-4">
        <ul className="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2">
          {daysMonth.map((item, index) => (
            <li style={{ minWidth: "65px" }} className="nav-item me-1">
              <button
                onClick={() => {
                  setCurrentDay(item)
                  getSchedulesByDate(
                    `${currentUser?.id}`,
                    moment(
                      `${currentYear}-${filterMonth && filterMonth < 10 ? `0${filterMonth}` : currentMonth}-${item}`,
                    ).format("YYYY-MM-DD"),
                    moment(
                      `${currentYear}-${filterMonth && filterMonth < 10 ? `0${filterMonth}` : currentMonth}-${item}`,
                    ).format("YYYY-MM-DD"),
                  )
                }}
                type="button"
                className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-40px me-2 py-4 btn-active-primary ${
                  `${currentDay}` === item ? "active" : ""
                }`}
                data-bs-toggle="tab"
              >
                <span className="opacity-50 fs-7 fw-bold">{switchMonth(weekDays[index])}</span>
                <span className="fs-6 fw-boldest">{item}</span>
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          {currentAppointments.length ? (
            currentAppointments?.map((item, index) => (
              <div key={index} id="kt_schedule_day_0" className="tab-pane fade show active">
                <div className="d-flex flex-stack position-relative mt-6">
                  <div className="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0" />
                  <div className="fw-bold ms-5">
                    <div className="fs-7 mb-1">
                      {moment(item.appointment_date).format("HH:mm")}
                      {/* {item.start_at} - {item.end_at} */}
                      <span className="fs-7 text-muted text-uppercase">pm</span>
                    </div>
                    <Link to="#" className="fs-5 fw-bolder text-dark text-hover-primary mb-2">
                      {item?.product?.name}
                    </Link>
                    <div className="fs-7 text-muted">
                      Cliente
                      <Link to="/gestao/clientes/38/visualizar-cliente"> {item.provider_user.name}</Link>
                    </div>
                  </div>
                  <Link
                    to={`/gestao/detalhes-agendamento/${item.id}`}
                    className="btn btn-light bnt-active-light-primary btn-sm"
                  >
                    Visualizar
                  </Link>
                </div>
              </div>
            ))
          ) : (
            <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>Sem agendamentos</div>
          )}
        </div>
      </div>
    </div>
  )
}
