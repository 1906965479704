import React, { useEffect, useState } from "react"
import moment from "moment"
import EmailIcon from "../../../../assets/icons/email"
import PhoneIcon from "../../../../assets/icons/phone"
import ScheduleIcon from "../../../../assets/icons/schedule"
import UserIcon from "../../../../assets/icons/user"
import WalletIcon from "../../../../assets/icons/wallet"
import Bus from "../../../../assets/icons/bus.svg"
import { AppointmentsPartnersDTOS } from "../../../dtos/AppointmentsPartnersDTOs"
import { useSchedules } from "../../../hooks/useSchedules"
import useMount from "../../../utils/useMount"
import * as S from "./styles"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Link } from "react-router-dom"

// import { Container } from './styles';
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"

const DetailsAppointment: React.FC = () => {
  const { user } = useAuthBelleza()
  const { appointments, getSchedules, getPartnerSchedules, providerAppointments } = useSchedules()
  const query = window.location.pathname.split("/")
  const currentId = query[query.length - 1]
  const [currentClient, setCurrentClient] = useState<AppointmentsPartnersDTOS>()
  const [active, setActive] = useState("schedule")

  useMount(() => {
    getSchedules()
    getPartnerSchedules(`${user?.user.id}`)
  })

  useEffect(() => {
    if (user?.user.is_admin === 0 && user.user.is_provider === 1) {
      setCurrentClient(providerAppointments.find(item => item.id === parseInt(currentId)))
    } else {
      setCurrentClient(appointments.find(item => item.id === parseInt(currentId)))
    }
  }, [appointments, currentClient, currentId, getPartnerSchedules, getSchedules, providerAppointments, user])

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `Detalhes do agendamento #${currentClient?.id}`,
      path: "/gestao/agendamentos/:id",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function phone(v: any) {
    var r = v.replace(/\D/g, "")
    r = r.replace(/^0/, "")
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3")
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3")
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2")
    } else {
      r = r.replace(/^(\d*)/, "($1")
    }
    return r
  }

  return (
    <S.Container>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Agendamentos</PageTitle>
      <S.LineTabs>
        <S.ButtonTab onClick={() => setActive("schedule")} active={active === "schedule"}>
          Resumo do agendamento
        </S.ButtonTab>
      </S.LineTabs>
      <S.ContainerBoxes>
        <S.WhiteBox>
          <S.ContentOverflow>
            <h3>Detalhes do agendamento</h3>
            <S.ContentList>
              <div className="side">
                <ScheduleIcon />
                <h5>Data da compra</h5>
              </div>
              <h5 className="data-content">
                {currentClient?.checkin ? moment(currentClient?.checkin).format("DD/MM/YYYY") : <span>Sem data</span>}
              </h5>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <ScheduleIcon />
                <h5>Data de prestação</h5>
              </div>
              <h5 className="data-content">{moment(currentClient?.appointment_date).format("DD/MM/YYYY")}</h5>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <WalletIcon />
                <h5>Método de pagamento</h5>
              </div>
              <div className="side">
                <h5 className="data-content">Online</h5>
              </div>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <WalletIcon />
                <h5>Status</h5>
              </div>
              <div className="side">
                <h5 className="data-content">
                  {currentClient?.checkout ? (
                    <div className="badge badge-light-success">Concluído</div>
                  ) : (
                    <div className="badge badge-light-warning">Pendente</div>
                  )}
                </h5>
              </div>
            </S.ContentList>
          </S.ContentOverflow>
        </S.WhiteBox>
        <S.WhiteBox>
          <S.ContentOverflow>
            <h3>Detalhes do cliente</h3>
            <S.ContentList>
              <div className="side">
                <UserIcon />
                <h5>Cliente</h5>
              </div>
              <div className="side">
                <img src="https://picsum.photos/200/300" alt="client" />
                <Link
                  to={`/gestao/usuarios/${currentClient?.client_user.id}/visualizar-usuario`}
                  className={`data-content ${!currentClient?.client_user.name && "badge badge-light-danger"}`}
                >
                  <div style={{ marginLeft: "10px" }}> {currentClient?.client_user.name || "Não informado"}</div>
                </Link>
              </div>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <EmailIcon />
                <h5>Email</h5>
              </div>
              <h5 className="data-content">{currentClient?.client_user.email || "Não informado"}</h5>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <PhoneIcon />
                <h5>Telefone</h5>
              </div>
              <div className="side">
                <h5 className="data-content">
                  {phone(currentClient?.client_user?.phone_number || "") || "Não informado"}
                </h5>
              </div>
            </S.ContentList>
          </S.ContentOverflow>
        </S.WhiteBox>
        <S.WhiteBox>
          <S.ContentOverflow>
            <h3>Detalhes do Parceiro</h3>
            <S.ContentList>
              <div className="side">
                <UserIcon />
                <h5>Parceiro</h5>
              </div>
              <div className="side">
                {currentClient?.provider_user ? (
                  <>
                    <img src="https://picsum.photos/200/300" alt="client" />
                    <Link
                      to={`/gestao/usuarios/${currentClient?.provider_user?.id}/visualizar-usuario`}
                      className={`data-content`}
                    >
                      <div style={{ marginLeft: "10px" }}> {currentClient?.provider_user?.name || "Não informado"}</div>
                    </Link>
                  </>
                ) : (
                  <h5 className="data-content">Não informado</h5>
                )}
              </div>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <EmailIcon />
                <h5>Email</h5>
              </div>
              <h5 className="data-content">{currentClient?.provider_user?.email || "Não informado"}</h5>
            </S.ContentList>
            <S.ContentList>
              <div className="side">
                <PhoneIcon />
                <h5>Telefone</h5>
              </div>
              <div className="side">
                <h5 className="data-content">
                  {currentClient?.provider_user?.phone_number
                    ? phone(currentClient?.provider_user?.phone_number || "")
                    : "Não informado"}
                </h5>
              </div>
            </S.ContentList>
          </S.ContentOverflow>
        </S.WhiteBox>
      </S.ContainerBoxes>
      <S.Adress>
        <div className="box">
          <h3>Endereço de serviço</h3>
          <h4>{currentClient?.appointment_address}</h4>
          {/* <h4>Avenida Miguel Castro, 600</h4>
          <h4>Edifício Fulano de Tal, Apt 321,</h4>
          <h4>Lagoa Nova,</h4>
          <h4>Natal/RN</h4> */}
          <span className="img-fixed">
            <img src={Bus} alt="cart" />
          </span>
        </div>
      </S.Adress>
      {active === "schedule" ? (
        <S.ScheduleDetails>
          <h3>Agendamento #{currentClient?.id}</h3>
          <div className="table-responsive">
            <table className="table table-rounded table-flush">
              <thead>
                <tr className="fw-semibold fs-6 text-danger text-gray-400 py-4">
                  <th>Serviço</th>
                  <th></th>
                  <th className="text-end "></th>
                  <th className="text-end "></th>
                  <th className="text-end ">SKU</th>
                  <th className="text-end ">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr className="py-5 fw-semibold   fs-6">
                  <td>
                    <div className="product">
                      <img className="product-image" src="https://picsum.photos/200/300" alt="product" />
                      <div>
                        <h4>{currentClient?.product.name}</h4>
                        <h5>Data do serviço: {moment(currentClient?.appointment_date).format("DD/MM/YYYY")}</h5>
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td className="text-end "></td>
                  <td className="text-end "></td>
                  <td className="text-end ">{currentClient?.product.id}</td>
                  <td className="text-end ">R${currentClient?.product.price.toLocaleString()}</td>
                </tr>
                <tr className="py-5 fw-bold text-end  fs-6">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-end ">Subtotal</td>
                  <td className="text-end ">R${currentClient?.product.price.toLocaleString()}</td>
                </tr>
                <tr className="py-5 fw-bold text-end  fs-6">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Impostos (0%)</td>
                  <td>R$0.00</td>
                </tr>

                <tr className="py-5 fw-bold text-black  text-end fs-4 price-total">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Valor Total</td>
                  <td className="">
                    <strong>R${currentClient?.product.price.toLocaleString()}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </S.ScheduleDetails>
      ) : (
        <S.OrderHistory>
          <div className="table-responsive">
            <h3>Order History</h3>
            <table className="table table-rounded gy-7 gs-7">
              <thead>
                <tr className="fw-semibold fs-7 text-gray-400">
                  <th>DATA</th>
                  <th>DESCRIÇÃO</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {currentClient?.checkout && (
                  <tr>
                    <td>{moment(currentClient?.checkout).format("DD/MM/YYYY")}</td>
                    <td className="fw-bold text-gray-600">O serviço foi finalizado com sucesso</td>
                    <td>
                      <span className="badge badge-light-success">Finalizado</span>
                    </td>
                  </tr>
                )}
                {currentClient?.checkin && (
                  <tr>
                    <td>{moment(currentClient?.checkin).format("DD/MM/YYYY")}</td>
                    <td className="fw-bold text-gray-600">O serviço está em andamento</td>
                    <td>
                      <span className="badge badge-light-primary">Em andamento</span>
                    </td>
                  </tr>
                )}
                {currentClient?.confirmed !== 0 && (
                  <tr>
                    <td>-</td>
                    <td className="fw-bold text-gray-600">O serviço foi confirmado com sucesso</td>
                    <td>
                      <span className="badge badge-light-success">Concluído</span>
                    </td>
                  </tr>
                )}
                {currentClient?.appointment_date && (
                  <tr>
                    <td>{moment(currentClient?.appointment_date).format("DD/MM/YYYY")}</td>
                    <td className="fw-bold text-gray-600">Agendamento recebido</td>
                    <td>
                      {currentClient?.confirmed === 0 ? (
                        <span className="badge badge-light-warning">Pendente</span>
                      ) : (
                        <span className="badge badge-light-success">Confirmado</span>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </S.OrderHistory>
      )}
    </S.Container>
  )
}

export default DetailsAppointment
