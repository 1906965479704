import { PaginationItem } from './PaginationItem';

interface PaginationProps {
    totalCountOfRegisters: number;
    registersPerPage?: number;
    currentPage?: number;
    onPageChange: (page: number) => void;
}

const siblingsCount = 1;

function generatePagesArray(from: number, to: number) {
    return [...new Array(to - from)]
        .map((_, index) => {
            return from + index + 1;
        })
        .filter(page => page > 0)
}

export function PaginationsAPI({
    totalCountOfRegisters,
    registersPerPage = 5,
    currentPage = 1,
    onPageChange
}: PaginationProps) {

    const lastPage = Math.ceil(totalCountOfRegisters / registersPerPage);

    const previousPages = currentPage > 1
        ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
        : []

    const nextPages = currentPage < lastPage
        ? generatePagesArray(currentPage, Math.min(currentPage + siblingsCount, lastPage))
        : []

    return (
        <div className='flex-row'>
            <div className="text-gray-600 px-4">
                Página <strong>{currentPage}</strong> de <strong>{lastPage}</strong>
            </div>
            <ul className='pagination mt-8 mb-8 px-5'>
                {currentPage > (1 + siblingsCount) && (
                    <>
                        <PaginationItem number={1} onPageChange={onPageChange} />
                        {currentPage > (2 + siblingsCount) && (
                            <p className="page-link">
                                ...
                            </p>
                        )}
                    </>
                )}

                {previousPages.length > 0 && previousPages.map(page => {
                    return <PaginationItem key={page} number={page} onPageChange={onPageChange} />
                })}

                <PaginationItem number={currentPage} isCurrent onPageChange={onPageChange} />

                {nextPages.length > 0 && nextPages.map(page => {
                    return <PaginationItem key={page} number={page} onPageChange={onPageChange} />
                })}

                {currentPage + siblingsCount < lastPage && (
                    <>
                        <PaginationItem number={lastPage} onPageChange={onPageChange} />
                        {currentPage + 1 + siblingsCount < lastPage && (
                            <p className="page-link">
                                ...
                            </p>
                        )}
                    </>
                )}

            </ul>
        </div>
    );
}