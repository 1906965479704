import { SelectHTMLAttributes, useState } from "react"
import { Link } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import EditCustomer from "../EditCustomer"
import { useUser } from '../../../../hooks/useUser';

interface SidebarCustomersProps extends SelectHTMLAttributes<HTMLSelectElement> {
  file: string
  name: string
  email: string
  document: string
  codId: string
  phone_number: string
  last_access: string
  status?: number
  is_admin?: number
  is_provider?: number
}

export function SidebarCustomers({
  file,
  name,
  email,
  codId,
  status,
  last_access,
  phone_number,
  document,
  is_admin,
  is_provider,
  ...rest
}: SidebarCustomersProps) {
  const [showEdit, setShowEdit] = useState(false)
  const {currentUser} = useUser()

  return (
    <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
      <div className="card mb-5 mb-xl-8">
        <div className="card-body">
          <div className="d-flex flex-center flex-column py-5">
            <div className="symbol symbol-100px symbol-circle mb-7">
              <img
                style={{ objectFit: "cover" }}
                className="symbol-label"
                src={
                  file ? `https://apibelleza.velty.com.br/storage/${file}` : toAbsoluteUrl("/media/avatars/blank.png")
                }
                alt="user"
              />
            </div>
            <Link to="#" className="fs-3 text-gray-800 text-hover-primary fw-bolder mb-3">
              {name}
            </Link>
          </div>
          <div className="d-flex flex-stack fs-4 py-3">
            <div
              className="fw-bolder rotate collapsible"
              data-bs-toggle="collapse"
              role="button"
              aria-expanded="false"
              aria-controls="kt_user_view_details"
            >
              Detalhes
            </div>
            <EditCustomer showModal={showEdit} setShowModal={setShowEdit} />
          </div>
          <div className="separator" />
          <div id="kt_user_view_details" className="collapse show">
            <div className="pb-5 fs-6">
              <div className="d-flex justify-content-between">
                <div className="pb-5 fs-6">
                  <div className="fw-bolder mt-5">Código</div>
                  <div className="text-gray-600">ID-{codId}</div>
                </div>

                <div className="pb-5 fs-6">
                  <div className="fw-bolder mt-6"></div>
                  {is_admin === 1 ? (
                    <div className="badge badge-primary fs-7 me-5">Admin</div>
                  ) : is_provider === 1 ? (
                    <div className="badge badge-warning fs-7 me-5">Parceiro</div>
                  ) : (
                    <div className="badge badge-danger fs-7 me-5">Cliente</div>
                  )}
                </div>
              </div>
              <div className="fw-bolder mt-5">Email</div>
              <div className="text-gray-600">
                <Link to="#" className="text-gray-600 text-hover-primary">
                  {email}
                </Link>
              </div>
              <div className="fw-bolder mt-5">Telefone</div>
              <div className="text-gray-600">
                <Link to="#" className="text-gray-600 text-hover-primary">
                  {phone_number}
                </Link>
              </div>
              <div className="fw-bolder mt-5">CPF</div>
              <div className="text-gray-600">
                <Link to="#" className="text-gray-600 text-hover-primary">
                  {document}
                </Link>
              </div>
              <div className="fw-bolder mt-5">Endereço</div>
              <div className="text-gray-600">
                {currentUser?.address_line1}
                <br />
                {currentUser?.address_line2}
                <br />
                {currentUser?.city}/{currentUser?.state}
              </div>
              {/* <div className="fw-bolder mt-5">Último acesso</div>
              <div className="text-gray-600">{last_access ? last_access : "?"}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
