import { InputHTMLAttributes } from "react"
// import { CheckBox } from "../../../components/Form/CheckBox"
import { UserServicesDTO } from "../../../dtos/UserServicesDTOs"
import formatterNumber from "../../../utils/priceFormatter"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: UserServicesDTO
  onClickCheckBox?: () => void
  onClickConfirm?: () => void
}

export function TableListServicesPartners({ data, onClickCheckBox, onClickConfirm, ...rest }: Props) {
  return (
    <>
      <tr className="w-100">
        <td className="w-50px" />
        <td className="w-250px">
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <p className="symbol symbol-50px">
                <span
                  className="symbol-label"
                  style={{
                    backgroundImage: data.product.file
                      ? `url(https://apibelleza.velty.com.br/storage/${data.product.file})`
                      : `url(https://iili.io/ZOF5Yu.png)`,
                  }}
                />
              </p>
            </div>

            <div className="d-flex mt-4 w-100">
              <p className="fs-6 text-gray-700 ps-6">{data.product.name}</p>
            </div>
          </div>
        </td>

        <td className="w-200px">
          <div className="d-flex mt-4 w-100">
            <p className="fs-6 text-gray-500">{data.product.category.name}</p>
          </div>
        </td>

        <td className="w-200px">
          <div className="d-flex mt-4">{formatterNumber(data.product.price)}</div>
        </td>

        <td className="w-200px">
          <div className="d-flex mt-4">
            {data.approved === 1 ? (
              <div className="badge badge-light-success">Ativo</div>
            ) : (
              <div className="badge badge-light-danger">Inativo</div>
            )}
          </div>
        </td>
      </tr>
    </>
  )
}
