import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import Swal from "sweetalert2"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { PickerPhoto } from "../../components/PickerPhoto"
import { SpinnerLoad } from "../../components/SpinnerLoad"
import { SpinnerLoading } from "../../components/SpinnerLoading"
import { SpinnerLoadSmall } from "../../components/SpinnerLoadSmall"
import { useCategories } from "../../hooks/useCategories"
import useMount from "../../utils/useMount"

const EditCategory: FC = () => {
  const params = useParams()
  const {
    editCategoryById,
    loading,
    editPictureById,
    fetchCategoryById,
    currentCategory,
    setCurrentCategory,
    deletePictureById,
  } = useCategories()
  const pageTitleBreadCrumbs: Array<PageLink> = useMemo(
    () => [
      {
        title: `Categoria #${currentCategory?.id}`,
        path: "gestao/categorias/:id/editar-categoria",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
      },
    ],
    [currentCategory?.id],
  )
  const [edit, setEdit] = useState(false)
  const [file, setFile] = useState<any>()
  const [name, setName] = useState<string>("")
  const [desc, setDesc] = useState<string>("")

  useEffect(() => {
    setFile(`${currentCategory?.file}`)
    setName(`${currentCategory?.name}`)
    setDesc(`${currentCategory?.desc}`)
  }, [currentCategory])

  const handleRemovePhoto = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      deletePictureById(params?.id || "")
    },
    [params, deletePictureById],
  )

  const handleUpdatePhoto = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      await handleRemovePhoto(e)
      const data = new FormData()
      data.append("file", currentCategory?.file!)
      editPictureById(params.id || "", data)
    },
    [currentCategory?.file, editPictureById, handleRemovePhoto, params.id],
  )

  const updatePhoto = async (e: FormEvent) => {
    e.preventDefault()
    await handleRemovePhoto(e)
    editPictureById(params.id || "", file)
  }
  /*
  const handleEditService = useCallback(
    (e: FormEvent) => {
      console.log(name, desc)
      e.preventDefault()
      const data = new FormData()
      const photoData = new FormData()

      data.append("id", `${params.id}`)
      data.append("name", name)
      data.append("desc", desc)
      data.append("disabled", "0")

      if (currentCategory?.file && edit) {
        photoData.append("file", currentCategory.file)
        handleUpdatePhoto(e)
        data.append("file", currentCategory.file)
      }
      console.log(name, desc)
      editCategoryById(params.id || "", name, desc)
    },
    [currentCategory, editCategoryById, handleUpdatePhoto, params.id, edit],
  )
    */
  const editService = (e: FormEvent) => {
    e.preventDefault()
    //const photoData = new FormData()
    if (currentCategory?.file && edit) {
      //photoData.append("file", currentCategory.file)
      handleUpdatePhoto(e)
      setFile(currentCategory.file)
    }
    editCategoryById(params.id || "", name, desc, file)
  }

  useMount(() => {
    fetchCategoryById(params.id || "")
  })

  const changeInput = useCallback(
    (key: string, value: string | File) => {
      setCurrentCategory((prev: any) => {
        return { ...prev, [key]: value }
      })
    },
    [setCurrentCategory],
  )

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Categorias</PageTitle>
      {loading ? (
        <SpinnerLoading />
      ) : (
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">Editar Categoria</h3>
            </div>
          </div>
          <div id="kt_account_profile_details" className="collapse show">
            <form noValidate className="form">
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">Foto Categoria</label>

                  {loading ? (
                    <SpinnerLoadSmall />
                  ) : (
                    <div className="col-lg-8">
                      <PickerPhoto
                        onFileSelected={file => {
                          //setFile(file!)

                          changeInput("file", file)
                          setEdit(true)
                        }}
                        image={file}
                      />
                      <div className="col-lg-8 mt-5">
                        {/* {currentCategory?.file === null ? (
                          ""
                        ) : currentCategory?.file ? (
                          <button type="button" className="btn btn-danger ms-2" onClick={handleRemovePhoto}>
                            Remover Foto
                          </button>
                        ) : (
                          <button type="button" className="btn btn-primary ms-2" onClick={handleUpdatePhoto}>
                            Atualizar Foto
                          </button>
                        )} */}
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className="separator my-10"></div>
                </div>

                {loading ? (
                  <div className="d-flex flex-row flex-column-fluid">
                    <div className="d-flex flex-row-fluid flex-center">
                      <SpinnerLoad />
                    </div>
                  </div>
                ) : (
                  <>
                    {/* begin::Input Name */}
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">Nome da Categoria</label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Nome da Categoria"
                          name="Nome da Categoria"
                          value={name}
                          onChange={e => {
                            setName(e.target.value)
                            //changeInput("name", e.target.value)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">Descrição</label>
                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Descrição"
                          name="desc"
                          value={desc}
                          onChange={e => {
                            setDesc(e.target.value)
                            //changeInput("desc", e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="card-footer d-flex justify-content-end py-6 px-9">
                  <div className="d-flex justify-content-end">
                    <Link to={"/gestao/categorias"} type="button" className="btn btn-light-primary me-3">
                      Voltar
                    </Link>
                  </div>
                  <button type="submit" className="btn btn-primary" onClick={editService}>
                    Atualizar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export { EditCategory }
