import { useState } from "react"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { PaginationsAPI } from "../../../components/Form/PaginationAPI"
import { HeaderSearchStatus } from "../../../components/HeaderSearchStatus"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
// import { AppointmentsDTOS } from "../../../dtos/AppointmentsDTOs";
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"
import { useSchedules } from "../../../hooks/useSchedules"
import api from "../../../services/api"
import useMount from "../../../utils/useMount"
import { TableAppointmentsProgress } from "./TableAppointmentsProgress"

export function PageAppointmentsProgress() {
  const { profile } = useAuthBelleza()
  const { getPartnerSchedules, providerAppointments, setPage, page, totalCount, loading } = useSchedules()
  const userId = localStorage.getItem("@App:userId")
  const [checked, setChecked] = useState(false)
  const [selectedId, setSelectedId] = useState<string[]>([])
  // const [appointmentConfirm, setAppointmentConfirm] = useState(0);

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "/gestao/parceiros/agendamentos-disponiveis/:id",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  async function handleAppointmentCheckin(id: string) {
    await api
      .post(`/appointments/checkin/${id}`)
      .then(response => {
        console.log(response.data)
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        /*  setIsLoading(false); */
      })
  }

  async function handleAppointmentCheckout(id: string) {
    await api
      .post(`/appointments/checkout/${id}`)
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        /*  setIsLoading(false); */
      })
  }

  useMount(() => {
    getPartnerSchedules(userId || "")
  })

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Agendamentos em Andamento</PageTitle>
      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <KTCard>
          <div>
            <KTCardBody>
              <>
                <HeaderSearchStatus />
                <div className="card-body pt-0">
                  <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th className="">Cod.</th>
                        <th className="">Cliente</th>
                        <th className="">Produto</th>
                        <th className="">Status</th>
                        <th className="">Check-in</th>
                        <th className="">Checkout</th>
                        <th className="">Valor</th>
                        <th className="">Data</th>
                        <th className="text-end min-w-75px pe-6">Ações</th>
                      </tr>
                    </thead>
                    {providerAppointments.length > 0 ? (
                      <tbody className="fw-bold text-gray-600">
                        {providerAppointments.map(appointment => {
                          return (
                            <TableAppointmentsProgress
                              data={appointment}
                              key={appointment.id}
                              onClickCheckin={() => handleAppointmentCheckin(appointment.id.toString())}
                              onClickCheckout={() => handleAppointmentCheckout(appointment.id.toString())}
                              onClickCheckBox={() => handleChecked(appointment.id.toString())}
                              checked={checked && selectedId[0] === appointment.id.toString() ? true : false}
                            />
                          )
                        })}
                      </tbody>
                    ) : (
                      <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                        Sem agendamentos
                      </div>
                    )}
                  </table>
                </div>

                <PaginationsAPI totalCountOfRegisters={totalCount} currentPage={page} onPageChange={setPage} />
              </>
            </KTCardBody>
          </div>
        </KTCard>
      )}
    </>
  )
}
