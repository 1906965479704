import styled from "styled-components"

export const Overlay = styled.div`
  background: black;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  left: 0;
  top: 0;
  z-index: 1000;
  right: 0;
`


export const LineTabs = styled.div`
  display: flex;
`

export const ButtonTab: any = styled.div`
  color: ${(props: any) => (props.active ? "#009ef7" : "#a1a5b7")};
  font-size: 16px;
  font-weight: 500;
  margin-right: 30px;
  border-bottom: ${(props: any) => (props.active ? "2px solid #009ef7" : "")};
  padding-bottom: 12px;
  cursor: pointer;
`
