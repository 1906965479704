import { useState } from "react"
import Swal from "sweetalert2"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Paginations } from "../../../components/Form/Pagination"
import { SpinnerLoading } from "../../../components/SpinnerLoading"
import { Environment } from "../../../environment"
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"
import { useServices } from "../../../hooks/useServices"
import api from "../../../services/api"
import useMount from "../../../utils/useMount"
import { TableServicesPartners } from "./TableServicesPartners"

export function PageServicesAssociate() {
  const { profile } = useAuthBelleza()
  const {getServices, servicesList, isLoading} = useServices()
  const [checked, setChecked] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string[]>([])
  const [page, setPage] = useState(1)

  /* Table */

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: `${profile?.name}`,
      path: "/gestao/servicos-ao-usuario/adicionar-servicos-ao-usuario",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  async function handleConfirm(id: string) {
    await api
      .post(`/user_products/add/${profile?.id}`, {
        product_id: id,
      })
      .then(response => {
        Swal.fire("Serviço associado!", "Serviço associado com sucesso", "success")
      })
      .catch(error => {
        console.log("error", error)
      })
      .finally(() => {
        setSelectedId([])
        setChecked(false)
      })
  }

  useMount(() => {
    getServices()
  })

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Associar Serviço</PageTitle>
      <KTCard>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div>
            <KTCardBody>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  {/* START HEADER TABLE */}
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th className="w-10px pe-2" />
                      <th />
                      <th>Nome</th>
                      {/* <th >Status</th> */}
                      <th>Categoria</th>
                      <th>Descrição</th>
                      <th>Preço</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  {servicesList.length > 0 ? (
                    <tbody className="text-gray-600 fw-bold">
                      {servicesList
                        .slice((page - 1) * Environment.LINHA_DE_LINHAS, page * Environment.LINHA_DE_LINHAS)
                        .map(service => {
                          return (
                            <TableServicesPartners
                              key={service.id}
                              data={service}
                              onClickConfirm={() => handleConfirm(service.id.toString())}
                              onClickCheckBox={() => handleChecked(service.id.toString())}
                              checked={checked && selectedId[0] === service.id.toString() ? true : false}
                            />
                          )
                        })}
                    </tbody>
                  ) : (
                    <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40, width: "206px" }}>
                      Sem serviços associados
                    </div>
                  )}
                </table>
              </div>
            </KTCardBody>
            {/* begin::pagination */}
            <Paginations totalCountOfRegisters={servicesList.length} currentPage={page} onPageChange={setPage} />
          </div>
        )}
      </KTCard>
    </>
  )
}
