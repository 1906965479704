/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import * as S from "./styles"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { UserModel } from "../../../@types/api-user-model"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
import { SidebarCustomers } from "../components/SidebarCustomers"
import { ActionButtonLink } from "../../../components/ActionButtonLink"
import SchedulesTable from "../components/Schedules"
import SecurityInformations from "../components/SecurityInformations"
import { useServices } from "../../../hooks/useServices"
import useMount from "../../../utils/useMount"
import { usePartners } from "../../../hooks/usePartners"
import { useTransactions } from "../../../hooks/useTransactions"
import { UserServices } from "../../UserServices"
import { useUser } from "../../../hooks/useUser"
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"

interface userId {
  userId: number
}
const LoadingCustomer: FC = () => {
  const params = useParams()
  const [active, setActive] = useState(1)
  const { getUserServices } = useServices()
  const [toggleState] = useState(1)
  const { currentUser, fetchUser } = useUser()
  const { fetchUserTranactions } = useTransactions()
  const [customer] = useState<UserModel[]>([])
  const navigate = useNavigate()

  const [userId, setUserId] = useState<any>(null)

  const { profile } = useAuthBelleza()

  useMount(() => {
    fetchUser(params.id || "")
    fetchUserTranactions(params.id || "")
  })

  // const toggleTab = (index: number) => {
  //   setToggleState(index)
  // }

  const pageTitleBreadCrumbs: Array<PageLink> = useMemo(
    () => [
      {
        title: `Carregando...`,
        path: "/gestao/usuarios/loading",
        isSeparator: false,
        isActive: false,
      },
      {
        title: "",
        path: "",
        isSeparator: true,
        isActive: false,
      },
    ],
    [customer],
  )

  //carregar dados do cliente

  useMount(() => {
    getUserServices(`${params.id}` || "")
  })

  useEffect(() => {
    const id = profile?.id
    setUserId(id)
  }, [profile])

  const redirectUser = () => {
    userId && navigate(`/gestao/usuarios/${userId}/visualizar-usuario`)
  }

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Parceiro</PageTitle>
      {userId ? redirectUser() : <SpinnerLoadingMiddle />}
    </>
  )
}

export { LoadingCustomer }
