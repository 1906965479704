import { useState } from "react"
import Swal from "sweetalert2"

interface PickerPhotoProps {
  onFileSelected: (file: File) => void
  image?: string
  hiddenPencil?: boolean
}

export function PickerPhoto({ onFileSelected, image, hiddenPencil }: PickerPhotoProps) {
  const [filePreview, setFilePreview] = useState("")

  return (
    <div
      className="image-input image-input-outline"
      data-kt-image-input="true"
      style={{
        backgroundImage:
          //base64, //preview da imagem
          `url(${filePreview}))`,
      }}
    >
      <div
        className="image-input-wrapper w-150px h-150px"
        style={{
          backgroundPosition: "center",
          backgroundImage: filePreview
            ? `url(${filePreview})`
            : image
            ? `url(https://apibelleza.velty.com.br/storage/${image})`
            : `url(https://iili.io/ZOF5Yu.png)`,
        }}
      >
        {!hiddenPencil && (
          <label
            className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
            data-kt-image-input-action="change"
            data-bs-toggle="tooltip"
            title="Alterar avatar"
          >
            <i className="bi bi-pencil-fill fs-7">
              <input
                type="file"
                name="avatar"
                accept=".png,.jpg,.jpeg,.pdf"
                onChange={async e => {
                  if (e.target.files && e.target.files[0].size > 999999) {
                    Swal.fire("Imagem muito grande!", "Tamanho máximo: 1 mb", "error")
                  } else {
                    //base64
                    if (e.target.files) {
                      const reader = new FileReader()
                      reader.readAsDataURL(e.target.files[0])
                      reader.onload = () => {
                        setFilePreview(reader.result as string)
                      }
                    }

                    //file
                    if (e.target.files) {
                      /*  setFile(e.target.files[0]); */
                      onFileSelected(e.target.files[0])
                    }
                  }
                }}
              />
            </i>
          </label>
        )}
      </div>
    </div>
  )
}
