import { InputHTMLAttributes } from "react"
import { ActionButton } from "../../../components/ActionButton"
import { CheckBox } from "../../../components/Form/CheckBox"
import { ServicesDTOs } from "../../../dtos/ServicesDTOs"
import { useServices } from "../../../hooks/useServices"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: ServicesDTOs
  onClickCheckBox?: () => void
  OnClickDelete?: () => void
  onClickView?: () => void
  onClickEdit?: () => void
}

//API - Falta o dado de implementação da API

export function TableServices({ data, onClickCheckBox, OnClickDelete, onClickView, onClickEdit, ...rest }: Props) {
  const { editServiceById } = useServices()
  const newPrice = (e: any) => {
    if (e) {
      var v = e.replace(/\D/g, "")
      v = (v / 100).toFixed(2) + ""
      v = v.replace(".", ",")
      v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,")
      v = v.replace(/(\d)(\d{3}),/g, "$1.$2,")
      e = v
    }

    return e
  }

  return (
    <>
      <tr className="w-100">
        <td>
          <div className="d-flex">
            <p className="symbol symbol-50px">
              <span
                className="symbol-label"
                style={{
                  backgroundImage: data.file
                    ? `url(https://apibelleza.velty.com.br/storage/${data.file})`
                    : `url(https://iili.io/ZOF5Yu.png)`,
                }}
              />
            </p>
          </div>
        </td>
        <td className="w-150px">
          <div className="d-flex mt-4 w-100">
            <p className="fs-6 text-gray-700">{data.name}</p>
          </div>
        </td>
        {/* <td className="w-150px">
                    <div className="d-flex mt-4 w-100 justify-content-start">
                        <div className='badge badge-light-success'>Ativo - "API"</div>
                    </div>
                </td> */}
        <td className="w-150px">
          <div className="d-flex mt-4 w-100">
            <p className="fs-6 text-gray-500">{data.category?.name}</p>
          </div>
        </td>

        <td className="w-400px">
          <div className="d-flex mt-4">{data.desc}</div>
        </td>
        <td>
          <div className="d-flex mt-4">{newPrice(`${data.price?.toFixed(2)}`)}</div>
        </td>
        <td>
          <div className="d-flex justify-content-center">
            <ActionButton
              onClickEdit={onClickEdit}
              onClickDelete={() => {
                editServiceById(
                  `${data.id}`,
                  `category_id=${data.category?.id}&name=${data.name}&desc=${data.desc}&price=${data.price}&disabled=1`,
                )
              }}
            />
          </div>
        </td>
      </tr>
    </>
  )
}
