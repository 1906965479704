import { useCallback, useEffect, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { Prev } from "react-bootstrap/esm/PageItem"
import { Link } from "react-router-dom"
import Swal from "sweetalert2"
import { PickerPhoto } from "../../../../components/PickerPhoto"
import { useUser } from "../../../../hooks/useUser"

const DocumentsTable = () => {
  const { currentUser, uploadDocument, deleteDocument } = useUser()
  const [show, setShow] = useState(false)
  const [selectDoc, setSelectDoc] = useState<any>(null)

  const handleClose = () => setShow(false)
  const handleShow = (document: any) => {
    const documentType = document.file.split(".")[1]
    if (documentType === "pdf") {
      window.open(`https://apibelleza.velty.com.br/storage/${document.file}`, "_blank")
    } else {
      setSelectDoc(document)
      setShow(true)
    }
  }
  const [form, setForm] = useState({
    type: "",
    file: "",
  })
  const [edit, setEdit] = useState(false)

  const submitDocument = useCallback(() => {
    if (form.file && form.type) {
      const data = new FormData()
      data.append("type", form.type)
      if (form.file) {
        data.append("file", form.file)
      }
      uploadDocument(`${currentUser?.id}`, data)
    } else {
      Swal.fire("Por favor preencher campos", "Revisar campos", "error")
    }
  }, [currentUser, form, uploadDocument])

  const renameDocument = (documentType: string) => {
    console.log(documentType)
    switch (documentType) {
      case "RG_Front":
        return "Frente do Rg"
      case "RG_Back":
        return "Verso do Rg"
      case "Criminal":
        return "certidão de antecedentes criminais"
      case "Address":
        return "Comprovante de residência"
      case "document" || null || undefined:
        return "Documento"
      default:
        break
    }
  }

  return (
    <div className="card pt-4 mb-6 mb-xl-9">
      <div style={{ alignItems: "center" }} className="card-header border-0">
        <div className="card-title">
          <h2>Documentos</h2>
        </div>
        <button onClick={() => setEdit(!edit)} style={{ width: "24px", background: "none" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#009ef7"
            className="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
      </div>

      <div className="card-body pt-0 pb-5">
        {edit ? (
          <>
            <div className="fv-row mb-7">
              <label className="fs-6 fw-bold mb-2">Tipo de documento</label>

              <select
                value={form.type}
                className="form-control form-control-solid"
                placeholder=""
                onChange={e =>
                  setForm(prev => {
                    return { ...prev, type: e.target.value }
                  })
                }
              >
                {currentUser?.is_company && <option value="CPF">CNPJ</option>}
                <option></option>
                <option value="RG_Front">RG - frente</option>
                <option value="RG_Back">RG - Verso</option>
                <option value="Adress">Comprovante de residência </option>
                <option value="Criminal">Antecedentes criminais</option>
              </select>
            </div>
            <div className="flex mb-7 flex-col">
              <label className="fs-6 fw-bold mb-2">Documento</label>
              <div style={{ position: "relative", width: "fit-content" }}>
                <PickerPhoto
                  onFileSelected={file => {
                    setForm((prev): any => {
                      return { ...prev, file: file }
                    })
                    // inputChange("file", file)
                    setEdit(true)
                  }}
                  image={form?.file || ""}
                />
              </div>
            </div>
            <div>
              <button
                onClick={() => submitDocument()}
                style={{ marginLeft: "auto", display: "flex" }}
                className="btn btn-primary me-3"
              >
                Salvar
              </button>
            </div>
          </>
        ) : (
          <div id="kt_table_customers_payment_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="table-responsive">
              <table
                className="table align-middle table-row-dashed gy-5 dataTable no-footer"
                id="kt_table_customers_payment"
              >
                <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                  <tr className="text-start text-muted text-uppercase gs-0">
                    <th
                      className="min-w-100px sorting"
                      tabIndex={1}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Invoice No.: activate to sort column ascending"
                    >
                      Documento
                    </th>

                    <th
                      className="min-w-100px sorting flex justify-content-center"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                    >
                      Visualizar
                    </th>

                    <th
                      className="min-w-100px sorting"
                      tabIndex={0}
                      aria-controls="kt_table_customers_payment"
                      rowSpan={1}
                      colSpan={1}
                      aria-label="Date: activate to sort column ascending"
                    >
                      Ação
                    </th>
                  </tr>
                </thead>

                <tbody className="fs-6 fw-semibold text-gray-600">
                  {currentUser?.documents.map(document => (
                    <tr className="odd">
                      <td style={{ textTransform: "uppercase" }}>{renameDocument(document.type)}</td>
                      <td className="flex justify-content-center">
                        <i
                          className="bi bi-file-earmark-image fs-2x cursor-pointer"
                          onClick={() => handleShow(document)}
                        ></i>
                        {/* {currentUser?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")} */}
                      </td>
                      <td>
                        <Button
                          className="btn btn-light btn-active-light-primary btn-sm"
                          variant="secondary"
                          onClick={() => deleteDocument(`${document.id}`)}
                        >
                          Deletar
                        </Button>
                      </td>
                    </tr>
                  ))}

                  {/* <div className="mt-5" style={{ color: "#009Ef7" }}>
                    <Link to="#" className="text-gray-600 text-hover-primary mb-1 p-0">
                      ARQUIVOS
                    </Link>
                  </div>
                  {currentUser?.documents.map(document => (
                    <div className="flex justify-between w-full">
                      <span style={{ textTransform: "uppercase" }}>{document.type}</span>
                      <img
                        style={{ width: "300px" }}
                        src={`https://apibelleza.velty.com.br/storage/${document.file}`}
                        alt="document"
                      />
                      <button
                        onClick={() => deleteDocument(`${document.id}`)}
                        style={{ width: "40px", height: "40px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#009Ef7"
                          className="w-6 h-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
                  ))} */}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {show && (
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{selectDoc.type}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex justify-content-center">
              <img
                style={{ width: "300px" }}
                src={`https://apibelleza.velty.com.br/storage/${selectDoc.file}`}
                alt="document"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>Fechar</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  )
}

export default DocumentsTable
