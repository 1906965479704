import { SpinnerLoad } from "../../../../../../../belleza/components/SpinnerLoad"

const UsersListLoading = () => {
  const styles = {
    borderRadius: '0.475rem',
    color: '#7e8299',
    fontWeight: '500',
    margin: '0',
    width: 'auto',
    padding: '1rem 2rem',
    top: 'calc(50% - 2rem)',
    left: 'calc(50% - 4rem)',
  }

  return <div style={{ ...styles, position: 'absolute', textAlign: 'center' }}><SpinnerLoad /></div>
}

export { UsersListLoading }
