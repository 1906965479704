import { replace } from "formik"
import { InputHTMLAttributes, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { toAbsoluteUrl } from "../../../../../_metronic/helpers"
import { AppointmentsPartnersDTOS } from "../../../../dtos/AppointmentsPartnersDTOs"
import formatterNumber from "../../../../utils/priceFormatter"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: AppointmentsPartnersDTOS
}

export function TablePaymentProgress({ data, ...rest }: Props) {
  const [hour, setHour] = useState(data?.checkout || "--:--")
  const navigate = useNavigate()
  console.log(data)
  return (
    <tr onClick={() => navigate(`/gestao/detalhes-agendamento/${data.id}`, { replace: true })}>
      <td className="w-30px">
        <Link to="#" className="text-gray-800 text-hover-primary fw-bolder">
          {data.id}
        </Link>
      </td>

      <td className="w-250px">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <Link to="#">
              <div className="symbol-label fs-3 bg-light-danger text-danger">
                <span className="svg-icon svg-icon-2x svg-icon-danger">
                  <img src={toAbsoluteUrl("/media/avatars/blank.png")} className={"w-100 rounded"} alt="Pic" />
                </span>
              </div>
            </Link>
          </div>

          <div className="ms-5 w-120px">
            <Link
              to={`/gestao/detalhes-agendamento/${data.id}`}
              className="text-gray-800 text-hover-primary fs-5 fw-bolder"
            >
              {data.client_user.name}
            </Link>
          </div>
        </div>
      </td>

      <td className="w-150px">
        <span className="fw-bolder">{data.product.name}</span>
      </td>

      {/* <td className='text-start pe-0 w-400px'>
                <span className='fw-bolder'>
                {data.appointment_address}
                </span>
              </td> */}

      <td className="text-start ps-5 w-180px">
        <span className="fw-bolder">{formatterNumber(data.price)}</span>
      </td>

      <td className="text-start w-180px">
        <span className="fw-bolder">
          {new Date(data.appointment_date).toLocaleDateString("pt-BR", { timeZone: "UTC" })}
        </span>
      </td>
      <td className="text-start pe-0 w-100px">{hour.split(" ")[1].slice(0, 5)}</td>
    </tr>
  )
}
