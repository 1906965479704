import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { KTCard, KTCardBody } from "../../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { PaginationsAPI } from "../../../components/Form/PaginationAPI"
import { HeaderSearchStatus } from "../../../components/HeaderSearchStatus"
import { SpinnerLoadingMiddle } from "../../../components/SpinnerLoadingMiddle"
import { useAuthBelleza } from "../../../hooks/useAuthBelleza"
import { useSchedules } from "../../../hooks/useSchedules"
import useMount from "../../../utils/useMount"
import { TableAppointment } from "./TableAppointment"

export function PageAppointments() {
  const { user } = useAuthBelleza()
  const { getSchedules, page, setPage, appointments, loading, totalCount } = useSchedules()
  const navigate = useNavigate()

  useMount(() => {
    setTimeout(() => {
      if (user?.user.is_provider === 1 && user.user.is_admin === 0) {
        return navigate(`/gestao/usuarios/${user.user.id}/visualizar-usuario`)
      }
    }, 1000)
  })

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: ``,
      path: "/gestao/agendamentos/:id",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  // function handleChecked(id: string) {
  //     if (selectedId[0] === id) {
  //         setSelectedId([]);
  //         setChecked(false);
  //     } else if (selectedId[0] !== id) {
  //         setSelectedId([id]);
  //         setChecked(true);
  //     } else if (selectedId[0] === undefined) {
  //         setSelectedId([id]);
  //         setChecked(true);
  //     }
  // }

  /*  async function handleConfirmAppointment(id: string) {
         if (selectedId[0] !== id.toString())
             return alert('Selecione um agendamento para confirmar');
 
         await api.post(`/appointments/`)
             .then(response => {
                 console.log(response.data);
                 window.location.reload();
             })
             .catch(error => {
                 console.error(error);
             })
             .finally(() => {
                 setIsLoading(false);
             })
     } */

  useEffect(() => {
    getSchedules()
    function nextPage() {
      navigate(`/gestao/agendamentos/?page=${page}`)
    }
    nextPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Agendamentos</PageTitle>
      {loading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <KTCard>
          <div>
            <KTCardBody>
              <>
                <HeaderSearchStatus />
                <div className="card-body pt-0">
                  <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_sales_table">
                    <thead>
                      <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                        <th className="">Código</th>
                        <th className="">Cliente</th>
                        <th className="">Parceiro</th>
                        <th className="">Status</th>
                        {/* <th className="">Produto</th> */}
                        {/* <th className="">Endereço</th> */}
                        <th className="">Valor</th>
                        <th className="">Data</th>
                        <th className="text-end min-w-75px pe-6">Ações</th>
                      </tr>
                    </thead>

                    {appointments.length > 0 ? (
                      <tbody className="fw-bold text-gray-600">
                        {appointments.map(appointment => {
                          return <TableAppointment data={appointment} key={appointment.id} />
                        })}
                      </tbody>
                    ) : (
                      <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                        Sem Agendamentos
                      </div>
                    )}
                  </table>
                </div>

                <PaginationsAPI totalCountOfRegisters={totalCount} currentPage={page} onPageChange={setPage} />
              </>
            </KTCardBody>
          </div>
        </KTCard>
      )}
    </>
  )
}
