import { InputHTMLAttributes } from "react"
import { Link } from "react-router-dom"
import { UserModel } from "../../../../@types/api-user-model"
import { CheckBox } from "../../../../components/Form/CheckBox"
import { ActionButtonCustomers } from "../ActionButtonCustomers"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: UserModel
  onClickCheckBox?: () => void
  onClickActionView?: () => void
  onClickActionDisable?: () => void
  onClickActionActive?: () => void
  onClickApproved?: () => void
}

//API - Falta o dado de implementação da API

export function TableCustomers({
  data,
  onClickCheckBox,
  onClickActionView,
  onClickActionDisable,
  onClickActionActive,
  onClickApproved,
  ...rest
}: Props) {
  return (
    <>
      <tr className="w-100">
        {/* begin::checkbox */}

        {/* end::checkbox */}

        {/* begin::image and name*/}
        <td className="w-350px">
          <Link to={`/gestao/clientes/${data.id}/visualizar-cliente`}>
            <div className="d-flex align-items-center justify-content-star mt-5">
              <div className="d-flex">
                <Link to={`/gestao/clientes/${data.id}/visualizar-cliente`} className="symbol symbol-50px">
                  <span
                    className="symbol-label"
                    style={{
                      backgroundImage: data.file
                        ? `url(https://apibelleza.velty.com.br/storage/${data.file})`
                        : `url(https://iili.io/ZOF5Yu.png)`,
                    }}
                  />
                </Link>
              </div>

              {/* name e email */}
              <div className="d-flex flex-column ms-4">
                <Link
                  to={`/gestao/clientes/${data.id}/visualizar-cliente`}
                  className="text-gray-800 text-hover-primary mb-1 fs-5"
                >
                  {data.name}
                </Link>
                <span className="text-muted fw-bold text-muted d-block fs-7">{data.email}</span>
              </div>
            </div>
          </Link>
        </td>
        {/* end::image and name */}

        {/* begin::Ultimo Acesso */}
        {/* <td className="w-150px">
                    <div className="d-flex mt-4 w-100 justify-content-start">
                        <div className='badge badge-light fs-7'>
                            {data.last_access ? data.last_access : 'null'}
                        </div>
                    </div>
                </td> */}
        {/* end::Ultimo Acesso */}

        <td className="w-150px">
          <Link to={`/gestao/clientes/${data.id}/visualizar-cliente`}>
            <div className="mt-4 w-100 ">
              <div style={{ height: "63px" }} className="badge fs-7 flex align-items-start flex-col">
                <div className="badge badge-danger fs-7 mt-2">Cliente</div>
                {data.is_admin === 1 && <div className="badge badge-primary fs-7 mt-2">Admin</div>}
                {data.is_provider === 1 && <div className="badge badge-warning fs-7 mt-2">Parceiro</div>}
              </div>
            </div>
          </Link>
        </td>

        {/* begin::Active */}
        <td className="w-150px">
          <Link to={`/gestao/clientes/${data.id}/visualizar-cliente`}>
            <div className="d-flex mt-4 w-100 justify-content-start mt-11">
              {data.active === 1 ? (
                <div className="badge badge-light-success fs-7">Ativo</div>
              ) : (
                <div className="badge badge-light-danger fs-7">Desativado</div>
              )}
            </div>
          </Link>
        </td>
        {/* end::Active */}

        {/* begin::Active */}
        <td className="w-150px">
          <Link to={`/gestao/clientes/${data.id}/visualizar-cliente`}>
            <div className="d-flex mt-4 w-100 justify-content-start mt-11 flex">
              {data.user_approved_at ? (
                <div className="badge badge-light-success fs-7">Aprovado</div>
              ) : (
                <div className="badge badge-light-danger fs-7">Não Aprovado</div>
              )}
            </div>
          </Link>
        </td>
        {/* end::Active */}

        {/*begin::data cadastro  */}
        <td className="w-150px ">
          <Link to={`/gestao/clientes/${data.id}/visualizar-cliente`}>
            <div className="d-flex mt-4 justify-content-start mt-11">
              {/* formato data pt-BR */}
              <span className="text-gray-500 fw-bold text-muted d-block fs-6">
                {/* formato data 	25 Jul 2022, 6:05 pm */}
                {/* observação: o .split nao pode ser null */}
                {data.created_at ? data.created_at.split("T")[0].split("-").reverse().join("/") : "SEM DATA"}
              </span>
            </div>
          </Link>
        </td>
        {/*end::data cadastro  */}

        <td className="w-150px ">
          <div className="d-flex justify-content-end mt-9">
            <ActionButtonCustomers
              onClickView={onClickActionView}
              onClickActive={onClickActionActive}
              onClickDisable={onClickActionDisable}
              onClickApproved={onClickApproved}
            />
          </div>
        </td>
        {/* end::actions */}
      </tr>
    </>
  )
}
