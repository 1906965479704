import React, { useCallback, useContext, useMemo, useState } from "react"
import Swal from "sweetalert2"
import { REQUEST_PASSWORD_URL } from "../../app/modules/auth/core/_requests"
import { User } from "../dtos/AppointmentsPartnersDTOs"
import api from "../services/api"

export type ContextValue = {
  updateUserPhoto: (id: string, data: FormData) => Promise<void>
  removeUserPhoto: (id: string) => Promise<void>
  sendLinkRecover: () => Promise<void>
  fetchUser: (userId: string) => Promise<void>
  currentUser: User | undefined
  loading: boolean
  setCurrentUser: React.Dispatch<React.SetStateAction<User | undefined>>
  updateUser: (userId: string) => Promise<void>
  uploadDocument: (userId: string, data: FormData) => Promise<void>
  deleteDocument: (documentId: string) => Promise<void>
}

export const UserContext = React.createContext<ContextValue | undefined>(undefined)

export const UserProvider: React.FC = props => {
  const [loading, setLoading] = useState(false)
  const [currentUser, setCurrentUser] = useState<User>()
  const removeUserPhoto = useCallback(async (id: string) => {
    try {
      await api.delete(`/user/image/${id}`)
    } catch (error) {}
  }, [])

  const updateUserPhoto = useCallback(async (id: string, data: FormData) => {
    try {
      await api.post(`/user/image/${id}`, data)
      await Swal.fire("Sucesso ao editar foto!", "Foto editada", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao editar foto!", "Foto não editada", "error")
      window.location.reload()
    }
  }, [])

  const fetchUser = useCallback(async (userId: string) => {
    setLoading(true)
    try {
      const response = await api.get(`/user/${userId}`)
      setCurrentUser(response.data[0])
      setCurrentUser((prev: any) => {
        return { ...prev, nickname: "JD" }
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [])

  const updateUser = useCallback(
    async (userId: string) => {
      const values: any = currentUser
      delete values["pagarme"]
      delete values["documents"]
      values["birthdate"] = "2000-02-02"
      const Form = new FormData()
      Form.append("email", values.email)
      Form.append("name", values.name)
      Form.append("is_provider", `${values.is_provider}`)
      Form.append("document", values.document)
      Form.append("phone_number", values.phone_number)
      Form.append("address_line1", values.address_line1)
      Form.append("zipcode", values.zipcode)
      Form.append("city", values.city)
      Form.append("state", values.state)
      Form.append("country", values.country)
      // delete values["updated_at"]
      // delete values["user_approved_at"]
      // delete values["email_verified_at"]
      // delete values['created_at']

      try {
        await api.post(`/user/update/${userId}`, Form)
        await Swal.fire("Sucesso ao editar!", "Informações de parceiro editadas", "success")
      } catch (error) {
        await Swal.fire("Erro ao editar!", "Erro ao editar informações de parceiro", "error")
      }
    },
    [currentUser],
  )

  const uploadDocument = useCallback(async (userId: string, data: FormData) => {
    try {
      await api.post(`/user/document/${userId}`, data)
      await Swal.fire("Sucesso ao atualizar!", "Documento enviado", "success")
      window.location.reload()
    } catch (error) {
      await Swal.fire("Erro ao atualizar!", "Documento não enviado", "error")
    }
  }, [])

  const deleteDocument = useCallback(
    async (documentId: string) => {
      try {
        await api.delete(`/user/document/${documentId}`)
        await Swal.fire("Sucesso ao remover!", "Documento removido", "success")
        fetchUser(`${currentUser?.id}`)
      } catch (error) {
        await Swal.fire("Erro ao remover!", "Documento não removido", "error")
      }
    },
    [currentUser, fetchUser],
  )

  const sendLinkRecover = useCallback(async () => {
    try {
      await api.post(REQUEST_PASSWORD_URL, { email: currentUser?.email })
      await Swal.fire("Link de senha enviado!", "Por favor , verificar email", "success")
    } catch (error) {}
  }, [currentUser?.email])

  const value = useMemo(
    () => ({
      updateUserPhoto,
      removeUserPhoto,
      sendLinkRecover,
      fetchUser,
      currentUser,
      loading,
      setCurrentUser,
      updateUser,
      uploadDocument,
      deleteDocument,
    }),
    [
      updateUserPhoto,
      removeUserPhoto,
      sendLinkRecover,
      fetchUser,
      currentUser,
      loading,
      setCurrentUser,
      updateUser,
      uploadDocument,
      deleteDocument,
    ],
  )

  return <UserContext.Provider value={value} {...props} />
}

export const useUser = (): ContextValue => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider")
  }

  return context
}

//
// Utils
//

export interface ServiceBody {
  name: string
  description: string
  price: string
  file: string
  category_id: string
}
