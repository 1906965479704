import React, { useCallback, useContext, useMemo, useState } from "react"
import { TransactionsDTOs } from "../dtos/TransactionsDTOs"
import api from "../services/api"

export type ContextValue = {
  fetchUserTranactions: (userId: string) => Promise<void>
  userTransactions: TransactionsDTOs[]
}

export const TransactionsContext = React.createContext<ContextValue | undefined>(undefined)

export const TransactionsProvider: React.FC = props => {
  const [userTransactions, setUserTransactions] = useState<TransactionsDTOs[]>([])

  const fetchUserTranactions = useCallback(async (userId: string) => {
    try {
      const response = await api.get(`/transactions/user/${userId}`)
      setUserTransactions(response.data[0].data)
    } catch (error) {}
  }, [])

  const value = useMemo(
    () => ({
      fetchUserTranactions,
      userTransactions,
    }),
    [fetchUserTranactions, userTransactions],
  )

  return <TransactionsContext.Provider value={value} {...props} />
}

export const useTransactions = (): ContextValue => {
  const context = useContext(TransactionsContext)

  if (context === undefined) {
    throw new Error("useTransactions must be used within an TransactionsProvider")
  }

  return context
}

//
// Utils
//

export interface ServiceBody {
  name: string
  description: string
  price: string
  file: string
  category_id: string
}
