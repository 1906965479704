import { createGlobalStyle } from "styled-components"
export default createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: 0;
    outline: 0;
  }

  ul li a {
    text-decoration: none;
  }

  button, select {
    text-decoration: none;
    outline: none !important;
    outline: 0 !important;
    cursor: pointer;
    border: none ;
  }

  iframe {
    display: none !important ;
  }

  .w-full {
    width: 100%;
  }

  .h-full {
    height: 100%;
  }

  .flex {
    display: flex ;
  }

  .block {
    display: block ;
  }

  .items-center {
    align-items: center;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .flex-col {
    flex-direction: column;
  }

  .mt-1 {
    margin-top: 4px;
  }
  .mt-2 {
    margin-top: 8px;
  }
  .mt-3 {
    margin-top: 12px;
  }

  .mt-5 {
    margin-top: 20px;
  }

  .mt-7 {
    margin-top: 32px;
  }

`
