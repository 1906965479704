import moment from "moment"
import { InputHTMLAttributes } from "react"
import { Link } from "react-router-dom"
import { ActionButtonLink } from "../../../components/ActionButtonLink"
import { CheckBox } from "../../../components/Form/CheckBox"
import { CategoriesDTOs } from "../../../dtos/CategoriesDTOs"
import { useCategories } from "../../../hooks/useCategories"

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  data: CategoriesDTOs
  onClickCheckBox?: () => void
  OnClickDelete: () => void
  OnClickAction?: () => void
  toView: string
  OnClickToEdit: () => void
}

//API - Falta o dado de implementação da API

export function TableCategories({
  data,
  onClickCheckBox,
  OnClickDelete,
  OnClickAction,
  toView,
  OnClickToEdit,
  ...rest
}: Props) {
  const { disableCategory } = useCategories()
  const { id } = { ...data }

  return (
    <>
      <tr>
        {/* begin::checkbox */}
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Link to={`/gestao/categorias/${id}/editar-categoria`} className="symbol symbol-50px">
              <span
                className="symbol-label"
                style={{
                  backgroundImage: data.file
                    ? `url(https://apibelleza.velty.com.br/storage/${data.file})`
                    : `url(https://iili.io/ZOF5Yu.png)`,
                }}
              />
            </Link>
            <Link
              to={`/gestao/categorias/${id}/editar-categoria`}
              style={{ marginLeft: "20px" }}
              className="text-gray-800 text-hover-primary fs-5 fw-bold"
              data-kt-ecommerce-product-filter="product_name"
            >
              {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
            </Link>
          </div>
        </td>
        {/* end::name */}

        {/* begin::status falta na API*/}
        <td></td>
        {/* end::status falta na API*/}

        {/* begin::description */}
        <td style={{ width: "320px" }}>{data.desc}</td>
        {/* end::description */}

        {/* begin::n services */}
        <td>{data.products_count}</td>
        {/* end::n services */}

        {/* begin::created_at */}
        <td>{moment(data.created_at).format("DD/MM/YYYY")}</td>
        {/* end::created_at */}

        {/* begin::actions */}
        <td className="w-150px text-end">
          <ActionButtonLink
            toView={toView}
            OnClickToEdit={OnClickToEdit}
            OnClickDelete={() => {
              disableCategory(`${data.id}`, `desc=${data.desc}&name=${data.name}&disabled=1`)
            }}
            OnClickAction={OnClickAction}
          />
        </td>
        {/* end::actions */}
      </tr>
    </>
  )
}
