import React, { useCallback, useContext, useMemo, useState } from "react"
import { AppointmentsPartnersDTOS } from "../dtos/AppointmentsPartnersDTOs"
import api from "../services/api"

export type ContextValue = {
  getSchedules: () => Promise<void>
  appointments: AppointmentsPartnersDTOS[]
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  totalCount: number
  loading: boolean
  getSchedulesByDate: (user: string, start_date: string, end_date: string) => Promise<void>
  currentAppointments: AppointmentsPartnersDTOS[]
  getClientSchedules: (id: string) => Promise<void>
  clientAppointments: AppointmentsPartnersDTOS[]
  getPartnerSchedules: (id: string) => Promise<void>
  providerAppointments: AppointmentsPartnersDTOS[]
  getUnconfirmedAppointments: () => Promise<void>
}

export const SchedulesContext = React.createContext<ContextValue | undefined>(undefined)

export const SchedulesProvider: React.FC = props => {
  const [appointments, setAppointments] = useState<AppointmentsPartnersDTOS[]>([])
  const [clientAppointments, setClientAppointments] = useState<AppointmentsPartnersDTOS[]>([])
  const [providerAppointments, setProviderAppointments] = useState<AppointmentsPartnersDTOS[]>([])
  const [currentAppointments, setCurrentAppointments] = useState<AppointmentsPartnersDTOS[]>([])
  const [page, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setIsLoading] = useState(false)

  const getSchedules = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`/appointments?page=${page}`)
      setAppointments(response.data[0].data)
      setTotalCount(response.data[0].meta.total)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }, [page])

  const getPartnerSchedules = useCallback(
    async (id: string) => {
      setIsLoading(true)
      try {
        const response = await api.get(`/appointments/provider/${id}?page=${page}`)
        setProviderAppointments(response.data[0].data)
        setTotalCount(response.data[0].meta.total)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    },
    [page],
  )

  const getUnconfirmedAppointments = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await api.get(`/appointments/unconfirmed?my_products=true?page=${page}`)
      setProviderAppointments(response.data[0].data)
      setTotalCount(response.data[0].meta.total)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [page])

  const getSchedulesByDate = useCallback(async (user: string, start_date: string, end_date: string) => {
    setIsLoading(true)
    try {
      const response = await api.get(
        `/appointments/provider/${user}?calendar=1&date_start=${start_date}&date_end=${end_date}`,
      )

      setCurrentAppointments(response.data[0].data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [])

  const getClientSchedules = useCallback(
    async (id: string) => {
      setIsLoading(true)
      try {
        const response = await api.get(`/appointments/client/${id}?page=${page}`)
        setClientAppointments(response.data[0].data)
        setTotalCount(response.data[0].meta.total)
      } catch (error) {
      } finally {
        setIsLoading(false)
      }
    },
    [page],
  )

  const value = useMemo(
    () => ({
      appointments,
      setPage,
      totalCount,
      loading,
      getSchedules,
      page,
      getSchedulesByDate,
      currentAppointments,
      getClientSchedules,
      setClientAppointments,
      clientAppointments,
      getPartnerSchedules,
      providerAppointments,
      getUnconfirmedAppointments,
    }),
    [
      appointments,
      setPage,
      totalCount,
      loading,
      getSchedules,
      page,
      getSchedulesByDate,
      currentAppointments,
      getClientSchedules,
      setClientAppointments,
      clientAppointments,
      getPartnerSchedules,
      providerAppointments,
      getUnconfirmedAppointments,
    ],
  )

  return <SchedulesContext.Provider value={value} {...props} />
}

export const useSchedules = (): ContextValue => {
  const context = useContext(SchedulesContext)

  if (context === undefined) {
    throw new Error("useSchedules must be used within an SchedulesProvider")
  }

  return context
}

//
// Utils
//
