import { useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import Swal from "sweetalert2"
import { SpinnerLoadingMiddle } from "../../../../belleza/components/SpinnerLoadingMiddle"
import api from "../../../../belleza/services/api"
import { toAbsoluteUrl } from "../../../../_metronic/helpers"

export function RedefinePassword() {
  const [qs] = useSearchParams()
  const token = qs.get("token")

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmAlteration, setIsConfirmAlteration] = useState(false)

  async function handleRedefinePassword() {
    if (password !== confirmPassword) {
      alert("As senhas não são iguais")
      return
    }

    setIsLoading(true)

    await api
      .post("/password/reset", {
        token,
        email,
        password,
        password_confirmation: confirmPassword,
      })
      .then(response => {
        Swal.fire("Senha alterada com sucesso", "success")
        setIsConfirmAlteration(true)
      })
      .catch(err => {
        console.log(err)
        Swal.fire("Verifique as informações e tente novamente", "error")
      })
      .finally(() => {
        /* window.location.href = "/login"; */
        setIsLoading(false)
      })
  }

  return (
    <>
      {isLoading ? (
        <SpinnerLoadingMiddle />
      ) : (
        <div className="d-flex flex-center flex-column flex-column-fluid pb-lg-20">
          {isConfirmAlteration ? (
            <div className="alert alert-dismissible bg-gray-300 d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10">
              <div className="text-center">
                <h5 className="fw-bolder fs-1 mb-5 text-gray-600">Senha alterada com sucesso!</h5>

                <div className="separator separator-dashed border-gray-600 opacity-25 mb-5"></div>

                <div className="d-flex flex-center position-relative mb-5">
                  <div className="symbol symbol-100px symbol-circle symbol-success">
                    <img src={toAbsoluteUrl("/media/icons/duotune/general/gen060.svg")} alt="" className="w-100" />
                  </div>
                </div>

                <div className="separator separator-dashed border-gray-600 opacity-25 mb-5"></div>

                <p className="text-gray-600 fw-bold fs-3">Agora você pode fazer login com sua nova senha.</p>
              </div>
            </div>
          ) : (
            <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
              <div className="text-center mb-10">
                {/* logo belleza */} {/* redefinir senha */}
                <Link to="/" className="mb-12">
                  <img alt="Logo" src={toAbsoluteUrl("/media/logos/logo-belleza-3-dark.svg")} className="h-60px" />
                </Link>
                {/* fim logo belleza */} {/* redefinir senha */}
                <h3 className="text-dark mb-3 mt-10">Digite sua nova senha</h3>
                <div className="text-gray-400 fw-bold fs-4 mt-10">
                  {/* input de email */}

                  <input
                    type="email"
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg fs-6"
                    placeholder="E-mail"
                    name="email"
                    autoComplete="off"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />

                  <input
                    type="password"
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg fs-6 mt-5"
                    placeholder="Senha"
                    name="password"
                    autoComplete="off"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />

                  <input
                    type="password"
                    className="form-control form-control-solid h-auto py-7 px-6 rounded-lg fs-6 mt-5"
                    placeholder="Confirmar senha"
                    name="confirmPassword"
                    autoComplete="off"
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                </div>
                <div className="text-center mt-10">
                  <button
                    type="submit"
                    className="btn btn-belleza fw-bolder px-9 py-4 my-3 fs-6"
                    onClick={handleRedefinePassword}
                  >
                    Redefinir senha
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}
