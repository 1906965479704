import React from "react"

const PhoneIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 20H19V21C19 21.6 18.6 22 18 22H6C5.4 22 5 21.6 5 21V20ZM19 3C19 2.4 18.6 2 18 2H6C5.4 2 5 2.4 5 3V4H19V3Z"
        fill="currentColor"
      ></path>
      <path opacity="0.3" d="M19 4H5V20H19V4Z" fill="currentColor"></path>
    </svg>
  )
}

export default PhoneIcon
