const formatterNumber = (number: number | undefined) => {
  if (number === undefined) return

  if (isNaN(number)) {
    return "0,00"
  }

  const replaceNumber = number.toString().replace(",", "")
  const parseNumber = parseFloat(replaceNumber)

  return parseNumber.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  })
}

export default formatterNumber
