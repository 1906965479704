import Spinner from 'react-bootstrap/Spinner';

export function SpinnerLoad() {
    return (
        //centralizar o spinner
        <div className="d-flex justify-content-center mb-10">
            <div className='d-flex w-200px h-200px'>
                <div className="d-flex flex-row-fluid flex-center">
                    <div className="spinner-border text-primary" role="status">
                        <Spinner animation="grow" variant="primary" />
                    </div>
                </div>
            </div>
        </div>
    )
}

