import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { ActionButtonView } from "../../../components/ActionButtonView"
import { UserServices } from "../../../hooks/useServices"
import formatterNumber from "../../../utils/priceFormatter"

interface Props {
  data: UserServices
  onClickCheckBox?: () => void
  onClickActionView?: () => void
  onClickActionDisable?: () => void
}

//API - Falta o dado de implementação da API

export function TableUserServices({ data, onClickActionView, onClickActionDisable }: Props) {
  return (
    <>
      <tr className="w-100">
        <td></td>
        <td className="w-300px">
          <div className="d-flex align-items-center">
            <div className="d-flex">
              <Link to="#" className="symbol symbol-50px">
                <span
                  className="symbol-label"
                  style={{
                    backgroundImage: data?.product?.file
                      ? `url(https://apibelleza.velty.com.br/storage/${data.product.file})`
                      : `url(https://iili.io/ZOF5Yu.png)`,
                  }}
                />
              </Link>
            </div>

            {/* name e email */}
            <div className="d-flex flex-column ms-4">
              <Link to="#" className="text-gray-800 text-hover-primary mb-1 fs-5">
                {data?.product?.name}
              </Link>
            </div>
          </div>
        </td>
        {/* end::image and name */}

        {/* begin::Especialidade - API*/}
        <td className="w-150px">
          <div className="d-flex mt-4 w-100">
            <p className="fs-6 text-gray-600">{data?.product?.category.name}</p>
          </div>
        </td>
        {/* end::Especialidade */}

        {/* begin::Ultimo Acesso */}
        <td className="w-150px">
          <div className="d-flex mt-4 w-100 justify-content-start">
            <div className="badge badge-light fs-7">{formatterNumber(data?.product?.price)}</div>
          </div>
        </td>
        {/* end::Ultimo Acesso */}

        {/* begin::Active */}
        <td className="w-150px">
          <div className="d-flex mt-4 w-100 justify-content-start">
            {data?.approved === 1 ? (
              <div className="badge badge-light-success fs-7">Aceito</div>
            ) : (
              <div className="badge badge-light-danger fs-7">Pendente</div>
            )}
          </div>
        </td>
        {/* end::Active */}

        {/*begin::data cadastro  */}
        {/* <td className="w-150px ">
                    <div className="d-flex mt-4 justify-content-center">
                        <span className="text-gray-500 fw-bold text-muted d-block fs-6">
                            {data.created_at.split('T')[0].split('-').reverse().join('/')}
                        </span>
                    </div>
                </td> */}
        {/*end::data cadastro  */}

        <td className="w-150px">
          <div className="d-flex justify-content-end">
            <Button
              variant="secundary"
              className="btn btn-light btn-active-light-primary btn-sm"
              onClick={onClickActionDisable}
            >
              Desabilitar
            </Button>
            {/*<ActionButtonView key={data?.id} onClickView={onClickActionView} onClickDisable={onClickActionDisable} />*/}
          </div>
        </td>
        {/* end::actions */}
      </tr>
    </>
  )
}
