import { useMemo, useState } from "react"
import { KTCard, KTCardBody } from "../../../_metronic/helpers"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { HeaderSearchAdd } from "../../components/HeaderSearchAdd"
import { SpinnerLoading } from "../../components/SpinnerLoading"
import { TableServices } from "./TableServices"
import { Environment } from "../../environment"
import { useNavigate } from "react-router-dom"
import { Paginations } from "../../components/Form/Pagination"
import { useServices } from "../../hooks/useServices"
import useMount from "../../utils/useMount"

export function Services() {
  const navigate = useNavigate()
  const { getServices, servicesList, page, setPage, isLoading, deleteServiceById } = useServices()
  const [checked, setChecked] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string[]>([])

  const pageTitleBreadCrumbs: Array<PageLink> = [
    {
      title: ``,
      path: "gestao/servicos",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ]

  function handleChecked(id: string) {
    if (selectedId[0] === id) {
      setSelectedId([])
      setChecked(false)
    } else if (selectedId[0] !== id) {
      setSelectedId([id])
      setChecked(true)
    } else if (selectedId[0] === undefined) {
      setSelectedId([id])
      setChecked(true)
    }
  }

  function handleEditService(id: number) {
    return navigate(`/gestao/servicos/${id}/editar-servicos`)
  }

  function handleAddProduct() {
    return navigate(`/gestao/servicos/adicionar-servico`)
  }

  useMount(() => {
    getServices()
  })

  const currentList = useMemo(() => {
    return servicesList.slice((page - 1) * Environment.LINHA_DE_LINHAS, page * Environment.LINHA_DE_LINHAS)
  }, [page, servicesList])

  return (
    <>
      <PageTitle breadcrumbs={pageTitleBreadCrumbs}>Serviços</PageTitle>
      <KTCard>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div>
            <HeaderSearchAdd onClick={handleAddProduct} />
            <KTCardBody>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 gy-7">
                  <thead>
                    <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                      <th></th>
                      <th>Nome</th>
                      <th>Categoria</th>
                      <th>Descrição</th>
                      <th>Preço</th>
                      <th className="text-center">Ações</th>
                    </tr>
                  </thead>
                  {currentList.length > 0 ? (
                    <tbody className="text-gray-600 fw-bold">
                      {currentList.map(service => {
                        return (
                          <TableServices
                            key={service.id}
                            data={service}
                            onClickEdit={() => handleEditService(service.id)}
                            OnClickDelete={() => deleteServiceById(service.id)}
                            onClickCheckBox={() => handleChecked(service.id.toString())}
                            checked={checked && selectedId[0] === service.id.toString() ? true : false}
                          />
                        )
                      })}
                    </tbody>
                  ) : (
                    <div style={{ marginLeft: "20px", fontSize: 16, color: "#009ef7", marginTop: 40 }}>
                      Sem serviços
                    </div>
                  )}
                </table>
              </div>
            </KTCardBody>
            <Paginations totalCountOfRegisters={servicesList.length} currentPage={page} onPageChange={setPage} />
          </div>
        )}
      </KTCard>
    </>
  )
}
